import { isEmpty, map } from 'lodash';
import React, { memo } from 'react';
import { EarlyPayBadge } from 'src/components/bulkSnapshotApproval/common';

import {
    BulkApprovalSnapShot,
    SnapShotAmountSummary, SnapShotAmountSummaryList
} from '@amzn/digital-vendor-payments-gateway-client';
import Button from '@amzn/meridian/button';
import Card from '@amzn/meridian/card';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Tag from '@amzn/meridian/tag';
import Text from '@amzn/meridian/text';

import {
    currencyFormatHelper,
    dueDateTagHelper,
    formatDateAsString,
    getApprovalName,
    getMarketPlaceFromId,
    isApproveEnabled,
    showEarlyTag
} from '@app/helpers';

interface Props {
    snapshot: BulkApprovalSnapShot;
    businessApprovalAllowed: boolean;
    financeApprovalAllowed: boolean;
    onApproveSnapshot: () => void;
    onDownload: () => void;
}

/**
 * Creates single row for the pending approval snapshot table
 * @param snapshot: snapshot information to display in the table
 * @param onApproveSnapshot: method to call when clicking the "Business / Finance Approval" button
 * @param onDownload: method to call when clicking the "Download" button
 * @returns TableRow with approval snapshot information(s)
 */
const PendingApprovalsTableCard: React.FunctionComponent<Props> = ({
    snapshot,
    businessApprovalAllowed,
    financeApprovalAllowed,
    onApproveSnapshot,
    onDownload
}) => {
    const { disbursementType, amountSummary, approvalPhaseInfo, paymentStartDate, paymentEndDate } =
        snapshot;
    const { approvalType, snapShotId, snapShotApprovalType, glProductGroup, marketplaceId } =
        snapshot;
    const isApproveButtonDisabled = !isApproveEnabled(
        snapShotApprovalType,
        businessApprovalAllowed,
        financeApprovalAllowed
    );

    return (
        <Card
            key={snapShotId}
            value={snapShotId}
            spacingInset='100'
            onClick={() => {}}
            checked={false}>
            <Column spacingInset='400'>
                <Row alignmentHorizontal='justify' alignmentVertical='top'>
                    <Column spacing='400' spacingInset='10'>
                        <Row spacing='400'>
                            <Text type='b500' tag='label'>
                                {disbursementType} -{' '}
                                {getMarketPlaceFromId(Number(marketplaceId)).name}{' '}
                            </Text>
                            {showEarlyTag(approvalType) && <EarlyPayBadge />}
                        </Row>
                        <Text tag='div' alignment='start'>
                            <Text type='h100'>Earnings Period</Text>
                            <Text>
                                {`${formatDateAsString(paymentStartDate)} to ${formatDateAsString(
                                    paymentEndDate
                                )}`}
                            </Text>
                        </Text>
                    </Column>
                </Row>
                <Row alignmentVertical='top' wrap='down'>
                    <Column spacing='400' minWidth={150}>
                        <Text tag='div' alignment='start'>
                            <Text type='h100'>Due Date</Text>
                            {formatDateAsString(approvalPhaseInfo.approvalDueDate)} <br />
                            <Tag type={dueDateTagHelper(approvalPhaseInfo).status}>
                                {dueDateTagHelper(approvalPhaseInfo).text}
                            </Tag>
                        </Text>
                        <Text tag='div' alignment='start'>
                            <Text type='h100'># of Payments</Text>
                            {isEmpty(amountSummary) ? (
                                <Text>N/A</Text>
                            ) : (
                                map(amountSummary, (summary: SnapShotAmountSummary) => (
                                    <Text>{summary.count}</Text>
                                ))
                            )}
                        </Text>
                        <Text tag='div' alignment='start'>
                            <Text type='h100'>GL Product Group</Text>
                            <Text>{`${glProductGroup}`}</Text>
                        </Text>
                    </Column>
                    <Column minWidth={150}>
                        <Text tag='div' alignment='start'>
                            <Text type='h100'>Total Amounts</Text>
                            {isEmpty(amountSummary) ? (
                                <Text>N/A</Text>
                            ) : (
                                map(amountSummary, (summary: SnapShotAmountSummary) => (
                                    <Text>
                                        {currencyFormatHelper(summary.amount, summary.currency)}
                                    </Text>
                                ))
                            )}
                        </Text>
                    </Column>
                </Row>
                <Row alignmentVertical='bottom'>
                    <Button
                        type='primary'
                        onClick={onApproveSnapshot}
                        disabled={isApproveButtonDisabled}>
                        {`${getApprovalName(snapShotApprovalType)} Approval`}
                    </Button>
                    <Button type='tertiary' onClick={onDownload}>
                        Email Report
                    </Button>
                </Row>
            </Column>
        </Card>
    );
};

export default memo(
    PendingApprovalsTableCard,
    (prevProps, nextProps) =>
        prevProps.businessApprovalAllowed === nextProps.businessApprovalAllowed &&
        prevProps.financeApprovalAllowed === nextProps.financeApprovalAllowed &&
        prevProps.snapshot.snapShotId === nextProps.snapshot.snapShotId &&
        prevProps.snapshot.approvalPhaseInfo.approver ===
            nextProps.snapshot.approvalPhaseInfo.approver &&
        prevProps.snapshot.amountSummary === nextProps.snapshot.amountSummary
);
