import React from 'react';

import Tag from '@amzn/meridian/tag';
import Tooltip from '@amzn/meridian/tooltip';

export default class EarlyPayBadge extends React.Component {
    render() {
        return (
            <Tooltip
                position='end'
                title='This is a tag configured by your business to approve these disbursements earlier than usual
             based on the net payment terms'>
                <Tag>Early Pay</Tag>
            </Tooltip>
        );
    }
}
