import {
  UserClaims,
  ResourceAttributes,
  ResourceOperations,
  Resources,
  BulkApprovalOperations,
  BulkApprovalAttributes,
} from "src/authorization/types";

import _ from "lodash";
import { fetchUserClaims } from "./token_handler";

export const isAuthorized = (
  resource: Resources,
  operation: ResourceOperations,
  attribute: ResourceAttributes
): boolean => {
  const userClaims = fetchUserClaims();
  if (userClaims) {
    if (resource === Resources.BULK_APPROVAL) {
      return authorizeBulkApproval(operation, attribute, userClaims);
    }
  }
  return false;
};

const authorizeBulkApproval = (
  operation: ResourceOperations,
  attribute: ResourceAttributes,
  userClaims: UserClaims
): boolean => {
  const bulkApprovalClaims = userClaims.bulk_approval;
  let operation_permissions: [] | BulkApprovalAttributes[] = [];
  switch (operation) {
    case BulkApprovalOperations.BUSINESS_APPROVAL:
      operation_permissions = bulkApprovalClaims.business_approval;
      break;
    case BulkApprovalOperations.FINANCE_APPROVAL:
      operation_permissions = bulkApprovalClaims.finance_approval;
      break;
    case BulkApprovalOperations.VIEW:
      operation_permissions = bulkApprovalClaims.view;
      break;
  }
  return _.some(operation_permissions, attribute);
};
