import { TargetDomEvent } from 'aws-rum-web';

export const rumIdLocator = (value: string): string => {
    return `[data-rum-id="${value}"]`;
};

export const headerRumDomEvents: TargetDomEvent[] = [
    { event: 'click', cssLocator: rumIdLocator('btn-header-dapr') },
    { event: 'click', cssLocator: rumIdLocator('btn-header-feedback') },
    { event: 'click', cssLocator: rumIdLocator('btn-header-region-selector') }
];
