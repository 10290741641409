import { UPDATE_FEEDBACK_STATE, FeedbackKeys } from "./types";

export function updateFeedbackState(
  stateVariable: FeedbackKeys,
  value: any
): UPDATE_FEEDBACK_STATE {
  return {
    type: "@@FEEDBACK/UPDATE_FEEDBACK_STATE",
    payload: { [stateVariable]: value }
  };
}
