import Digitalvendorpaymentsgateway from "@amzn/digital-vendor-payments-gateway-client";
import {
  Metadata,
  Operation,
  OperationExecutor,
} from "@amzn/digital-vendor-payments-gateway-client/structures";
import { API } from "aws-amplify";
import { getCompressedToken } from "src/authorization/token_handler";

function createExecutor(): OperationExecutor {
  return (
    metadata: Metadata,
    operation: Operation,
    request?: any
  ): Promise<any> => {
    const header: any = { "Content-Type": "application/x-amz-json-1.1" };
    const token = getCompressedToken();
    if (token) {
      header["DVPG-Auth"] = token;
    }
    const payload: any = {
      headers: header,
    };
    if (operation.http.method === "POST") {
      payload["body"] = request;
      return API.post(
        PAYMENT_APPROVAL_SERVICE,
        operation.http.requestUri,
        payload
      );
    }
    payload["queryStringParameters"] = request;
    return API.get(
      PAYMENT_APPROVAL_SERVICE,
      operation.http.requestUri,
      payload
    );
  };
}
export const digitalVendorPaymentGateway = new Digitalvendorpaymentsgateway(
  createExecutor()
);

export const PAYMENT_APPROVAL_SERVICE = "PaymentApprovalService";
