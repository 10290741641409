import React, { memo } from 'react';

import { TableCell } from '@amzn/meridian/table';

import { formatDateAsString } from '@app/helpers';

interface Props {
    paymentStartDate: number;
    paymentEndDate: number;
}

const EarningsPeriodCell: React.FunctionComponent<Props> = ({
    paymentStartDate,
    paymentEndDate
}) => {
    return (
        <TableCell alignmentHorizontal='center'>
            {formatDateAsString(paymentStartDate)} {' to '}
            {formatDateAsString(paymentEndDate)}
        </TableCell>
    );
};

export default memo(
    EarningsPeriodCell,
    (prevProps, nextProps) =>
        prevProps.paymentStartDate === nextProps.paymentStartDate &&
        prevProps.paymentEndDate === nextProps.paymentEndDate
);
