import { useSelector } from 'react-redux';

import { RootState } from '..';

export const useFiltersSelector = () =>
	useSelector((state: RootState) => state.bulkSnapshotApproval.filters);

export const useBusinessesSelector = () =>
	useSelector((state: RootState) => state.bulkSnapshotApproval.businesses);

export const useMarketplacesSelector = () =>
	useSelector((state: RootState) => state.bulkSnapshotApproval.marketPlaces);

export const useSendBulkApprovalReportSelector = () =>
	useSelector((state: RootState) => state.bulkSnapshotApproval.sendBulkApprovalReport);

export const usePlaceBulkApprovalSelector = () =>
	useSelector((state: RootState) => state.bulkSnapshotApproval.placeBulkApproval);
