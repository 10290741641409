import React from 'react';

import Icon from '@amzn/meridian/icon';
import { TableCell, TableRow } from '@amzn/meridian/table';
import Tooltip from '@amzn/meridian/tooltip';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
export const ApprovalsHistoryHeaderRow: React.FunctionComponent = () => {
    return (
        <TableRow>
            <TableCell alignmentHorizontal='center' header>
                Business
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                GL
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Marketplace
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Earnings Period
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Amount
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                # of Payments
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Due Date
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Approver
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Approval Date
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                <>Approval Type</>{' '}
                <Tooltip position='end' title='Business / Finance'>
                    <Icon tokens={infoKnockoutTokens} />
                </Tooltip>
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                <>Approval Status</>{' '}
                <Tooltip position='end' title='In Progress / Approved / Failed'>
                    <Icon tokens={infoKnockoutTokens} />
                </Tooltip>
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                <>Download Report</>{' '}
                <Tooltip
                    position='end'
                    title='By clicking download, you will download a CSV containing the payments involved with this approval'>
                    <Icon tokens={infoKnockoutTokens} />
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};
