import React, { useEffect } from "react";
import Text from "@amzn/meridian/text";
import Row from "@amzn/meridian/row";
import About from "src/components/home/About";
import Content from "src/components/common/Content";
import Heading from "@amzn/meridian/heading";
import { css } from "emotion";
import { RootState } from "src/reducers";
import { useSelector } from "react-redux";
// @ts-ignore
import token from "@amzn/meridian-tokens/base/color";
import { useHistory } from "react-router";

const Home: React.FunctionComponent = () => {
  const userNameSelector = (state: RootState) => state.user;

  /**
   * Because of a migration of HashRouter to BrowserRouter.
   * We added this to have backwards compatibility of the previous routes.
   */
  const history = useHistory();
  useEffect(() => {
    if (location.hash.startsWith('#/')) {
      history.push(location.hash.replace('#', ''));
    }
  });

  /**
   * useSelector is a custom hook provided by redux, which is a replica for mapStateToProps 
   * function provided by redux store
   * */ 
  const userData = useSelector(userNameSelector);
  const style = () =>
    css({
      borderBottom: `1px solid ${token.colorGrayTranslucentMid500}`,
    });
  return <>
    <Row
      alignmentHorizontal="justify"
      alignmentVertical="top"
      spacingInset="400"
      wrap="down"
      widths={["grid-12"]}
      backgroundColor="secondary"
      className={style()}
    >
      <Content spacingInset="400">
        <Heading level={1} type="d100">
          Welcome {userData.username}
        </Heading>
        <Text type="b300">
          This tool is intended for business and finance users 
          who are responsible for approving payments before they are paid out to vendors.
        </Text>{" "}
      </Content>
    </Row>
    <Row
      alignmentVertical="top"
      alignmentHorizontal="start"
      spacingInset="400"
      wrap="down"
      widths={["grid-12"]}
    >
      <About />
    </Row>
  </>;
};

export default Home;
