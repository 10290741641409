import React from "react";
import Card, { CardHeader } from "@amzn/meridian/card";
import Heading from "@amzn/meridian/heading";
import { useHistory } from "react-router-dom";
import Tag from "@amzn/meridian/tag";
import { css } from "emotion";
import Box from "@amzn/meridian/box";

export enum LinkType {
  Internal = "internal",
  External = "external"
}
interface ICardProps {
  heading?: string;
  isComingSoon?: boolean;
  backgroundColor?: string;
  link?: string;
  linkType?: LinkType;
}
const style = () =>
  css({
    boxShadow: "rgba(11, 12, 12, 0.16) 0px 2px 4px 0px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: "rgb(231, 233, 233)",
    borderImage: "initial",
    borderRadius: "4px"
  });

const HomePageCard: React.FunctionComponent<ICardProps> = props => {
  let cardParams: any = {};
  let history = useHistory();
  if (props.linkType === LinkType.Internal) {
    cardParams.onClick = history.push;
  }
  return (
    <Box className={style()} spacingInset="500">
      <Heading level={3} type="h200">
        {props.heading}{" "}
        {props.isComingSoon && <Tag type="success">Coming Soon</Tag>}{" "}
      </Heading>
      <br />
      {props.children}
    </Box>
  );
};

export default HomePageCard;
