import {
  BulkApprovalState,
  BulkApprovalActionTypes,
  tInputGroup,
  REFRESH_APPROVALS,
  UPDATE_SELECTED_APPROVALS,
  UPDATE_BULKAPPROVAL_STATE,
  UPDATE_SINGLE_REQUEST,
  UPDATE_SUBMIT_STATUS, UPDATE_BULK_APPROVAL_DOWNLOAD_REPORT_REQUEST, UPDATE_DOWNLOAD_REPORT_SUBMIT_STATUS,
} from "./types";
import { Reducer } from "redux";

import startOfMonth from "date-fns/startOfMonth";
import addMonths from "date-fns/addMonths";
import formatISO from "date-fns/formatISO";

const getInitialInputState = (): tInputGroup => {
  const currentMonthStart = startOfMonth(new Date());
  const currentMonthString = formatISO(currentMonthStart, {
    representation: "date",
  });
  const previousMonthString = formatISO(addMonths(currentMonthStart, -1), {
    representation: "date",
  });
  let business = "";
  let marketplace = 0;
  return {
    date_range: [previousMonthString, currentMonthString],
    business: business,
    marketplace: marketplace,
  };
};

const  removeProperty = (object: any, propertyName: any) => {
  let { [propertyName]: _, ...result } = object
  return result
}

const initialState: BulkApprovalState = {
  inputGroup: getInitialInputState(),
  approvals: [],
  selectAll: false,
  fetchError: false,
  submitError: false,
  showDownloadBulkApprovalReportWindowConfirmation: false,
  showConfirmation: false,
  isSubmitted: false,
};

export const BulkApprovalReducer: Reducer<BulkApprovalState> = (
  state: BulkApprovalState = initialState,
  action
) => {
  switch ((action as BulkApprovalActionTypes).type) {
    case "@@BULK_APPROVAL/UPDATE_INPUT":
      const updatedInputWithoutStatusFilter = removeProperty(state.inputGroup, "disb_status_filter");
      const updatedInput = { ...updatedInputWithoutStatusFilter, ...action.payload };
      return { ...state, inputGroup: updatedInput };
    case "@@BULK_APPROVAL/REFRESH_APPROVALS":
      const approvalsPayload = (action as REFRESH_APPROVALS).approvals;
      return {
        ...state,
        fetchError: false,
        selectAll: false,
        approvals: [...approvalsPayload],
      };
    case "@@BULK_APPROVAL/UPDATE_SELECTED_APPROVALS":
      const updatedSelectedPayload = action as UPDATE_SELECTED_APPROVALS;
      return {
        ...state,
        approvals: state.approvals.map((approval) => {
          if (updatedSelectedPayload.ids.includes(approval.id)) {
            return {
              ...approval,
              checked: (action as UPDATE_SELECTED_APPROVALS).value,
            };
          } else {
            return approval;
          }
        }),
      };
    case "@@BULK_APPROVAL/UPDATE_STATE_DIRECT":
      const error_payload = action as UPDATE_BULKAPPROVAL_STATE;
      return {
        ...state,
        [error_payload.key]: error_payload.value,
      };
    case "@@BULK_APPROVAL/UPDATE_SINGLE_REQUEST":
      const single_request_payload = action as UPDATE_SINGLE_REQUEST;
      return {
        ...state,
        showConfirmation: true,
        singleRequest: {
          id: single_request_payload.id,
          phase: single_request_payload.phase,
        },
      };
    case "@@BULK_APPROVAL/UPDATE_BULK_APPROVAL_DOWNLOAD_REPORT_REQUEST":
      const single_download_request_payload = action as UPDATE_BULK_APPROVAL_DOWNLOAD_REPORT_REQUEST;
      return {
        ...state,
        showDownloadBulkApprovalReportWindowConfirmation: true,
        singleReportDownloadRequest:{
          id: single_download_request_payload.id
        }
      };
    case "@@BULK_APPROVAL/CLEAR_SUBMIT_STATUS":
      return {
        ...state,
        showConfirmation: false,
        showDownloadBulkApprovalReportWindowConfirmation: false,
        submitError: false,
        isSubmitted: false,
        singleRequest: undefined,
        singleReportDownloadRequest:undefined,
        submitPayload: undefined,
      };
    case "@@BULK_APPROVAL/UPDATE_SUBMIT_STATUS":
      const submit_status_payload = action as UPDATE_SUBMIT_STATUS;
      return {
        ...state,
        isSubmitted: true,
        submitError: false,
        submitPayload: submit_status_payload.payload,
      };
    case "@@BULK_APPROVAL/UPDATE_DOWNLOAD_REPORT_SUBMIT_STATUS":
      const download_report_submit_status_payload = action as UPDATE_DOWNLOAD_REPORT_SUBMIT_STATUS;
      return {
        ...state,
        isSubmitted: true,
        submitError: false,
        submitReportDownloadPayload: download_report_submit_status_payload.payload,
      };
    default:
      return state;
  }
};
