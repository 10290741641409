import { isEmpty } from 'lodash';
import { getGls } from 'src/components/disbursementsummary/DisbursementSummaryInputGroup';
import {
    canAssignGl,
    hasGlPermissions,
    isDateRangeWithinLastThreeMonths,
    isValidDateForDisbursementSummary,
    isValidRangeForDisbursementSummary
} from 'src/helpers/disbursement_summary';

import {
    DateRangeTypeValue,
    DateRangeTypeValueEnum
} from '@amzn/digital-vendor-payments-gateway-client';

import { ALL_MARKETPLACE, MarketPlace, Month } from '@app/constants';
import { BulkApprovalFilters } from '@app/reducers';
import { tInputGroup } from '@app/reducers/bulk_approval/types';
import { tDisbursementSummaryInputGroup } from '@app/reducers/disbursement_summary/types';
import { getCurrentMonthAndYear } from '@app/util/date_util';
import {
    canAssignBusiness,
    canAssignMarketplace,
    isValidBusiness,
    isValidDate,
    isValidDateRangeType,
    isValidDisbursementStatusFilter,
    isValidMarketplace,
    isValidMonthAndYear,
    isValidRange
} from '@app/validators';

import { getMarketPlaceFromId } from './bulkSnapshotApproval';

export type optionalString = string | null;

export type tParsedValue = {
    errors: string[] | null;
    bulkApprovalInputs?: tInputGroup | null;
    disbursementSummaryInputs?: tDisbursementSummaryInputGroup | null;
    bulkSnapshotApprovalFilters?: BulkApprovalFilters | null;
};
export const parseBulkApprovalParams = (
    startDate: optionalString,
    endDate: optionalString,
    business: optionalString,
    marketplace: optionalString,
    dateRangeType?: optionalString,
    disbursementStatusFilter?: optionalString
): tParsedValue | null => {
    const errors: string[] = [];
    let start = '';
    let end = '';
    let mp = 0;
    let vp = '';
    let dateType = '';
    let disbStatusFilter = '';
    //No queryString
    if (!startDate && !endDate && !business && !marketplace) {
        return null;
    }

    if (startDate && isValidDate(startDate)) {
        start = startDate;
    } else {
        errors.push('Invalid Start Date');
    }

    if (endDate && isValidDate(endDate)) {
        end = endDate;
    } else {
        errors.push('Invalid End Date');
    }

    if (endDate && startDate && isValidRange(endDate, startDate)) {
        end = endDate;
    } else {
        errors.push('Invalid date range - should be less than 3 months');
    }

    if (business && business !== '' && canAssignBusiness(business)) {
        vp = business;
    } else {
        errors.push("Invalid business or you don't have access to the business");
    }

    if (marketplace && canAssignMarketplace(marketplace)) {
        mp = parseInt(marketplace);
    } else {
        marketplace &&
            errors.push("Invalid marketplace or you don't have access to the marketplace");
    }

    if (dateRangeType && isValidDateRangeType(dateRangeType)) {
        dateType = dateRangeType;
    } else {
        if (dateRangeType) {
            errors.push('Invalid dateRangeType');
        }
    }

    if (disbursementStatusFilter && isValidDisbursementStatusFilter(disbursementStatusFilter)) {
        disbStatusFilter = disbursementStatusFilter;
    } else {
        if (disbursementStatusFilter) {
            errors.push('Invalid disbursementStatusFilter');
        }
    }
    if (errors.length) {
        return {
            errors: errors,
            bulkApprovalInputs: null
        };
    } else {
        return {
            errors: null,
            bulkApprovalInputs: {
                date_range: [start, end],
                business: vp,
                marketplace: mp,
                date_range_type: dateType,
                disb_status_filter: disbStatusFilter
            }
        };
    }
};

export const parseListDisbursementSummaryParams = (
    startDate: optionalString,
    endDate: optionalString,
    gl: optionalString
): tParsedValue | null => {
    const errors: string[] = [];
    let yearString = '';
    let monthString = '';
    let glCode = '';
    //No queryString
    if (!startDate && !endDate && !gl) {
        return null;
    }

    if (startDate && isValidDateForDisbursementSummary(startDate)) {
        yearString = startDate.substr(0, 4);
        monthString = startDate.substr(5, 2);
    } else {
        errors.push('Invalid Start Date');
    }

    if (!endDate || !isValidDateForDisbursementSummary(endDate)) {
        errors.push('Invalid End Date');
    }

    if (!(endDate && startDate && isValidRangeForDisbursementSummary(startDate, endDate))) {
        errors.push('Invalid date range - difference should be one month');
    }

    if (!(endDate && startDate && isDateRangeWithinLastThreeMonths(startDate, endDate))) {
        errors.push('Invalid date range - start and end date can only be in last three months');
    }

    if (gl && canAssignGl(gl)) {
        glCode = gl;
    } else if ((!gl || gl == '') && hasGlPermissions()) {
        glCode = getGls()[0].glNum;
    } else {
        errors.push("Invalid GL or you don't have access to the GL");
    }

    if (errors.length) {
        return {
            errors: errors,
            disbursementSummaryInputs: null
        };
    } else {
        return {
            errors: null,
            disbursementSummaryInputs: {
                gl: glCode,
                year: yearString,
                month: monthString
            }
        };
    }
};

export const parseBulkSnapshotApprovalParams = (
    monthInput: optionalString,
    yearInput: optionalString,
    businessInput: optionalString,
    marketPlaceInput: optionalString,
    datePeriodTypeInput: optionalString
): { errors: string[] | null; bulkSnapshotApprovalFilters?: BulkApprovalFilters } | null => {
    const errors: string[] = [];
    const { currentMonth, currentYear } = getCurrentMonthAndYear();

    if (!monthInput && !yearInput && !businessInput && !marketPlaceInput && !datePeriodTypeInput) {
        return null;
    }

    if (!businessInput) {
        return { errors: ['Business is required'] };
    }
    if (!monthInput) {
        monthInput = `${currentMonth + 1}`;
    }
    if (!yearInput) {
        yearInput = `${currentYear}`;
    }
    if (!marketPlaceInput) {
        marketPlaceInput = '0';
    }
    if (!datePeriodTypeInput) {
        datePeriodTypeInput = DateRangeTypeValueEnum.EARNING_PERIOD;
    }

    let month: Month = currentMonth as Month;
    let year: number = currentYear;
    let business: string = '';
    let marketPlace: MarketPlace = ALL_MARKETPLACE;
    let datePeriodType: DateRangeTypeValue = DateRangeTypeValueEnum.EARNING_PERIOD;

    // If valid month and year are given, use that or use the defaults (latest payment period)
    if (isValidMonthAndYear(monthInput, yearInput)) {
        month = (Number(monthInput) - 1) as Month;
        year = Number(yearInput);
    }

    if (isValidBusiness(businessInput)) {
        business = businessInput;
    } else {
        errors.push("Invalid business or you don't have access to the business");
    }

    if (isValidMarketplace(marketPlaceInput)) {
        marketPlace = getMarketPlaceFromId(Number(marketPlaceInput));
    } else {
        errors.push("Invalid marketplace or you don't have access to the marketplace");
    }

    if (isValidDateRangeType(datePeriodTypeInput)) {
        datePeriodType = datePeriodTypeInput as DateRangeTypeValue;
    } else {
        errors.push('Invalid date period type');
    }

    if (!isEmpty(errors)) {
        return { errors };
    }

    return {
        errors: null,
        bulkSnapshotApprovalFilters: {
            month,
            year,
            business,
            marketPlace,
            datePeriodType
        }
    };
};
