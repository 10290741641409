import { parseISO } from 'date-fns';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateInputMutiple } from 'src/reducers/bulk_approval';

import { DateRangeTypeValue } from '@amzn/digital-vendor-payments-gateway-client';

import BulkApproval from '@app/components/bulkapproval/BulkApproval';
import { Month } from '@app/constants';
import { formatDateAsString } from '@app/helpers';
import { useCutoffConfig } from '@app/hooks';
import {
    RootState,
    updateAppState,
    updateMultipleFilters,
    useFiltersSelector,
    useShowNewExperienceSelector
} from '@app/reducers';
import {
    convertPaymentDateToEarningPeriodDate,
    getPaymentEndDate,
    getPaymentStartDate,
    getPreviousMonthAndYear
} from '@app/util/date_util';

import { BulkSnapshotApproval } from './bulkSnapshotApproval';

export const BulkApprovalExperience: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const showNewExperience = useShowNewExperienceSelector();
    const {
        business: businessOld,
        date_range,
        date_range_type
    } = useSelector((state: RootState) => state.bulk_approval.inputGroup);
    const { business: businessNew, month, year, datePeriodType } = useFiltersSelector();
    const { isNewExperienceEnabled } = useCutoffConfig();

    useEffect(() => {
        // no business selected in old ui or already showing new experience
        if (!businessOld || showNewExperience) {
            return;
        }

        const paymentEndDate = parseISO(date_range[1]);
        // compute earning period date for EFFECTIVE_DATE search type only for checking config
        const earningPeriodEndDate = convertPaymentDateToEarningPeriodDate(
            paymentEndDate.getTime(),
            businessOld,
            date_range_type
        );
        // new experience month and year selector should get same date as end date in old experience
        const [startMonth, startYear] = getPreviousMonthAndYear(
            paymentEndDate.getMonth(),
            paymentEndDate.getFullYear()
        );

        if (isNewExperienceEnabled(businessOld, earningPeriodEndDate)) {
            dispatch(
                updateMultipleFilters({
                    month: startMonth as Month,
                    year: startYear,
                    business: businessOld,
                    datePeriodType: date_range_type as DateRangeTypeValue
                })
            );
            dispatch(updateAppState('showNewExperience', true));
        }
    }, [businessOld, date_range, date_range_type, showNewExperience]);

    useEffect(() => {
        // no business selected in new ui or already showing old experience
        if (!businessNew || !showNewExperience) {
            return;
        }

        // compute earning period date for EFFECTIVE_DATE search type only for checking config
        const earningPeriodEndDate = convertPaymentDateToEarningPeriodDate(
            getPaymentEndDate(month, year) * 1000,
            businessNew,
            datePeriodType
        );

        if (!isNewExperienceEnabled(businessNew, earningPeriodEndDate)) {
            dispatch(
                updateInputMutiple({
                    business: businessNew,
                    // old experience date range should be same as in new experience
                    date_range: [
                        formatDateAsString(getPaymentStartDate(month, year)),
                        formatDateAsString(getPaymentEndDate(month, year))
                    ],
                    date_range_type: datePeriodType
                })
            );
            dispatch(updateAppState('showNewExperience', false));
        }
    }, [businessNew, month, year, datePeriodType, showNewExperience]);

    return showNewExperience ? <BulkSnapshotApproval /> : <BulkApproval />;
};
