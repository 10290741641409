import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { DateRangeTypeValue } from '@amzn/digital-vendor-payments-gateway-client';
import Row from '@amzn/meridian/row';

import {
	BusinessSelector,
	DatePeriodTypeSelector,
	MarketplaceSelector,
	MonthSelector,
	YearSelector
} from '@app/components/common';
import { Month } from '@app/constants';
import {
	formQueryUrl,
	getAvailableBusinesses,
	getAvailableMarketPlacesForBusiness,
	getMarketPlaceFromId
} from '@app/helpers';
import {
	BulkApprovalFilters,
	updateAvailableBusinesses,
	updateAvailableMarketPlaces,
	updateBusiness,
	updateDatePeriodType,
	updateMarketPlace,
	updateMonth,
	updateYear,
	useBusinessesSelector,
	useFiltersSelector,
	useMarketplacesSelector
} from '@app/reducers';

const BulkSnapshotFilter: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const businesses = useBusinessesSelector();
	const marketPlaces = useMarketplacesSelector();
	const filters = useFiltersSelector();
	const { month, year, business, marketPlace, datePeriodType } = filters;

	const updateQueryParam = (filters: BulkApprovalFilters) => {
		const queryString = formQueryUrl(filters);
		history.push({
			search: queryString
		});
	};

	const onUpdateMonth = (month: Month) => {
		dispatch(updateMonth(month));
	};

	const onUpdateYear = (year: number) => {
		dispatch(updateYear(year));
	};

	const onUpdateBusiness = (business: string) => {
		dispatch(updateBusiness(business));
	};

	const onUpdateMarketPlace = (id: number) => {
		dispatch(updateMarketPlace(getMarketPlaceFromId(id)));
	};

	const onUpdateDatePeriodType = (datePeriodType: DateRangeTypeValue) => {
		dispatch(updateDatePeriodType(datePeriodType));
	};

	useEffect(() => {
		dispatch(updateAvailableBusinesses(getAvailableBusinesses()));
	}, []);

	useEffect(() => {
		updateQueryParam(filters);
	}, [filters]);

	useEffect(() => {
		dispatch(updateAvailableMarketPlaces(getAvailableMarketPlacesForBusiness(business)));
	}, [business]);

	return (
		<Row alignmentVertical='center' widths='grid-2'>
			<YearSelector selectedYear={year} onUpdateYear={onUpdateYear} />
			<MonthSelector
				selectedMonth={month}
				selectedYear={year}
				onUpdateMonth={onUpdateMonth}
			/>
			<BusinessSelector
				businesses={businesses}
				selectedBusiness={business}
				onUpdateBusiness={onUpdateBusiness}
			/>
			<MarketplaceSelector
				marketPlaces={marketPlaces}
				selectedMarketPlace={marketPlace}
				onUpdateMarketPlace={onUpdateMarketPlace}
			/>
			<DatePeriodTypeSelector
				datePeriodType={datePeriodType}
				onUpdateDatePeriodType={onUpdateDatePeriodType}
			/>
		</Row>
	);
};

export default BulkSnapshotFilter;
