import { isEmpty, map } from 'lodash';
import React, { Fragment, memo } from 'react';

import {
    SnapShotAmountSummary,
    SnapShotAmountSummaryList
} from '@amzn/digital-vendor-payments-gateway-client';
import { TableCell } from '@amzn/meridian/table';

interface Props {
    amountSummary: SnapShotAmountSummaryList | { [key: string]: SnapShotAmountSummary };
}

const AmountCountCell: React.FunctionComponent<Props> = ({ amountSummary }) => {
    const isAmountSummaryEmpty = isEmpty(amountSummary);

    return (
        <TableCell alignmentHorizontal='center'>
            {isAmountSummaryEmpty ? (
                <span>N/A</span>
            ) : (
                map(amountSummary, (summary: SnapShotAmountSummary) => (
                    <Fragment key={summary.currency}>
                        {summary.count}
                        <br />
                    </Fragment>
                ))
            )}
        </TableCell>
    );
};

export default memo(
    AmountCountCell,
    (prevProps, nextProps) => prevProps.amountSummary === nextProps.amountSummary
);
