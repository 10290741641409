import {
  BulkApproval,
} from "@amzn/digital-vendor-payments-gateway-client";
import {isAuthorized} from "src/authorization/authorization_manager";
import {BulkApprovalOperations, Resources} from "src/authorization/types";
import {
  tApproval,
  tApprovalPhases,
  tInputGroup,
  tSingleReportDownloadRequest,
  tSingleRequest,
} from "src/reducers/bulk_approval/types";
import {differenceInDays, startOfDay} from "date-fns";
import {formatToTimeZone} from "date-fns-timezone";
import {TagType} from "@amzn/meridian/tag/tag";
import {tSubmitDownloadBulkApprovalRequest, tSumbitRequest} from "src/apis/bulk_approval";
import {fetchUserClaims} from "src/authorization/token_handler";
import {getCurrentStageConfig} from "src/constants/stage";
import {getBusiness} from "src/components/bulkapproval/InputGroup";
import {APUB_DISBURSEMENT_TYPES} from "src/constants/app"
import {createKatalMetricsPublisher} from "src/helpers/katal_metrics_emitter";
import {RootState} from "src/reducers";
import {useSelector} from "react-redux";

export type tSummary = {
  payments?: number;
  variance: number;
  variance_percent?: number;
  totalAmount?: number;
  currency: string;
};

/**
 * This method builds summary information in format of tSummary from the list api result
 * @param item
 */
export const summaryBuilder = (item: BulkApproval): tSummary[] | undefined => {
  let finalSummary: tSummary[] | undefined;
  finalSummary = getCurrentMonthSummary(item);
  addVarianceInfo(item, finalSummary);
  return finalSummary;
};

//Method to extract only the current month summary information from the list api result
const getCurrentMonthSummary = (item: BulkApproval) => {
  return item.summary
    ?.filter((summary) =>  {
      return (APUB_DISBURSEMENT_TYPES.includes(item.business)) ?
        (summary.startDate === item.paymentStartDate) :
        (summary.startDate === item.paymentEndDate)
    })
    .map((item) => {
      return {
        payments: item.disbursementsCount,
        totalAmount: item.totalAmount,
        variance: item.totalAmount,
        currency: item.currencyCode,
      };
    });
};

//Method to extract the previous month summary info from list api result and
//convert it into variance by comparing against current month summary.
const addVarianceInfo = (
  item: BulkApproval,
  finalSummary: tSummary[] | undefined
) => {
  item.summary
    ?.filter((summary) => {
      return (APUB_DISBURSEMENT_TYPES.includes(item.business)) ?
        (summary.endDate === item.paymentStartDate) :
        (summary.startDate === item.paymentStartDate)
    })
    .forEach((summary) => {
      const filtered = finalSummary?.filter(
        (s1) => summary.currencyCode == s1.currency
      );
      if (filtered && filtered.length) {
        const current = filtered[0];
        current.variance = current.variance - summary.totalAmount;
        if (summary.totalAmount) {
          current.variance_percent =
            (current.variance / summary.totalAmount) * 100;
        }
      } else {
        finalSummary?.push({
          currency: summary.currencyCode,
          variance: -summary.totalAmount,
        });
      }
    });
  };

export const approvalPermissionSummary = (approval: tApproval) => {
  const item = approval.payload;
  return approvalPermissionSummaryOnItem(item);
};

export const approvalPermissionSummaryOnItem = (
  item: BulkApproval
) => {
  const isApproved =
    item.financePhaseInfo?.bulkApprovalId &&
    item.businessPhaseInfo?.bulkApprovalId
      ? true
      : false;
  const isBusinessApproved = item.businessPhaseInfo?.bulkApprovalId
    ? true
    : false;
  const isFinanceApproved = item.financePhaseInfo?.bulkApprovalId
    ? true
    : false;
  const bulkAttributes = {
    vp: item.business,
    mp: item.marketplaceId,
    typ: item.approvalType,
  };
  const canApproveBusiness = isAuthorized(
    Resources.BULK_APPROVAL,
    BulkApprovalOperations.BUSINESS_APPROVAL,
    bulkAttributes
  );
  const canApproveFinance = isAuthorized(
    Resources.BULK_APPROVAL,
    BulkApprovalOperations.FINANCE_APPROVAL,
    bulkAttributes
  );
  const hasPermissionOnBoth = canApproveBusiness && canApproveFinance;
  const noPermission = !canApproveFinance && !canApproveBusiness;
  return {
    hasPermissionOnBoth,
    isBusinessApproved,
    isFinanceApproved,
    isApproved,
    noPermission,
    canApproveFinance,
    canApproveBusiness,
  };
};

export const filterApprovalsForConfirmation = (
  allApprovals: tApproval[],
  singleRequest: tSingleRequest | undefined
): tSumbitRequest[] => {
  let approvalRequest: tSumbitRequest[];
  if (singleRequest) {
    approvalRequest = allApprovals
      .filter((approval) => approval.id === singleRequest.id)
      .map((approval) => {
        return { phase: singleRequest.phase, payload: approval };
      });
  } else {
    approvalRequest = allApprovals
      .filter((approval) => approval.checked)
      .map((approval) => {
        const { canApproveBusiness } = approvalPermissionSummary(approval);
        return {
          phase: canApproveBusiness ? "business" : "finance",
          payload: approval,
        };
      });
  }

  approvalRequest
    .filter((submitRequest) => isSodViolated(submitRequest.payload.payload, submitRequest.phase))
    .forEach((submitRequest) => {
      const bulkApproval = submitRequest.payload.payload;
      const actionMetricsPublisher = createKatalMetricsPublisher('bulk_approval_sod_violation');
      actionMetricsPublisher.publishCounterMonitor(bulkApproval.business+'_'+
                                                    bulkApproval.glProductGroup+'_'+
                                                    bulkApproval.marketplaceId, 1);
    });

  approvalRequest = approvalRequest
    .filter((submitRequest) => !isSodViolated(submitRequest.payload.payload, submitRequest.phase));
  return approvalRequest;
};

export const filterApprovalsForReportDownload = (
    allApprovals: tApproval[],
    singleReportDownloadRequest: tSingleReportDownloadRequest | undefined,
    bulkApprovalInput: tInputGroup
): tSubmitDownloadBulkApprovalRequest => {
  let reportDownloadRequests: tSubmitDownloadBulkApprovalRequest[] = [];
  if (singleReportDownloadRequest) {
    reportDownloadRequests = allApprovals
        .filter((approval) => approval.id === singleReportDownloadRequest.id)
        .map((approval) => {
          return {  payload: approval , bulkApprovalPageFilters: bulkApprovalInput };
        });
  }
  return reportDownloadRequests[0];
};

export const isSodViolated = (
  item: BulkApproval,
  phase: tApprovalPhases
): boolean => {

  const betaSuperUser = isBetaSuperUser();
  if(betaSuperUser) {
    return false;
  }

  const {
    isBusinessApproved,
    isFinanceApproved,
  } = approvalPermissionSummaryOnItem(item);

  const businessApprover = item.businessPhaseInfo?.approver;
  const financeApprover = item.financePhaseInfo?.approver;
  const userId = fetchUserClaims()?.user?.user_id;

  if(phase === "business") {
    return (isFinanceApproved && financeApprover === userId);
  } else {
    return (isBusinessApproved && businessApprover === userId);
  }
}

export const isBetaSuperUser = (): boolean => {
  const stageConfig = getCurrentStageConfig();
  const permittedBusinesses = getBusiness();
  return stageConfig.STAGE === "beta" && permittedBusinesses.length > 4;
}

export const formatDateAsString = (date: number | null | undefined) => {
  const format = "YYYY-MM-DD";
  return date
    ? formatToTimeZone(date * 1000, format, { timeZone: "Etc/UTC" })
    : "NA";
};

export const SLATagHelper = (approvalPayload: BulkApproval) => {
  let showTag = false;
  const isApproved =
    approvalPayload.financePhaseInfo?.bulkApprovalId &&
    approvalPayload.businessPhaseInfo?.bulkApprovalId;
  const dueDate = approvalPayload.businessPhaseInfo?.approvalDueDate;
  const startOfToday = startOfDay(new Date());
  let status: TagType = "theme";
  let tag: string = "";
  if (dueDate) {
    showTag = true;
    const diff = differenceInDays(
      convertUTCtoLocal(dueDate * 1000),
      startOfToday
    );
    if (diff > 0) {
      status = "success";
      tag = "Due Soon";
    } else if (diff == 0) {
      status = "warning";
      tag = "Due Today";
    } else {
      status = "error";
      tag = "Past Due";
    }
  }
  return {
    showTag: showTag && !isApproved,
    status: status,
    text: tag,
  };
};

export const isApprovalWindowOpen = (item: BulkApproval) => {
  const current = new Date();
  const windowStartDate = item.businessPhaseInfo?.approvalWindowStartDate;
  return windowStartDate
    ? current > convertUTCtoLocal(windowStartDate * 1000)
    : true;
};

/**
 * Method which returns whether the approval can be selected in bulk and can be approved.
 * @param approval
 */
export const canSelectApproval = (approval: tApproval) => {
  const {
    hasPermissionOnBoth,
    noPermission,
    isBusinessApproved,
    isFinanceApproved,
    canApproveBusiness,
    canApproveFinance,
  } = approvalPermissionSummary(approval);
  const enableApproval = isApprovalWindowOpen(approval.payload);

  const result =
    hasPermissionOnBoth ||
    !enableApproval ||
    noPermission ||
    (canApproveBusiness && isBusinessApproved) ||
    (canApproveFinance && isFinanceApproved);
  return !result;
};

export const formQueryString = (input: tInputGroup) => {
  let querString = "?";
  if (input.date_range[0]) {
    querString += `start_date=${input.date_range[0]}&`;
  }
  if (input.date_range[1]) {
    querString += `end_date=${input.date_range[1]}&`;
  }
  if (input.business) {
    querString += `business=${input.business}&`;
  }
  if (input.marketplace) {
    querString += `marketplace=${input.marketplace}&`;
  }
  if (input.date_range_type) {
    querString += `date_range_type=${input.date_range_type}&`;
  }
  if (input.disb_status_filter) {
    querString += `disb_status_filter=${input.disb_status_filter}`;
  }
  return querString;
};

const convertUTCtoLocal = (date: number) => {
  var localOffset = new Date().getTimezoneOffset() * 60000;
  return new Date(date + localOffset);
};
