import React from 'react';

import { TableCell, TableRow } from '@amzn/meridian/table';

export const ConfirmationHeader: React.FunctionComponent = () => {
	return (
		<TableRow>
			<TableCell alignmentHorizontal='center' header>
				Business
			</TableCell>
			<TableCell alignmentHorizontal='center' header>
				GL
			</TableCell>
			<TableCell alignmentHorizontal='center' header>
				Marketplace
			</TableCell>
			<TableCell alignmentHorizontal='center' header>
				Earnings Period
			</TableCell>
			<TableCell alignmentHorizontal='center' header>
				Total Amount
			</TableCell>
			<TableCell alignmentHorizontal='center' header>
				No.of Payments
			</TableCell>
		</TableRow>
	);
};
