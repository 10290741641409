import React from "react";
import { InputGroup } from "./InputGroup";
import { ListBulkApproval } from "./BulkApprovalList";
import { SubmitBulkApproval } from "./SubmitBulkApproval";
import { fetchUserClaims } from "src/authorization/token_handler";
import Row from "@amzn/meridian/row/row";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import {SendBulkApprovalReport} from "src/components/bulkapproval/SendBulkApprovalReport";

const BulkApproval: React.FunctionComponent = () => {
  const viewPermissions = fetchUserClaims()?.bulk_approval.view;
  return viewPermissions && viewPermissions.length ? (
    <>
      <InputGroup> </InputGroup>
      <ListBulkApproval></ListBulkApproval>
      <SubmitBulkApproval></SubmitBulkApproval>
      <SendBulkApprovalReport></SendBulkApprovalReport>
    </>
  ) : (
    <Row alignmentHorizontal="center" spacingInset="large">
      <Alert size="large" type="informational">
        <Text type="b500">
          You don't have access to view/approve bulk approvals for any business.
          <br />
          Visit the request access link from Help section to get permissions.
        </Text>
      </Alert>
    </Row>
  );
};
export default BulkApproval;
