import React, { memo } from 'react';
import { EarlyPayBadge } from 'src/components/bulkSnapshotApproval/common';

import { approvalTypes } from '@amzn/digital-vendor-payments-gateway-client';
import { TableCell } from '@amzn/meridian/table';

import { showEarlyTag } from '@app/helpers';

interface Props {
    disbursementType: string;
    approvalType: approvalTypes;
}

const BusinessCell: React.FunctionComponent<Props> = ({ disbursementType, approvalType }) => {
    return (
        <TableCell alignmentHorizontal='center'>
            {disbursementType} <br />
            {showEarlyTag(approvalType) && <EarlyPayBadge />}
        </TableCell>
    );
};

export default memo(
    BusinessCell,
    (prevProps, nextProps) =>
        prevProps.approvalType === nextProps.approvalType &&
        prevProps.disbursementType === nextProps.disbursementType
);
