import React, { memo } from 'react';

import { BulkApprovalSnapShot } from '@amzn/digital-vendor-payments-gateway-client';
import { TableCell, TableRow } from '@amzn/meridian/table';

import {
    AmountCountCell,
    AmountSummaryCell,
    BusinessCell,
    EarningsPeriodCell
} from '@app/components/bulkSnapshotApproval/cells';
import { getMarketPlaceFromId } from '@app/helpers';

interface Props {
    snapshot: BulkApprovalSnapShot;
}

const ConfirmationRow: React.FunctionComponent<Props> = ({ snapshot }) => {
    const { snapShotId, approvalType, disbursementType, glProductGroup, marketplaceId } = snapshot;
    const { paymentStartDate, paymentEndDate, amountSummary } = snapshot;

    return (
        <TableRow key={snapShotId}>
            <BusinessCell approvalType={approvalType} disbursementType={disbursementType} />
            <TableCell alignmentHorizontal='center'>{glProductGroup}</TableCell>
            <TableCell alignmentHorizontal='center'>
                {getMarketPlaceFromId(Number(marketplaceId)).name}
            </TableCell>
            <EarningsPeriodCell
                paymentStartDate={paymentStartDate}
                paymentEndDate={paymentEndDate}
            />
            <AmountSummaryCell amountSummary={amountSummary} />
            <AmountCountCell amountSummary={amountSummary} />
        </TableRow>
    );
};

export default memo(
    ConfirmationRow,
    (prevProps, nextProps) => prevProps.snapshot.snapShotId === nextProps.snapshot.snapShotId
);
