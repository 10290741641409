import React from "react";
import {
  ResourceAttributes,
  ResourceOperations,
  Resources
} from "src/authorization/types";

import { isAuthorized } from "src/authorization/authorization_manager";

interface IwithAuth {
  resource: Resources;
  operation: ResourceOperations;
  attributes: ResourceAttributes;
}

/**
 * The Success and Failure components are created just to have better code readability.
 * They should never be exported and instantiated independently outside of Authorize element.
 */
class Success extends React.Component<{}, {}> {
  render() {
    return this.props.children;
  }
}

class Failed extends React.Component<{}, {}> {
  render() {
    return this.props.children;
  }
}
/**
 * Authorization component which needs to be used in all the places
 * where component needs to be conditionally rendered on user permissions.
 * Takes in IWithAuth interface as props and decides whether
 * the component needs to be rendered or not.
 * Example Usage 1: Here Component will be rendered if user has permission
 * <Authorized
    resource={Resources.BULK_APPROVAL}
    operation={BulkApprovalOperations.VIEW}
    attributes={{
        vp: "AppStoreBanjo",
        mp: 6,
        gl: 405,
        typ: "normal"
    }}> <Home /></Authorized>    
 * Example Usage 2: Taking both Success and Failure.
    <Authorized
        resource={Resources.BULK_APPROVAL}
        operation={BulkApprovalOperations.VIEW}
        attributes={{
            vp: "AppStoreBanjo",
            mp: 6,
            gl: 405,
            typ: "normal"
    }} >                
        <Authorized.SUCCESS>
            <Home />
        </Authorized.SUCCESS>
        <Authorized.FAILURE>
            <FallBackComponent/>
        </Authorized.FAILURE>
    </Authorized>
 */

export class Authorize extends React.Component<IwithAuth, {}> {
  static SUCCESS = Success;
  static FAILURE = Failed;
  constructor(props: IwithAuth) {
    super(props);
  }

  filterChild(childrenArray: React.ReactNodeArray, Component: React.ReactNode) {
    return childrenArray.find((child: any) => child.type == Component);
  }

  render() {
    const { resource, operation, attributes } = this.props;
    const childrenArray = Array.isArray(this.props.children)
      ? this.props.children
      : [this.props.children];
    const authorized = isAuthorized(resource, operation, attributes);
    if (childrenArray.length === 1) {
      return authorized ? this.props.children : "";
    } else {
      if (authorized) {
        return <>{this.filterChild(childrenArray, (<Success />).type)}</>;
      } else {
        return <>{this.filterChild(childrenArray, (<Failed />).type)}</>;
      }
    }
  }
}

export default Authorize;
