import Amplify from 'aws-amplify';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hasGlPermissions } from 'src/helpers/disbursement_summary';
import { updateAppState } from 'src/reducers/app';
import { updateInput as updateBulkApproval } from 'src/reducers/bulk_approval';
import { updateInput as updateListDisbursementInput } from 'src/reducers/disbursement_summary';
import { updateUser, UserState } from 'src/reducers/user';
import { createCATSClientInstance } from 'src/services/caspian-approval-service-client';

import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';

import { authenticateAmplify } from '@app/authentication/midwayAuthN';
import { refreshMidway } from '@app/authentication/midwayTokenRetriever';
import { retrieveAuthToken } from '@app/authorization/token_handler';
import { getGls } from '@app/components/disbursementsummary/DisbursementSummaryInputGroup';
import { AUTHENTICATION_PROVIDER } from '@app/constants/app';
import { getCurrentStageConfig, IAppStageConfig } from '@app/constants/stage';
import { TT_QUICKLINK } from '@app/constants/urls';
import { RootState, useLazyCutoffConfigQuery } from '@app/reducers';

import { getBusiness, getInitalDateRangeType } from './bulkapproval/InputGroup';

/**
 * Component to manage the application initialization.
 * Initialisation of Amplify, Authentication takes place in this phase.
 */
const AppInitWrapper: React.FunctionComponent = ({ children }) => {
    const dispatch = useDispatch();
    const stageConfig = useMemo(getCurrentStageConfig, []);
    const { errored, isInitializing } = useSelector((state: RootState) => state.app);
    const [triggerCutoffConfigQuery, { isFetching, isError }] = useLazyCutoffConfigQuery();

    const initializeAmplify = (stageConfig: IAppStageConfig) => {
        console.log(stageConfig['AMPLIFY']['COGNITO']['IDENTITY_POOL_ID']);
        console.log(stageConfig['AWS_REGION']);
        console.log(stageConfig['SERVICE_ENDPOINTS']);
        Amplify.configure({
            Auth: {
                identityPoolId: stageConfig['AMPLIFY']['COGNITO']['IDENTITY_POOL_ID'],
                region: stageConfig['AWS_REGION'],
                mandatorySignIn: true,
                refreshHandlers: { [AUTHENTICATION_PROVIDER]: refreshMidway }
            },
            API: {
                endpoints: stageConfig['SERVICE_ENDPOINTS']
            }
        });
    };

    const initializeStartupState = () => {
        dispatch(updateBulkApproval('business', getBusiness()[0]));

        dispatch(updateBulkApproval('date_range_type', getInitalDateRangeType()));

        if (hasGlPermissions()) {
            dispatch(updateListDisbursementInput('gl', getGls()[0].glNum));
        }
    };

    const authenticate = async () => {
        try {
            const user = await authenticateAmplify();
            dispatch(updateUser(user as UserState));

            await retrieveAuthToken();
            triggerCutoffConfigQuery();
            initializeStartupState();
            await initializeCATSClient();

            dispatch(updateAppState('isInitializing', false));
        } catch (err) {
            console.error(`Error during initializing the application ${err}`);
            dispatch(updateAppState('errored', true));
        }
    };

    const initializeCATSClient = async () => {
        if (stageConfig['CATS_ENDPOINT'] != '') {
            await createCATSClientInstance(stageConfig['CATS_ENDPOINT'], stageConfig['AWS_REGION']);
        }
    };

    useEffect(() => {
        initializeAmplify(stageConfig);
        authenticate();
    }, []);

    return errored || isError ? (
        <Column height='25vh' heights='fit' alignmentHorizontal='center' alignmentVertical='center'>
            <Alert type='error' size='large'>
                <Text type='b400'>
                    We have encountered an error while initializing the app. Please try refreshing
                    the page
                </Text>
                <Text type='b400'>
                    If the issue persists please cut a ticket to&nbsp;
                    <Link
                        href={TT_QUICKLINK}
                        type='secondary'
                        target='_blank'
                        rel='noopener noreferrer'>
                        Dex Team
                    </Link>
                </Text>
            </Alert>
        </Column>
    ) : isInitializing || isFetching ? (
        <Column
            height='100vh'
            heights='fit'
            alignmentHorizontal='center'
            alignmentVertical='center'>
            <Loader type='circular' />
        </Column>
    ) : (
        <>{children}</>
    );
};

export default AppInitWrapper;
