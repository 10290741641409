import Button from "@amzn/meridian/button";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/reducers";
import {
    clearSubmitStatus,
    updateBulkApprovalState, updateDownloadReportSubmitStatus,
} from "src/reducers/bulk_approval/actions";
import Text from "@amzn/meridian/text";
import { useAsync } from "react-async";
import {sendBulkApprovalReport, tSubmitDownloadBulkApprovalRequest, tSumbitRequest} from "src/apis/bulk_approval";
import Loader from "@amzn/meridian/loader";
import Alert from "@amzn/meridian/alert";
import { filterApprovalsForReportDownload} from "src/helpers/bulk_approval";
import {createKatalMetricsPublisher} from "src/helpers/katal_metrics_emitter";

function emitMetricOnDownloadBulkApprovalReportRequest(business:string,request_status:string){
    const actionMetricsPublisher = createKatalMetricsPublisher('download_bulk_approval_report_request');
    actionMetricsPublisher.publishCounterMonitor(request_status+'_'+business, 1);
}

/**
 * Component which renders a Modal on the bulk approval item's download request
 * It does a call to submitBulkApprovalReportAPI when the user clicks on Proceed
 * and surfaces the status.
 */
export const SendBulkApprovalReport: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const bulkApprovalStateSelector = (state: RootState) => state.bulk_approval;
    const bulkApprovalState = useSelector(bulkApprovalStateSelector);
    const showModal = bulkApprovalState.showDownloadBulkApprovalReportWindowConfirmation;
    const allApprovals = bulkApprovalState.approvals;
    const singleReportDownloadRequest = bulkApprovalState.singleReportDownloadRequest;
    const isSubmitted = bulkApprovalState.isSubmitted;
    const submitReportDownloadPayload = bulkApprovalState.submitReportDownloadPayload;
    const submitError = bulkApprovalState.submitError;
    const filteredDownloadDisbursementRequest: tSubmitDownloadBulkApprovalRequest = filterApprovalsForReportDownload(
        allApprovals,
        singleReportDownloadRequest,
        bulkApprovalState.inputGroup
    );

    const { isPending, run } = useAsync({
        deferFn: sendBulkApprovalReport,
        onResolve: function (data: any) {
            dispatch(updateDownloadReportSubmitStatus(data));
        },
        onReject: function () {
            dispatch(updateBulkApprovalState("submitError", true));
        },
    });

    const closeHandler = () => {
        dispatch(clearSubmitStatus());
    };

    return (
        <Modal
            title="Send Bulk Approval Report"
            open={showModal}
            scrollContainer="viewport"
            closeLabel="Close"
            aria-describedby="modal-description"
            width={976}
        >
            {isPending ? (
                <Row alignmentHorizontal="center" spacingInset="500">
                    <Loader size="large" type="circular" />
                </Row>
            ) : !isSubmitted && !submitError ? (
                <>
                    <Alert size="large" type="informational">
                        <Text type="b300">
                            The No. of Payments and the Total Amount may change if there are new disbursements in
                            the past few minutes. You will receive a mail with the latest values within 15 mins.
                        </Text>
                    </Alert>
                    <br />
                    <Text type="b500">Please click Proceed button to send mail</Text>
                    <br />
                </>
            ) : !submitError ? (
                <>
                    {emitMetricOnDownloadBulkApprovalReportRequest(bulkApprovalState.inputGroup.business
                        ,'success')}
                    <Text type="b500">
                        {submitReportDownloadPayload?.errorMessage === ''
                            ? "Success! Download Bulk Approval Payments report request submitted. " +
                            "You would be getting the report in email shortly."
                            +"Execution id : " + submitReportDownloadPayload.executionId
                            : "Error placing request. Please retry after sometime or contact DeX team"}
                    </Text>
                    <br />
                </>
            ) : (
                <>
                <Row alignmentHorizontal="center" spacingInset="500">
                    {emitMetricOnDownloadBulkApprovalReportRequest(bulkApprovalState.inputGroup.business
                        ,'failure')}
                    <Alert size="large" type="error">
                        <Text type="b500">
                            We have encountered an error while placing download disbursements request.
                            <br />
                            Please retry after sometime or contact DeX team.
                        </Text>
                    </Alert>
                </Row>
                </>
            )}
            {!isPending && (
                <ModalFooter>
                    <Row alignmentHorizontal="end" widths="fit">
                        <Button type="secondary" size="small" onClick={closeHandler}>
                            Close
                        </Button>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                run(filteredDownloadDisbursementRequest);
                            }}
                            disabled={isSubmitted}
                        >
                            {submitError ? "Retry" : "Proceed"}
                        </Button>
                    </Row>
                </ModalFooter>
            )}
        </Modal>
    );
};
