import React from 'react';

import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

export const AlertLoading: React.FunctionComponent = () => {
	return (
		<Row alignmentHorizontal='center' spacingInset='500'>
			<Column heights='fill' alignmentHorizontal='center'>
				<Alert size='large' type='informational'>
					<Text type='b400'>
						Please Wait - We are fetching approvals, and history for the month. This may take up to 10 seconds based on business volume.
					</Text>
				</Alert>
				<Loader size='large' type='circular' />
			</Column>
		</Row>
	);
};
