import {
    InputGroupKeys,
    REFRESH_SUMMARY, tDisbursementSummary, tDisbusementSummaryDirectUpdate,
    tDisbursementSummaryInputGroup,
    UPDATE_DISBURSEMENT_SUMMARY_INPUT, UPDATE_DISBURSEMENT_SUMMARY_STATE, UPDATE_SELECTED_GL_SUMMARY
} from "src/reducers/disbursement_summary/types";

export function updateInput(
    stateVariable: InputGroupKeys,
    value: any
): UPDATE_DISBURSEMENT_SUMMARY_INPUT {
    return {
        type: "@@DISBURSEMENT_SUMMARY/UPDATE_INPUT",
        payload: { [stateVariable]: value },
    };
}

export function updateInputCompletely(
    value: tDisbursementSummaryInputGroup
): UPDATE_DISBURSEMENT_SUMMARY_INPUT {
    return {
        type: "@@DISBURSEMENT_SUMMARY/UPDATE_INPUT",
        payload: value,
    };
}

export function refreshSummaries(summaries: tDisbursementSummary[]): REFRESH_SUMMARY {
    return {
        type: "@@DISBURSEMENT_SUMMARY/REFRESH_SUMMARY",
        summaries: summaries,
    };
}

export function updateDisbursementSummaryState(
    field: tDisbusementSummaryDirectUpdate,
    value: boolean
): UPDATE_DISBURSEMENT_SUMMARY_STATE {
    return {
        type: "@@DISBURSEMENT_SUMMARY/UPDATE_STATE_DIRECT",
        value: value,
        key: field,
    };
}

