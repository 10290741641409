import { addMonths, startOfMonth } from 'date-fns';
import { toInteger } from 'lodash';

import { DateRangeTypeValueEnum } from '@amzn/digital-vendor-payments-gateway-client';

//getMonth Function in typescript has values from 0-11 . i.e JAN = 0 , FEB = 1
export const getMonthNameFromMonthNumber = (monthNumber: number) => {
    switch (monthNumber) {
        case 0:
            return 'JAN';
        case 1:
            return 'FEB';
        case 2:
            return 'MAR';
        case 3:
            return 'APR';
        case 4:
            return 'MAY';
        case 5:
            return 'JUN';
        case 6:
            return 'JUL';
        case 7:
            return 'AUG';
        case 8:
            return 'SEP';
        case 9:
            return 'OCT';
        case 10:
            return 'NOV';
        case 11:
            return 'DEC';
        default:
            throw 'Incorrect month number exception';
    }
};

//getMonth Function in typescript has values from 0-11 . i.e JAN = 0 , FEB = 1
export const getMonthNumberStringFromMonthNumber = (monthName: number) => {
    switch (monthName) {
        case 0:
            return '01';
        case 1:
            return '02';
        case 2:
            return '03';
        case 3:
            return '04';
        case 4:
            return '05';
        case 5:
            return '06';
        case 6:
            return '07';
        case 7:
            return '08';
        case 8:
            return '09';
        case 9:
            return '10';
        case 10:
            return '11';
        case 11:
            return '12';
        default:
            throw 'Incorrect month number exception';
    }
};

//format yyyy-mm-dd
export const getDateFormatStringFromDate = (date: Date) => {
    return date.toISOString().split('T')[0];
};

/**
 * Returns the latest month and year available for fetching data
 */
export const getCurrentMonthAndYear = () => {
    const date = new Date();
    const [currentMonth, currentYear] = getPreviousMonthAndYear(
        date.getMonth(),
        date.getFullYear()
    );

    return { currentMonth, currentYear };
};

export const getPreviousMonthAndYear = (month: number, year: number) => {
    if (month === 0) {
        return [11, year - 1];
    }

    return [month - 1, year];
};

/**
 * For month of 12/22 - paymentStartDate should be 01-11-22 and paymentEndDate should be 01-12-22
 * For previous month of 12/22 - paymentStartDate should be 01-10-22 and paymentEndDate should be 01-11-22
 */
export const getPaymentStartDate = (month: number, year: number) =>
    toInteger(Date.UTC(year, month) / 1000);

export const getPaymentEndDate = (month: number, year: number) => {
    const date = startOfMonth(addMonths(new Date(year, month), 1));
    return toInteger(Date.UTC(date.getFullYear(), date.getMonth()) / 1000);
};

export const getPrevMonthPaymentStartDate = (month: number, year: number) => {
    const date = addMonths(Date.UTC(year, month), -1);
    return getPaymentStartDate(date.getMonth(), date.getFullYear());
};

export const getPrevMonthPaymentEndDate = (month: number, year: number) => {
    const date = addMonths(Date.UTC(year, month), -1);
    return getPaymentEndDate(date.getMonth(), date.getFullYear());
};

export const getUTCToday = () => {
    const localNow = new Date();
    return Date.UTC(localNow.getUTCFullYear(), localNow.getUTCMonth(), localNow.getUTCDate());
};

/**
 * Convert payment start/end date to EARNING_PERIOD search type for comparing with cutoff configs
 */
export const convertPaymentDateToEarningPeriodDate = (
    date: number,
    business: string,
    datePeriodType: string | undefined
): Date => {
    if (business === 'APUB' || datePeriodType === DateRangeTypeValueEnum.EARNING_PERIOD) {
        return new Date(date);
    }

    const earningDate = addMonths(date, -1);
    return new Date(earningDate.getFullYear(), earningDate.getMonth());
};
