import React from 'react';

import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

export const AlertNoPermissions: React.FunctionComponent = () => {
	return (
		<Row alignmentHorizontal='center' spacingInset='500'>
			<Column heights='fill' alignmentHorizontal='center'>
				<Alert size='large' type='informational'>
					<Text type='b500'>
						You don't have required permissions to view bulk approvals.
					</Text>
					<Text type='b500'>
						Please request access&nbsp;
						<Link
							href='https://w.amazon.com/bin/view/DART_Experience_(DeX)/DAPRv2/Permissions'
							target='_blank'>
							here
						</Link>
						.
					</Text>
				</Alert>
			</Column>
		</Row>
	);
};
