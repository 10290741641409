import { digitalVendorPaymentGateway } from "src/services/digital-payment-gateway-client";
import {
  SubmitFeedbackInput,
  SubmitFeedbackOutput,
} from "@amzn/digital-vendor-payments-gateway-client";

export const submitFeedback = ([user, rating, description]: any): Promise<
  SubmitFeedbackOutput
> => {
  let input: SubmitFeedbackInput = {
    rating,
    description,
    user,
  };
  return digitalVendorPaymentGateway.submitFeedback(input);
};
