export const PAGE_LOCATIONS = {
    HOME: '/',
    BULK_APPROVAL: '/bulk',
    DISBURSEMENTS: '/disbursements',
    DISBURSEMENT_SUMMARY: '/disbursementSummary'
};

export type tDomains = 'beta' | 'prod';

export type tRegions = 'NA' | 'EU' | 'FE' | 'CN';

export const AUTHENTICATION_PROVIDER = 'midway-auth.amazon.com';

export const ADDITIONAL_FILTERS_SUPPORTED_DISBURSEMENT_TYPES = ['APUB'];

export const APUB_DISBURSEMENT_TYPES = ['APUB'];

export const KATAL_METRIC_SERVICE_NAME = 'caspian-payments-tool';

export const DISBURSEMENTS_SUMMARY_MONTH_DROPDOWN_COUNT = 3;

export type Month = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11;

// TODO: to be changed as per PM and cutoff strategy
export const MINIMUM_SUPPORT_YEAR = 2021;

// Approval statuses shown to the customer
export enum CustomerApprovalStatus {
    PENDING = 'Pending',
    IN_PROGRESS = 'In Progress',
    APPROVED = 'Approved',
    FAILED = 'Failed'
}

export const CustomerApprovalStatusToolTip: Record<CustomerApprovalStatus, string> = {
    [CustomerApprovalStatus.PENDING]: 'Payments that are pending approval',
    [CustomerApprovalStatus.IN_PROGRESS]:
        'The system is processing payment approvals. Some payments may be available for additional approvals during this time',
    [CustomerApprovalStatus.APPROVED]:
        'Approval requests with all payments fully processed in the system',
    [CustomerApprovalStatus.FAILED]:
        'The system has encountered an error. Check back again in a few hours as the system will retry approving these payments.' +
        ' If this issue does not resolve, please cut us a ticket under Help in the menu bar'
};
