import { digitalVendorPaymentGateway } from "src/services/digital-payment-gateway-client";
import {
    ListDisbursementSummaryInput,
    ListDisbursementSummaryOutput
} from "@amzn/digital-vendor-payments-gateway-client";

export const listDisbursementSummary = async ({
                                            year,
                                            month,
                                            gl,
                                        }: any): Promise<ListDisbursementSummaryOutput> => {
    let startDateString = year+"-"+month+"-01";

    //We add 1 month to start date as a months disbursements effective date would lie in next month
    let startDate = new Date(startDateString);
    startDate.setUTCMonth(startDate.getUTCMonth() + 1);
    const startDateEpochValue = startDate.getTime()/1000;
    let endDate = startDate;
    endDate.setUTCMonth(endDate.getUTCMonth() + 1);
    const endDateEpochValue = endDate.getTime()/1000;

    const input: ListDisbursementSummaryInput = {
        gl: gl,
        paymentStartDate: startDateEpochValue,
        paymentEndDate: endDateEpochValue,
    };
    return digitalVendorPaymentGateway.listDisbursementSummary(input);
};
