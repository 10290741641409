import { AwsRum, AwsRumConfig, TargetDomEvent } from 'aws-rum-web';
import { getCurrentStageConfig } from 'src/constants/stage';

const APPLICATION_VERSION: string = '1.0.0';
const CN_REGION = 'cn-north-1'
const CN_RUM_REGION = 'ap-southeast-1';

class RumClient {
    private rumClient: AwsRum | undefined;

    init = () => {
        const { AWS_REGION, RUM_CONFIG } = getCurrentStageConfig();
        if (this.rumClient !== undefined || !RUM_CONFIG) return;

        // CN's Cloudfront distribution is in Singapore
        let awsRegion = (AWS_REGION !== CN_REGION) ? AWS_REGION : CN_RUM_REGION;

        try {
            const config: AwsRumConfig = {
                sessionSampleRate: 1,
                guestRoleArn: RUM_CONFIG.guestRoleArn,
                identityPoolId: RUM_CONFIG.identityPoolId,
                endpoint: RUM_CONFIG.endpoint,
                telemetries: [
                    'performance',
                    'errors',
                    'http',
                    [
                        'interaction',
                        {
                            enableMutationObserver: true
                        }
                    ]
                ],
                allowCookies: true,
                enableXRay: true,
                disableAutoPageView: true
            };

            this.rumClient = new AwsRum(
                RUM_CONFIG.applicationId,
                APPLICATION_VERSION,
                awsRegion,
                config
            );
        } catch (error) {
            // Ignore errors thrown during CloudWatch RUM web client initialization
        }
    };

    recordError(error: any) {
        if (!this.rumClient) return;
        this.rumClient.recordError(error);
    }

    recordPageView(pageId: string) {
        if (!this.rumClient) return;
        this.rumClient.recordPageView(pageId);
    }

    registerDomEvents(events: TargetDomEvent[]) {
        if (!this.rumClient) return;
        this.rumClient.registerDomEvents(events);
    }
}

export default new RumClient();
