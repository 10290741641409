import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

import {
	BulkApprovalSnapShot,
	DateRangeTypeValue,
	SendBulkApprovalReportInputV2
} from '@amzn/digital-vendor-payments-gateway-client';

import { MarketPlace, Month } from '@app/constants';

import { BulkApprovalFilters, BulkSnapShotApprovalState } from './types';

/**
 * Note: Can directly manipulate state object since createSlice uses Immer which tracks all the changes and
 * then uses that list of changes to return a safely immutably updated value
 * Reference: https://redux.js.org/tutorials/essentials/part-2-app-structure#reducers-and-immutable-updates
 */
export const updateAvailableBusinessesReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<string[]>
> = (state, action) => {
	state.businesses = action.payload;
	if (state.filters.business === '') {
		state.filters.business = state.businesses[0];
	}
};

export const updateAvailableMarketPlacesReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<MarketPlace[]>
> = (state, action) => {
	state.marketPlaces = action.payload;
};

export const updateMultipleFiltersReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<Partial<BulkApprovalFilters>>
> = (state, action) => {
	state.filters = { ...state.filters, ...action.payload };
};

export const updateMonthReducer: CaseReducer<BulkSnapShotApprovalState, PayloadAction<Month>> = (
	state,
	action
) => {
	state.filters.month = action.payload;
};

export const updateYearReducer: CaseReducer<BulkSnapShotApprovalState, PayloadAction<number>> = (
	state,
	action
) => {
	state.filters.year = action.payload;
};

export const updateMarketPlaceReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<MarketPlace>
> = (state, action) => {
	state.filters.marketPlace = action.payload;
};

export const updateBusinessReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<string>
> = (state, action) => {
	state.filters.business = action.payload;
};

export const updateDatePeriodTypeReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<DateRangeTypeValue>
> = (state, action) => {
	state.filters.datePeriodType = action.payload;
};

export const showSendBulkApprovalReportModalReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<SendBulkApprovalReportInputV2>
> = (state, action) => {
	state.sendBulkApprovalReport.showModal = true;
	state.sendBulkApprovalReport.input = action.payload;
};

export const hideSendBulkApprovalReportModalReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<void>
> = state => {
	state.sendBulkApprovalReport.showModal = false;
	state.sendBulkApprovalReport.input = undefined;
};

export const showPlaceBulkApprovalModalReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<BulkApprovalSnapShot[]>
> = (state, action) => {
	state.placeBulkApproval.showModal = true;
	state.placeBulkApproval.snapshots = action.payload;
};

export const hidePlaceBulkApprovalModalReducer: CaseReducer<
	BulkSnapShotApprovalState,
	PayloadAction<void>
> = state => {
	state.placeBulkApproval.showModal = false;
	state.placeBulkApproval.snapshots = [];
};
