import { FeedbackState, FeedBackActionTypes } from "./types";
import { Reducer } from "redux";
const initialState: FeedbackState = {
  isFeedbackOpen: false,
  isFeedbackSubmitted: false,
  isValid: true,
  feedbackContent: "",
  isErrored: false
};

export const FeedbackReducer: Reducer<FeedbackState> = (
  state: FeedbackState = initialState,
  action
) => {
  switch ((action as FeedBackActionTypes).type) {
    case "@@FEEDBACK/UPDATE_FEEDBACK_STATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};
