import KatalMetricsDriverSushi from "@amzn/katal-metrics-driver-sushi";
import * as KatalMetrics from "@amzn/katal-metrics";
import {getCurrentStageConfig} from "src/constants/stage";
import {KATAL_METRIC_SERVICE_NAME} from "src/constants/app";

export const createKatalMetricsPublisher = (methodName: string) => {
    const stageConfig = getCurrentStageConfig();

    const metricsErrorHandler = (err:Error) => { console.error(err) };

    const metricsDriver = new KatalMetricsDriverSushi.Builder()
        .withDomainRealm(stageConfig.STAGE, stageConfig.REALM)
        .withErrorHandler(metricsErrorHandler)
        .build();

    const initialMetricsContext = new KatalMetrics.Context.Builder()
        .withServiceName(KATAL_METRIC_SERVICE_NAME)
        .withSite(KATAL_METRIC_SERVICE_NAME)
        .build();

    const initialMetricsPublisher:KatalMetrics.Publisher = new KatalMetrics.Publisher(
        metricsDriver,
        metricsErrorHandler,
        initialMetricsContext);

    const actionMetricsPublisher:KatalMetrics.Publisher =
        initialMetricsPublisher.newChildActionPublisherForMethod(methodName);

    return actionMetricsPublisher;
};