import React, { useCallback, useEffect, useRef } from "react";
import Select, { SelectOption } from "@amzn/meridian/select";
import Row from "@amzn/meridian/row";
import { fetchUserClaims } from "src/authorization/token_handler";
import { RootState } from "src/reducers";
import { useDispatch, useSelector } from "react-redux";
import { updateInput } from "src/reducers/disbursement_summary/actions";
import { InputGroupKeys } from "src/reducers/disbursement_summary/types";
// @ts-ignore
import infoKnockoutTokens from "@amzn/meridian-tokens/base/icon/info-knockout";
import Column from "@amzn/meridian/column";
import { useHistory, useLocation } from "react-router-dom";
import {
    formQueryString, getMonthDropDownValues,
} from "src/helpers/disbursement_summary";

interface GlDropDownValue {
    glNum: string,
    glName: string,
}

export const getGls: () => GlDropDownValue[] = () => {
    let glDropDownValues :GlDropDownValue[] = [];
    fetchUserClaims()?.list_disbursements.summary_view.map(
        (viewAttribute) =>{
            glDropDownValues.push(
                {
                    glNum : viewAttribute.gl.toString(),
                    glName : viewAttribute.gn.toString()
                }
            )
        }
    )
    return glDropDownValues.sort((a, b) => a.glNum.localeCompare(b.glNum));
}

/**
 * Component which renders input elements for disbursement summary
 * and updates the input values in the state
 */
export const DisbursementSummaryInputGroup: React.FunctionComponent = () => {
    /**
     * useDispatch() is a hook provided by redux which replaces
     * the function mapDispatchToProps(),
     * for more info: https://react-redux.js.org/api/hooks
     */
    const dispatch = useDispatch();
    const disbursementSummaryInputSelector = (state: RootState) =>
        state.disbursement_summary.inputGroup;
    const disbursementSummaryInput = useSelector(disbursementSummaryInputSelector);
    const gls = getGls();

    const history = useHistory();
    const displayMonths:{dropDownLabel:string , value:string}[] = getMonthDropDownValues(new Date());

    const updateQueryParam = () => {
        const queryString = formQueryString(disbursementSummaryInput);
        history.push({
            search: queryString,
        });
    };

    useEffect(() => {
        const year = disbursementSummaryInput.year;
        const month = disbursementSummaryInput.month;
        if (year && month) {
            updateQueryParam();
        }
    }, [disbursementSummaryInput]);

    const updateDisbursementSummaryInput = (
        key: InputGroupKeys,
        value: string | (string | undefined)[]
    ) => {
        dispatch(updateInput(key, value));
    };


    return (
        <Row
            spacingInset="400"
            alignmentVertical="stretch"
            wrap="down"
            width="100%"
            widths={["grid-3", "grid-3", "grid-3"]}
        >
            <Select
                value={disbursementSummaryInput.gl}
                onChange={(val) => {
                    updateDisbursementSummaryInput("gl", val);
                }}
                placeholder="GL"
                width={300}
                label="GL"
                id="GL"
                size="xlarge">
                {gls.map((item, index) => (
                    <SelectOption key={index} value={item.glNum} label={item.glName} />
                ))}
            </Select>
            <Select
                value={disbursementSummaryInput.year + ' ' + disbursementSummaryInput.month}
                onChange={(val:string) => {
                    const yearValue:string = val.substr(0,4);
                    const monthValue:string = val.substr(5,2);
                    updateDisbursementSummaryInput("year", yearValue);
                    updateDisbursementSummaryInput("month", monthValue);
                }}
                placeholder="MONTH"
                width={300}
                label="MONTH"
                id="MONTH"
                size="xlarge">
                {displayMonths.map((item, index) => (
                    <SelectOption key={item.value} value={item.value} label={item.dropDownLabel} />
                ))}
            </Select>
        </Row>
    );
};
