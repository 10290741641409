import React, { memo } from 'react';

import refreshTokens from '@amzn/meridian-tokens/base/icon/refresh';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

import { createKatalMetricsPublisher } from '@app/helpers';

interface Props {
	business: string;
	reload: () => void;
}

const emitMetricOnPageLoad = (load_status: string, business: string) => {
	const actionMetricsPublisher = createKatalMetricsPublisher('bulk_approval_page_load');
	actionMetricsPublisher.publishCounterMonitor(load_status + '_' + business, 1);
};

const AlertError: React.FunctionComponent<Props> = ({ business, reload }) => {
	return (
		<Row alignmentHorizontal='center' spacingInset='600'>
			{emitMetricOnPageLoad('failure', business)}
			<Column heights='fit' alignmentHorizontal='center'>
                <Alert size='large' type='error'>
                    <Text type='b400'>
                        We have encountered an error while fetching the approvals. Try clicking the
                        refresh button, the page should load properly if the issue is related to
                        timeout issues on our servers after a few attempts.
                    </Text>
                </Alert>
				<Button type='icon' onClick={reload}>
					<Icon tokens={refreshTokens}>Refresh</Icon>
				</Button>
			</Column>
		</Row>
	);
};

export default memo(
	AlertError,
	(prevProps, nextProps) => prevProps.business === nextProps.business
);
