import { getTokenOrRedirect } from "src/authentication/tokenRetriever";
import { getUserClaims } from "src/apis/claims";
import { inflate } from "pako";
import {
  AuthTokenLocalStorage,
  UserClaims,
  BulkApprovalAttributes,
  UserData,
  ListDisbursementClaims,
  UnstructuredBulkApprovalClaims,
  UnstructuredBulkApprovalAttributes,
  UnstructuredMarketplaces,
  BulkApprovalClaims
} from "src/authorization/types";
import { Cache } from "aws-amplify";

export const authTokenCacheKey = "dvpg-authToken";

export const retrieveAuthToken = async (): Promise<AuthTokenLocalStorage> => {
  let storedAuthToken: AuthTokenLocalStorage = Cache.getItem(authTokenCacheKey);
  if (!storedAuthToken || shouldFetchToken(storedAuthToken)) {
    let midwayToken = await getTokenOrRedirect();
    const claimsOutput = await getUserClaims(midwayToken);
    const authToken = claimsOutput.authToken;
    if (authToken) {
      const authTokenDecompressed = decompressToken(authToken);
      const parsedToken = JSON.parse(atob(authTokenDecompressed.split(".")[1]));
      const expiryDate = parsedToken.exp * 1000;
      storedAuthToken = {
        expires_at: expiryDate,
        token: authTokenDecompressed,
        compressed: authToken,
      };
      Cache.setItem(authTokenCacheKey, storedAuthToken, {
        expires: expiryDate,
      });
    }
  }
  return storedAuthToken;
};

const shouldFetchToken = (token: AuthTokenLocalStorage): boolean => {
  return isTokenExpired(token) || isTokenDeprecated(token)
};

const isTokenExpired = (token: AuthTokenLocalStorage): boolean => {
  const expiryDateMinusOneHour = new Date(token.expires_at);
  expiryDateMinusOneHour.setHours(expiryDateMinusOneHour.getHours() - 1);

  return new Date() > expiryDateMinusOneHour;
}

// This method checks if the token is still using the previous structure.
// The new structure was introduced in the JWT refactor in https://code.amazon.com/reviews/CR-86778724
export const isTokenDeprecated = (token: AuthTokenLocalStorage): boolean => {
  const parsedToken = JSON.parse(atob(token.token.split(".")[1]));

  return parsedToken != undefined
    && parsedToken["scopes"] != undefined
    && parsedToken["scopes"]["bulk_approval"] != undefined
    && (parsedToken["scopes"]["bulk_approval"] as BulkApprovalClaims).view !== undefined
    && (parsedToken["scopes"]["bulk_approval"] as BulkApprovalClaims).view instanceof Array;
}

export const fetchUserClaims = () => {
  let storedAuthToken: AuthTokenLocalStorage = Cache.getItem(authTokenCacheKey);
  if (!storedAuthToken) {
    /**TODO:
    Once external link to internal routing is enabled window.location.origin 
    needs to be replaced with the window.locaiton.href
    **/
    window.location.replace(window.location.origin);
    return;
  }
  const parsedToken = JSON.parse(atob(storedAuthToken.token.split(".")[1]));
  const userClaims: UserClaims = reconstructUserClaims(parsedToken["scopes"]);
  return userClaims;
};

// Due to the structure of the token using to much space and making the request fail.
// A structure change was introduced in DigitalVendorPaymentsGatewayLambda https://code.amazon.com/reviews/CR-86778724
// Because of this, the token needs to be restructured to the previous way.
export const reconstructUserClaims = (
  {user, bulk_approval, list_disbursements}
  : {
    user: UserData,
    bulk_approval: UnstructuredBulkApprovalClaims,
    list_disbursements: ListDisbursementClaims
  }
): UserClaims => {
  const restructuredBulkApproval: BulkApprovalClaims = Object.keys(bulk_approval).reduce((newBulkApproval, key) => ({
      ...newBulkApproval,
      [key]: reconstructApprovalAttributes(bulk_approval[key])
    }), { "view": [], "business_approval": [], "finance_approval": []} as BulkApprovalClaims);

  return {
    "user": user,
    "bulk_approval": restructuredBulkApproval,
    "list_disbursements": list_disbursements
  };
}

export const reconstructApprovalAttributes = (attributes: UnstructuredBulkApprovalAttributes): BulkApprovalAttributes[] => {
  return Object.keys(attributes)
    .map((disbType: string): [string, UnstructuredMarketplaces] => ([disbType, attributes[disbType]]))
    .map(([disbType, marketplaceMap]: [disbType: string, marketplaceMap: UnstructuredMarketplaces]) => {

      return Object.keys(marketplaceMap)
        .map((marketplace: string): [string, string[] ] => ([marketplace, marketplaceMap[marketplace]]))
        .map(([marketplace, vendors]: [marketplace: string, vendors: string[]]): BulkApprovalAttributes[] => {

          return vendors.map((vendor: string): BulkApprovalAttributes => ({ "vp": vendor, "mp": Number(marketplace), "typ": disbType}));
        })
        .reduce((x,y) => x.concat(y), []);
    })
    .reduce((x,y) => x.concat(y), []);
}

const decompressToken = (base64CompressedToken: string): string => {
  // Decode base64 (convert ascii to binary)
  let compressedToken = atob(base64CompressedToken);
  // Convert binary string to character-number array
  let charData = compressedToken.split("").map(function (x) {
    return x.charCodeAt(0);
  });
  // Turn number array into byte-array
  let binData = new Uint8Array(charData);
  // Pako magic
  let data = inflate(binData);
  // Convert gunzipped byteArray back to ascii string:
  let decompressedToken = new TextDecoder("utf-8").decode(data);
  return decompressedToken;
};

export const getCompressedToken = () => {
  let storedAuthToken: AuthTokenLocalStorage = Cache.getItem(authTokenCacheKey);
  return storedAuthToken ? storedAuthToken.compressed : undefined;
};
