import { tDomains, tRegions } from "src/constants/app";

export type tDaprUrls = {
  home: string;
  list: string;
  bulk: string;
};

export const APPLICATION_URLS: Record<tDomains, Record<tRegions, string>> = {
  beta: {
    NA: "https://caspian-payments.beta.royalties.digital.amazon.dev",
    EU: "https://caspian-payments-eu.beta.royalties.digital.amazon.dev",
    FE: "https://caspian-payments-fe.beta.royalties.digital.amazon.dev",
    CN: "https://caspian-payments-cn.beta.royalties.digital.amazon.dev",
  },
  prod: {
    NA: "https://caspian-payments.royalties.digital.amazon.dev",
    EU: "https://caspian-payments-eu.royalties.digital.amazon.dev",
    FE: "https://caspian-payments-fe.royalties.digital.amazon.dev",
    CN: "https://caspian-payments-cn.royalties.digital.amazon.dev",
  },
};

export const DAPR_URLS: Record<tDomains, Record<tRegions, tDaprUrls>> = {
  beta: {
    NA: {
      home: "https://dapr.vipinteg.amazon.com/dvps",
      list: "https://dapr.vipinteg.amazon.com/dvps/listDisbursements.jsp",
      bulk: "https://dapr.vipinteg.amazon.com/dvps/bulkApproval.jsp?force",
    },
    EU: {
      home: "https://dapr-eu.integ.amazon.com/dvps",
      list: "https://dapr-eu.integ.amazon.com/dvps/listDisbursements.jsp",
      bulk: "https://dapr-eu.integ.amazon.com/dvps/bulkApproval.jsp?force",
    },
    FE: {
      home: "https://dapr-fe.integ.amazon.com/dvps",
      list: "https://dapr-fe.integ.amazon.com/dvps/listDisbursements.jsp",
      bulk: "https://dapr-fe.integ.amazon.com/dvps/bulkApproval.jsp?force",
    },
    CN: {
      home: "https://dapr-cn.integ.amazon.com/dvps",
      list: "https://dapr-cn.integ.amazon.com/dvps/listDisbursements.jsp",
      bulk: "https://dapr-cn.integ.amazon.com/dvps/bulkApproval.jsp?force",
    },
  },
  prod: {
    NA: {
      home: "https://dapr.amazon.com/dvps",
      list: "https://dapr.amazon.com/dvps/listDisbursements.jsp",
      bulk: "https://dapr.amazon.com/dvps/bulkApproval.jsp?force",
    },
    EU: {
      home: "https://dapr-eu.amazon.com/dvps",
      list: "https://dapr-eu.amazon.com/dvps/listDisbursements.jsp",
      bulk: "https://dapr-eu.amazon.com/dvps/bulkApproval.jsp?force",
    },
    FE: {
      home: "https://dapr-fe.amazon.com/dvps",
      list: "https://dapr-fe.amazon.com/dvps/listDisbursements.jsp",
      bulk: "https://dapr-fe.amazon.com/dvps/bulkApproval.jsp?force",
    },
    CN: {
      home: "https://dapr-cn.amazon.com/dvps",
      list: "https://dapr-cn.amazon.com/dvps/listDisbursements.jsp",
      bulk: "https://dapr-cn.amazon.com/dvps/bulkApproval.jsp?force",
    },
  },
};

export const TT_QUICKLINK =
  "https://t.corp.amazon.com/create/templates/f7dc7730-8111-4010-a1c7-ce94610305b3";

export const FEATURE_REQUEST_QUICKLINK =
  "https://issues.amazon.com/issues/create?template=2524da85-a830-40aa-a0e7-711782a7f83a";

export const SNTP_LINK =
  "https://inside.amazon.com/en/services/legal/us/spendingandtransaction/Pages/default.aspx";

export const FAQ_LINK =
  "https://w.amazon.com/bin/view/DART_Experience_(DeX)/DAPRv2/FAQ";

export const PERMISSION_LINK =
  "https://w.amazon.com/bin/view/DART_Experience_(DeX)/DAPRv2/Permissions";
