import { UPDATE_APP_STATE, AppStateKeys, AppState } from "./types";

export function updateAppState(
  stateVariable: AppStateKeys,
  value: any
): UPDATE_APP_STATE {
  return {
    type: "@@APP/UPDATE_APP_STATE",
    payload: { [stateVariable]: value },
  };
}
