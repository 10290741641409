import { Reducer } from "redux";
import {
    DisbursementSummaryActionTypes,
    DisbursementSummaryState, REFRESH_SUMMARY,
    tDisbursementSummaryInputGroup, UPDATE_DISBURSEMENT_SUMMARY_STATE, UPDATE_SELECTED_GL_SUMMARY, UPDATE_SINGLE_REQUEST
} from "src/reducers/disbursement_summary/types";
import {getMonthNumberStringFromMonthNumber} from "src/util/date_util";

export const getInitialInputState = (previousMonthDate: Date): tDisbursementSummaryInputGroup => {
    let gl = "";
    return {
        gl : gl,
        year: (previousMonthDate.getUTCMonth()==0) ? (previousMonthDate.getUTCFullYear()-1).toString() : previousMonthDate.getUTCFullYear().toString(),
        month: (previousMonthDate.getUTCMonth()==0) ? '12' :getMonthNumberStringFromMonthNumber(previousMonthDate.getUTCMonth()-1),
    };
};

const initialState: DisbursementSummaryState = {
    inputGroup: getInitialInputState(new Date()),
    summaries: [],
    fetchError: false,
};

export const DisbursementSummaryReducer: Reducer<DisbursementSummaryState> = (
    state: DisbursementSummaryState = initialState,
    action
) => {
    switch ((action as DisbursementSummaryActionTypes).type) {
        case "@@DISBURSEMENT_SUMMARY/UPDATE_INPUT":
            const updatedInput = { ...state.inputGroup, ...action.payload };
            return { ...state, inputGroup: updatedInput };
        case "@@DISBURSEMENT_SUMMARY/REFRESH_SUMMARY":
            const summariesPayload = (action as REFRESH_SUMMARY).summaries;
            return {
                ...state,
                fetchError: false,
                selectAll: false,
                summaries: [...summariesPayload],
            };
        case "@@DISBURSEMENT_SUMMARY/UPDATE_STATE_DIRECT":
            const error_payload = action as UPDATE_DISBURSEMENT_SUMMARY_STATE;
            return {
                ...state,
                [error_payload.key]: error_payload.value,
            };
        default:
            return state;
    }
};
