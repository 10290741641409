import { combineReducers } from 'redux';
import { AppReducer } from 'src/reducers/app/reducer';
import { BulkApprovalReducer } from 'src/reducers/bulk_approval/reducer';
import { DisbursementSummaryReducer } from 'src/reducers/disbursement_summary/reducer';
import { FeedbackReducer } from 'src/reducers/feedback/reducer';
import { UserReducer } from 'src/reducers/user/reducer';

import { bulkSnapshotApprovalApi, BulkSnapShotReducer } from './bulkSnapshotApproval';

export const rootReducer = combineReducers({
	app: AppReducer,
	user: UserReducer,
	feedback: FeedbackReducer,
	bulk_approval: BulkApprovalReducer,
	disbursement_summary: DisbursementSummaryReducer,
	bulkSnapshotApproval: BulkSnapShotReducer,
	[bulkSnapshotApprovalApi.reducerPath]: bulkSnapshotApprovalApi.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export * from './app';
export * from './bulkSnapshotApproval';
