import React, { useEffect } from "react";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Button from "@amzn/meridian/button";
import Text from "@amzn/meridian/text";
import Textarea from "@amzn/meridian/textarea";
import { RootState } from "src/reducers";
import { useSelector, useDispatch } from "react-redux";
import Loader from "@amzn/meridian/loader";
import { updateFeedbackState } from "src/reducers/feedback/actions";
import { smileyTypes } from "src/reducers/feedback/types";
import Alert from "@amzn/meridian/alert";
import { useAsync } from "react-async";
import { submitFeedback } from "src/apis/feedback";

type SmileyProps = {
  id: number;
  smileyType: smileyTypes;
};

type FeedbackBody = {
  isValid: boolean;
  content: string | undefined;
};

/**
 * This component renders a pop asking feedback from the user and submits
 * the feedback data to SIM via SubmitFeedback api
 */
const Feedback: React.FunctionComponent = () => {
  const feedBackSelector = (state: RootState) => state.feedback;
  const feedbackData = useSelector(feedBackSelector);
  const userSelector = (state: RootState) => state.user;
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (feedbackData.feedbackRating) {
      dispatch(updateFeedbackState("isValid", true));
    }
  }, [feedbackData.feedbackRating]);

  const setError = (value: boolean) => {
    dispatch(updateFeedbackState("isErrored", value));
  };

  const { isPending, run } = useAsync({
    deferFn: submitFeedback,
    onResolve: () => {
      dispatch(updateFeedbackState("isFeedbackSubmitted", true));
    },
    onReject: () => {
      setError(true);
    }
  });

  const closeFeedbackHandler = () => {
    dispatch(updateFeedbackState("isFeedbackOpen", false));
  };

  const submitFeedbackHandler = () => {
    dispatch(updateFeedbackState("isFeedbackSubmitted", false));
    setError(false);
    if (!feedbackData.feedbackRating) {
      dispatch(updateFeedbackState("isValid", false));
      return;
    }
    let rating: string = feedbackData.feedbackRating as string;
    rating = rating.replace("smiley-", "");
    run(user.username, rating, feedbackData.feedbackContent);
  };

  return (
    <Modal
      title="Feedback"
      open={feedbackData.isFeedbackOpen}
      onClose={closeFeedbackHandler}
      scrollContainer="viewport"
      closeLabel="Close"
      aria-describedby="modal-description"
      width={976}
    >
      <>
        {feedbackData.isFeedbackSubmitted && (
          <Alert
            size="large"
            onClose={() =>
              dispatch(updateFeedbackState("isFeedbackSubmitted", false))
            }
            type="success"
          >
            Thank you for providing your feedback.
          </Alert>
        )}
        {feedbackData.isErrored && (
          <Alert size="large" onClose={() => setError(false)} type="error">
            Error Submitting Feedback. Please try again later
          </Alert>
        )}
        {isPending ? (
          <Row alignmentHorizontal="center" spacingInset="500">
            <Loader size="large" type="circular" />
          </Row>
        ) : (
          <FeedbackBody
            isValid={feedbackData.isValid}
            content={feedbackData.feedbackContent}
          />
        )}
      </>
      {/**Rending ModalFooter inside a fragment is causing alignment problems, 
      so added this condition so that Modal Footer can be a direct child of Modal*/
      !isPending && (
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button
              type="secondary"
              size="small"
              onClick={closeFeedbackHandler}
            >
              Close
            </Button>
            <Button type="primary" size="small" onClick={submitFeedbackHandler}>
              Submit
            </Button>
          </Row>
        </ModalFooter>
      )}
    </Modal>
  );
};

const FeedbackBody = ({ isValid, content }: FeedbackBody) => {
  const dispatch = useDispatch();
  const updateContentHandler = (content: string) => {
    dispatch(updateFeedbackState("feedbackContent", content));
  };

  return <>
    <Text type="b300">
      What has your experience been like using the Caspian Payment Approval
      Tool?
    </Text>
    <Row alignmentHorizontal="start" widths="fit" spacingInset="300">
      <Smiley id={1} smileyType="smiley-Happy" />
      <Smiley id={2} smileyType="smiley-Sad" />
      {!isValid && (
        <Alert type="error" size="small">
          Please let us know how you are feeling
        </Alert>
      )}
    </Row>

    <Textarea
      value={content || ''}
      onChange={updateContentHandler}
      placeholder="Feedback is optional but we would love to know more..."
    />
  </>;
};
/**
 * This component renders individual smiley icons taking care of
 * active and hover states
 */
const Smiley: React.FunctionComponent<SmileyProps> = ({ id, smileyType }) => {
  const feedBackSelector = (state: RootState) => state.feedback.feedbackRating;
  const rating = "smiley-" + useSelector(feedBackSelector);
  const dispatch = useDispatch();

  const smileyClicked = (type: smileyTypes) => {
    const rating = type.replace("smiley-", "");
    dispatch(updateFeedbackState("feedbackRating", rating));
  };

  return (
    <div className="smiley-container">
      <div
        className={`smiley-icon ${smileyType} ${
          rating === smileyType ? "smiley-active" : ""
        }`}
      ></div>
      {/* Adding the normal state smiley only if the current smiley is not in clicked state*/}
      {rating !== smileyType && (
        <div
          className={`smiley-icon ${smileyType} smiley-icon-coloured`}
          id={`smiley-${id}`}
          onClick={() => {
            smileyClicked(smileyType);
          }}
        ></div>
      )}
    </div>
  );
};

export default Feedback;
