import React from 'react';
import { useDispatch } from 'react-redux';

import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Loader from '@amzn/meridian/loader';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

import { createKatalMetricsPublisher } from '@app/helpers';
import {
	hideSendBulkApprovalReportModal,
	useFiltersSelector,
	useSendBulkApprovalReportSelector,
	useSendBulkApprovalReportV2Mutation
} from '@app/reducers';

const emitMetricOnDownloadBulkApprovalReportRequest = (
	business: string,
	request_status: string
) => {
	const actionMetricsPublisher = createKatalMetricsPublisher(
		'download_bulk_approval_report_request'
	);
	actionMetricsPublisher.publishCounterMonitor(request_status + '_' + business, 1);
};

export const SendBulkApprovalReport: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { showModal, input } = useSendBulkApprovalReportSelector();
	const { business } = useFiltersSelector();
	const [trigger, { isError, isLoading, isSuccess, isUninitialized, data, reset }] =
		useSendBulkApprovalReportV2Mutation();

	const closeHandler = () => {
		dispatch(hideSendBulkApprovalReportModal());
		reset();
	};

	const triggerRequest = () => {
		input && trigger(input);
	};

	return (
		<Modal
			title='Download Bulk Approval Report'
			open={showModal}
			scrollContainer='viewport'
			closeLabel='Close'
			aria-describedby='modal-description'
			width={976}>
			{isLoading ? (
				<Row alignmentHorizontal='center' spacingInset='500'>
					<Loader size='large' type='circular' />
				</Row>
			) : isUninitialized ? (
				<>
					<Alert size='large' type='informational'>
                        <Text type='b300'>
                            The Number of Payments and the Total Amount may change if there are any
                            new disbursements in the past few minutes.
                        </Text>
					</Alert>
					<br />
                    <Text type='b400'>
                        After clicking proceed, you should expect an e-mail with a download link to
                        a report containing information on the individual disbursements that make up
                        the approval.
                    </Text>
                    <br />
                    <Text type='b400'>
                        This email should arrive with the latest values up to the last 15 minutes.
                    </Text>
				</>
			) : isSuccess ? (
				<>
                    {emitMetricOnDownloadBulkApprovalReportRequest(business, 'success')}
                    <Text type='b400'>
                        Success! Your Bulk Approval report request has been submitted. You will
                        receive the report in your e-mail inbox shortly from
                        caspian-payment-approval-tool-noreplay@amazon.com.
                    </Text>
                    <br />
                    <Text type='b400'>Execution id : {data?.executionId}</Text>
				</>
			) : (
				<>
                    <Row alignmentHorizontal='center' spacingInset='500'>
                        {emitMetricOnDownloadBulkApprovalReportRequest(business, 'failure')}
                        <Alert size='large' type='error'>
                            <Text type='b400'>
                                We have encountered an error while sending this bulk approval report
                                to your inbox.
                            </Text>
                            <br />
                            <Text type='b400'>
                                Please come back to retry after sometime or contact the DVDx team
                                for assistance if it continues to fail.
                            </Text>
                        </Alert>
                    </Row>
				</>
			)}
			{!isLoading && (
				<ModalFooter>
					<Row alignmentHorizontal='end' widths='fit'>
						<Button type='secondary' size='small' onClick={closeHandler}>
							Close
						</Button>
						<Button
							type='primary'
							size='small'
							onClick={triggerRequest}
							disabled={isSuccess}>
							{isError ? 'Retry' : 'Proceed'}
						</Button>
					</Row>
				</ModalFooter>
			)}
		</Modal>
	);
};
