import React from "react";
import Column from "@amzn/meridian/column";
import Box from "@amzn/meridian/box";

interface IContentProps {
  spacingInset?: any;
  backgroundColor?: string;
  className?: string;
}
class Content extends React.Component<IContentProps> {
  render() {
    const {
      children,
      spacingInset,
      backgroundColor,
      className,
      ...extraProps
    } = this.props;
    return (
      <Box
        backgroundColor={backgroundColor}
        spacingInset={spacingInset}
        className={className}
      >
        <Column spacing="none" width="100%">
          {this.props.children}
        </Column>
      </Box>
    );
  }
}

export default Content;
