import { css } from 'emotion';
import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { RumMonitor } from 'src/components/monitoring/RumMonitor';
import AppLayout from '@amzn/meridian/app-layout';

import DisbursementSummary from '@app/components/disbursementsummary/DisbursementSummary';
import Home from '@app/components/home/Home';
import Header from '@app/components/layouts/Header';
import { PAGE_LOCATIONS } from '@app/constants/app';
import { getCurrentStageConfig, IAppStageConfig } from '@app/constants/stage';

import { BulkApprovalExperience } from './BulkApprovalExperience';
import { WithQueryParam } from './WithQueryParam';

export class App extends React.Component<{}, { showBanner: boolean }> {
	private stageConfig: IAppStageConfig;
	constructor(props: {}) {
		super(props);
		this.stageConfig = getCurrentStageConfig();
	}

	style = () =>
		css({
			backgroundColor: '#B9E8F7',
			borderBottom: `1px solid #ECEDED`
		});

	render() {
		return (
			<BrowserRouter>
				<AppLayout headerComponent={Header} backgroundColor='alternatePrimary'>
					<Header />
                    <RumMonitor>
					<WithQueryParam>
						<Switch>
							<Route exact path='/'>
								<Home />
							</Route>
							<Route path={PAGE_LOCATIONS.BULK_APPROVAL}>
								<BulkApprovalExperience />
							</Route>
							<Route path={PAGE_LOCATIONS.DISBURSEMENT_SUMMARY}>
								<DisbursementSummary />
							</Route>
						</Switch>
					</WithQueryParam>
                    </RumMonitor>
				</AppLayout>
			</BrowserRouter>
		);
	}
}

export default App;
