export function formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
        str = str.replace(`{${index}}`, val[index]);
    }
    return str;
}

export function formatGraphXAxisLabels(input:string) {
    if(input.length == 0)
        return '';
    let words: string[] = [];
    let currentWord = '';
    for(let i:number = 0 ;i < input.length ; i++){
        if(input[i]=='('){
            words.push(currentWord);
            currentWord='(';
        }else if(input[i] == ')'){
            currentWord += ')';
            words.push(currentWord);
            currentWord = '';
        }else {
            currentWord += input[i];
        }
    }
    if(currentWord.length != 0){
        words.push(currentWord);
    }
    let formattedGraphLabel = words[0];
    for(let i:number = 1 ; i< words.length ; i++){
        formattedGraphLabel += '\n' + words[i] ;
    }
    return formattedGraphLabel;
}