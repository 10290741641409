import { find, isInteger, toNumber } from 'lodash';

import { MINIMUM_SUPPORT_YEAR } from '@app/constants';
import { getAvailableBusinesses, getAvailableMarketPlaces } from '@app/helpers';
import { getCurrentMonthAndYear } from '@app/util/date_util';

export const isValidMonthAndYear = (monthInput: string, yearInput: string) => {
    const month = toNumber(monthInput) - 1;
    const year = toNumber(yearInput);
    const { currentMonth, currentYear } = getCurrentMonthAndYear();

    if (!isInteger(month) || month < 0 || month > 11) {
        return false;
    }

    if (!isInteger(year) || year < MINIMUM_SUPPORT_YEAR) {
        return false;
    }

    return year < currentYear || (year === currentYear && month <= currentMonth);
};

export const isValidBusiness = (business: string) => {
    return getAvailableBusinesses().includes(business);
};

export const isValidMarketplace = (marketplaceIdInput: string) => {
    const marketplaceId = toNumber(marketplaceIdInput);
    return (
        marketplaceId === 0 || find(getAvailableMarketPlaces(), ({ id }) => id === marketplaceId)
    );
};
