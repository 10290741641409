import { find, map, omit } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { formatDateAsString } from 'src/helpers';
import { getPaymentEndDate, getPaymentStartDate } from 'src/util/date_util';

import {
	BulkApprovalRequestV2,
	BulkApprovalSnapShot
} from '@amzn/digital-vendor-payments-gateway-client';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Link from '@amzn/meridian/link';
import Loader from '@amzn/meridian/loader';
import Modal, { ModalFooter } from '@amzn/meridian/modal';
import Row from '@amzn/meridian/row';
import Table from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';

import { getCurrentStageConfig } from '@app/constants/stage';
import {
	bulkSnapshotApprovalApi,
	hidePlaceBulkApprovalModal,
	useFiltersSelector,
	usePlaceBulkApprovalSelector,
	usePlaceBulkApprovalV2Mutation
} from '@app/reducers';

import { ConfirmationHeader } from './ConfirmationHeader';
import ConfirmationRow from './ConfirmationRow';
import { StatusHeader } from './StatusHeader';
import StatusRow from './StatusRow';

export const SubmitBulkApproval: React.FunctionComponent = () => {
	const dispatch = useDispatch();
	const { showModal, snapshots } = usePlaceBulkApprovalSelector();
	const { month, year } = useFiltersSelector();
	const [trigger, { isError, isLoading, isSuccess, isUninitialized, data, reset }] =
		usePlaceBulkApprovalV2Mutation();

	const isAnyApprovalFailed = find(
		data?.bulkApprovalResponses,
		response => response.status === 'failed'
	);
	const { DAPR_ENDPOINT } = getCurrentStageConfig();

	const closeHandler = () => {
		dispatch(hidePlaceBulkApprovalModal());
		reset();

		if (isSuccess) {
			dispatch(
				bulkSnapshotApprovalApi.util.invalidateTags([
					{ type: 'ListBulkApprovalV2', id: 'LIST' },
					{ type: 'ListBulkApprovalHistoryV2', id: 'LIST' }
				])
			);
		}
	};

	const triggerRequest = () => {
		trigger({
			bulkApprovalRequests: map<BulkApprovalSnapShot, BulkApprovalRequestV2>(
				snapshots,
				snapshot => ({
					...omit(snapshot, [
						'snapShotId',
						'marketplaceId',
						'approvalPhaseInfo',
						'status',
						'snapShotApprovalType'
					]),
					snapshotId: snapshot.snapShotId,
					snapshotApprovalType: snapshot.snapShotApprovalType,
					approvalPhase:
						snapshot.snapShotApprovalType === 'VM_ONLY' ? 'business' : 'finance',
					marketplaceId: Number(snapshot.marketplaceId),
					paymentStartDate: formatDateAsString(getPaymentStartDate(month, year)),
					paymentEndDate: formatDateAsString(getPaymentEndDate(month, year))
				})
			)
		});
	};

	return (
		<Modal
			title='Submit Bulk Approval'
			open={showModal}
			scrollContainer='viewport'
			closeLabel='Close'
			aria-describedby='modal-description'
			width={976}>
			{isLoading ? (
				<Row alignmentHorizontal='center' spacingInset='500'>
					<Loader size='large' type='circular' />
				</Row>
			) : isUninitialized ? (
				<>
					<Alert size='large' type='informational'>
						<Text type='b300'>
							If you want to cancel, adjust or hold disbursements - please do so
							from&nbsp;
							<Link href={DAPR_ENDPOINT} target='_blank'>
								DAPR
							</Link>
							&nbsp;before clicking on submit here
						</Text>
					</Alert>
					<br />
                    <Text type='b400'>
                        Clicking on submit will begin processing the approval. These disbursements may
                        require additional approvals, and the status of the disbursement could
                        change. Please check the approval summary for the status of the approvals.
                    </Text>
					<br />
					<Table
						headerRows={1}
						showDividers={true}
						showStripes={true}
						spacing='small'
						rowComponents={[ConfirmationHeader, ConfirmationRow]}>
						<ConfirmationHeader />
						{map(snapshots, snapshot => (
							<ConfirmationRow key={snapshot.snapShotId} snapshot={snapshot} />
						))}
					</Table>
				</>
			) : isSuccess ? (
				<>
                    <Text type='b400'>
                        {isAnyApprovalFailed
                            ? 'Error placing approvals. Please retry the approvals which have failed after a few minutes'
                            : 'Success! The approval request has been submitted. The disbursements will be approved in our systems within 12 hours'}
                    </Text>
					<br />
					<Table
						headerRows={1}
						showDividers={true}
						showStripes={true}
						spacing='small'
						rowComponents={[StatusHeader, StatusRow]}>
						<StatusHeader />
						{map(data?.bulkApprovalResponses, response => {
							const snapshot = find(
								snapshots,
								({ snapShotId }) => snapShotId === response.snapshotId
							)!!;
							return (
								<StatusRow
									key={snapshot.snapShotId}
									response={response}
									snapshot={snapshot}
								/>
							);
						})}
					</Table>
				</>
			) : (
				<Row alignmentHorizontal='center' spacingInset='500'>
					<Alert size='large' type='error'>
                        <Text type='b400'>We have encountered an error while approving.</Text>
                        <br />
                        <Text type='b400'>
                            Please retry the approvals which have failed after a few minutes or
                            contact the DVDx team for assistance if it continues to fail.
                        </Text>
					</Alert>
				</Row>
			)}
			{!isLoading && (
				<ModalFooter>
					<Row alignmentHorizontal='end' widths='fit'>
						<Button type='secondary' size='small' onClick={closeHandler}>
							Close
						</Button>
						<Button
							type='primary'
							size='small'
							onClick={triggerRequest}
							disabled={isSuccess}>
							{isError ? 'Retry' : 'Submit'}
						</Button>
					</Row>
				</ModalFooter>
			)}
		</Modal>
	);
};
