import { times } from 'lodash';
import React, { memo } from 'react';

import Select, { SelectOption } from '@amzn/meridian/select';

import { MINIMUM_SUPPORT_YEAR } from '@app/constants';
import { getCurrentMonthAndYear } from '@app/util/date_util';

interface Props {
    fromYear?: number;
    toYear?: number;
    selectedYear?: number;
    // eslint-disable-next-line no-unused-vars
    onUpdateYear: (year: number) => void;
}

const { currentYear } = getCurrentMonthAndYear();

const YearSelector: React.FunctionComponent<Props> = ({
    fromYear = MINIMUM_SUPPORT_YEAR,
    toYear = currentYear,
    selectedYear = currentYear,
    onUpdateYear
}) => {
    return (
        <Select
            value={selectedYear}
            onChange={onUpdateYear}
            label='Year'
            placeholder='Select Year'
            size='xlarge'>
            {/* Render all year options from fromYear to toYear (both inclusive) */}
            {times(toYear - fromYear + 1, diff => {
                const year = fromYear + diff;
                return <SelectOption key={year} value={year} label={year} />;
            })}
        </Select>
    );
};

export default memo(
    YearSelector,
    (prevProps, nextProps) => prevProps.selectedYear === nextProps.selectedYear
);
