import { differenceInDays, startOfDay } from 'date-fns';
import { chain, filter, isEmpty, sumBy } from 'lodash';

import {
    ApprovalPhaseInfo,
    approvalTypes,
    BulkApprovalHistorySnapShot,
    BulkApprovalSnapShot,
    ListBulkApprovalHistoryInputV2,
    ListBulkApprovalInputV2,
    SnapShotAmountSummary,
    SnapShotApprovalTypes,
    SnapShotProcessStatus
} from '@amzn/digital-vendor-payments-gateway-client';
import { TagType } from '@amzn/meridian/tag/tag';

import { fetchUserClaims } from '@app/authorization/token_handler';
import {
    ALL_MARKETPLACE,
    CustomerApprovalStatus,
    MarketPlace,
    marketplaceToCC
} from '@app/constants';
import { ApprovalsSummary, BulkApprovalFilters } from '@app/reducers';

export const getAvailableBusinesses = (): string[] => {
    return chain(fetchUserClaims()?.bulk_approval.view)
        .uniqBy(({ vp }) => vp)
        .map(({ vp }) => vp)
        .sort()
        .value();
};

export const getAvailableMarketPlaces = (): MarketPlace[] => {
    // TODO:  Pull mapping details from Compendium instead of `marketplaceToCC`
    return chain(fetchUserClaims()?.bulk_approval.view)
        .uniqBy(({ mp }) => mp)
        .map(({ mp }) => ({ id: mp, name: marketplaceToCC[mp] }))
        .sortBy(({ name }) => name)
        .value();
};

export const getAvailableMarketPlacesForBusiness = (business: string): MarketPlace[] => {
    return chain(fetchUserClaims()?.bulk_approval.view)
        .filter(({ vp }) => vp === business)
        .uniqBy(({ mp }) => mp)
        .map(({ mp }) => ({ id: mp, name: marketplaceToCC[mp] }))
        .value();
};

export const getMarketPlaceFromId = (id: number): MarketPlace => {
    if (id === 0) {
        return ALL_MARKETPLACE;
    }
    return { id, name: marketplaceToCC[id] };
};

export const showEarlyTag = (input: approvalTypes): boolean => {
    return input === 'early';
};

export const getApprovalName = (input: SnapShotApprovalTypes): string => {
    return input === 'VM_ONLY' ? 'Business' : 'Finance';
};

export const isApproveEnabled = (
    snapShotApprovalType: SnapShotApprovalTypes,
    businessApprovalAllowed: boolean,
    financeApprovalAllowed: boolean
): boolean => {
    return (
        (snapShotApprovalType === 'VM_ONLY' &&
            businessApprovalAllowed &&
            !financeApprovalAllowed) ||
        (snapShotApprovalType === 'FM_ONLY' && financeApprovalAllowed && !businessApprovalAllowed)
    );
};

export const currencyFormatHelper = (amount: number, currencyCode: string): string => {
    return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: currencyCode,
        maximumFractionDigits: 2,
        currencyDisplay: 'code'
    });
};

export const dueDateTagHelper = (approvalPhaseInfo: ApprovalPhaseInfo) => {
    const dueDate = approvalPhaseInfo.approvalDueDate;
    const startOfToday = startOfDay(new Date());
    const diff = differenceInDays(convertUTCtoLocal(dueDate * 1000), startOfToday);

    let status: TagType;
    let tag: string;

    if (diff > 0) {
        status = 'success';
        tag = 'Due Soon';
    } else if (diff == 0) {
        status = 'warning';
        tag = 'Due Today';
    } else {
        status = 'error';
        tag = 'Past Due';
    }

    return { status, text: tag };
};

const convertUTCtoLocal = (date: number) => {
    const localOffset = new Date().getTimezoneOffset() * 60000;
    return new Date(date + localOffset);
};

export const filterSnapshotsByType = (
    snapshots: BulkApprovalSnapShot[],
    approvalType: approvalTypes
) => {
    return filter(snapshots, snapshot => snapshot.approvalType === approvalType);
};

export const filterHistorySnapshotsByType = (
    snapshots: BulkApprovalHistorySnapShot[],
    approvalType: approvalTypes
) => {
    return filter(
        snapshots,
        ({ bulkApprovalSnapshot }) => bulkApprovalSnapshot.approvalType === approvalType
    );
};

export const filterSnapshotsByPhase = (
    snapshots: BulkApprovalSnapShot[],
    snapShotApprovalType: SnapShotApprovalTypes
) => {
    return filter(snapshots, snapshot => snapshot.snapShotApprovalType === snapShotApprovalType);
};

export const filterHistorySnapshotsByPhase = (
    snapshots: BulkApprovalHistorySnapShot[],
    snapShotApprovalType: SnapShotApprovalTypes
) => {
    return filter(
        snapshots,
        ({ bulkApprovalSnapshot }) =>
            bulkApprovalSnapshot.snapShotApprovalType === snapShotApprovalType
    );
};

export const filterSnapshotsByMarketplace = (
    snapshots: BulkApprovalSnapShot[],
    marketplaceId: string
) => {
    return filter(snapshots, snapshot => snapshot.marketplaceId === marketplaceId);
};

export const filterHistorySnapshotsByMarketplace = (
    snapshots: BulkApprovalHistorySnapShot[],
    marketplaceId: string
) => {
    return filter(
        snapshots,
        ({ bulkApprovalSnapshot }) => bulkApprovalSnapshot.marketplaceId === marketplaceId
    );
};

export const computeTotalAmountSummary = (
    snapshots: BulkApprovalSnapShot[]
): ApprovalsSummary['amountSummary'] => {
    return chain(snapshots)
        .flatMap(({ amountSummary }) => amountSummary)
        .groupBy(({ currency }) => currency)
        .mapValues(
            (amountSummary, currency): SnapShotAmountSummary => ({
                currency,
                amount: sumBy(amountSummary, ({ amount }) => amount),
                count: sumBy(amountSummary, ({ count }) => count)
            })
        )
        .value();
};

export const groupTotalAmountsByCustomerApprovalStatus = (
    snapshots: BulkApprovalHistorySnapShot[]
) => {
    return chain(snapshots)
        .groupBy(({ bulkApprovalStatus }) =>
            getCustomerApprovalStatus(bulkApprovalStatus?.approvalProcessStatus)
        )
        .mapValues(snapshot =>
            computeTotalAmountSummary(
                snapshot.map(({ bulkApprovalSnapshot }) => bulkApprovalSnapshot)
            )
        )
        .value();
};

export const formQueryUrl = (filters: BulkApprovalFilters) => {
    const { month, year, business, marketPlace, datePeriodType } = filters;
    return (
        `?month=${month + 1}&year=${year}&business=${business}&` +
        `marketplace=${marketPlace.id}&datePeriodType=${datePeriodType}`
    );
};

export type QueryStateParams = (
    | { query: 'listBulkApprovalV2'; input: ListBulkApprovalInputV2 }
    | { query: 'listBulkApprovalHistoryV2'; input: ListBulkApprovalHistoryInputV2 }
) & { getState: () => unknown };

export const getQueryDataFromState = ({ query, input, getState }: QueryStateParams) => {
    const prevData = (getState() as any)?.['bulkSnapshotApprovalApi']?.['queries']?.[
        `${query}(${JSON.stringify(input, Object.keys(input).sort())})`
    ];

    return (
        prevData && prevData.status === 'fulfilled' && !isEmpty(prevData.data) && prevData.data[0]
    );
};

export const getCustomerApprovalStatus = (approvalProcessStatus?: SnapShotProcessStatus) => {
    switch (approvalProcessStatus?.approvalStatus ?? 'UNKNOWN') {
        case 'SUCCEEDED':
            return CustomerApprovalStatus.APPROVED;
        case 'UNKNOWN':
            return CustomerApprovalStatus.FAILED;
        case 'FAILED':
            if (approvalProcessStatus?.isRetryable === undefined || approvalProcessStatus?.isRetryable === false) {
                return CustomerApprovalStatus.FAILED;
            }
            return CustomerApprovalStatus.IN_PROGRESS;
        default:
            return CustomerApprovalStatus.IN_PROGRESS;
    }
};
