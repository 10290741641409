import { digitalVendorPaymentGateway } from "src/services/digital-payment-gateway-client";
import {
  GetUserClaimsInput,
  GetUserClaimsOutput
} from "@amzn/digital-vendor-payments-gateway-client";

export const getUserClaims = (
  midwayToken: string
): Promise<GetUserClaimsOutput> => {
  let input: GetUserClaimsInput = {
    midwayToken
  };
  return digitalVendorPaymentGateway.getUserClaims(input);
};
