import { isEmpty, map } from 'lodash';
import React, { memo } from 'react';
import { CustomerApprovalStatus, CustomerApprovalStatusToolTip } from 'src/constants';

import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Link from '@amzn/meridian/link';
import Row from '@amzn/meridian/row';
import { TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';

import { currencyFormatHelper, getMarketPlaceFromId } from '@app/helpers';
import { ApprovalsSummary } from '@app/reducers';

import { BusinessCell, DueDateCell, EarningsPeriodCell } from '../cells';

interface Props {
    summary: ApprovalsSummary;
    onDownload: () => void;
}

const ApprovalsSummaryTableRow: React.FunctionComponent<Props> = ({ summary, onDownload }) => {
    const { disbursementType, glProductGroup, marketplaceId, paymentStartDate, paymentEndDate } =
        summary;
    const { approvalType, approvalPhaseInfo, business, finance } = summary;

    const renderStatusSummary = (
        status: CustomerApprovalStatus,
        amountSummary: ApprovalsSummary['amountSummary']
    ) => {
        if (isEmpty(amountSummary)) {
            return null;
        }

        return (
            <Box type='outline' backgroundColor='transparent' spacingInset='300'>
                <Row widths={['fit', 'fill']}>
                    <Tooltip position='end' title={CustomerApprovalStatusToolTip[status]}>
                        <Link type='secondary'>
                            <Text type='h100'>{status}</Text>
                        </Link>
                    </Tooltip>
                    <Column alignmentHorizontal='start' spacing='none'>
                        {map(amountSummary, ({ currency, count, amount }) => (
                            <Text alignment='start' key={currency}>
                                {`${currencyFormatHelper(amount, currency)} (${count} payments)`}
                            </Text>
                        ))}
                    </Column>
                </Row>
            </Box>
        );
    };

    const isBusinessPhaseEmpty =
        isEmpty(business.pending) &&
        isEmpty(business.inprogress) &&
        isEmpty(business.approved) &&
        isEmpty(business.failed);
    const isFinancePhaseEmpty =
        isEmpty(finance.pending) &&
        isEmpty(finance.inprogress) &&
        isEmpty(finance.approved) &&
        isEmpty(finance.failed);

    return (
        <TableRow highlightOnHover={true}>
            <BusinessCell disbursementType={disbursementType} approvalType={approvalType} />
            <TableCell alignmentHorizontal='center'>{glProductGroup}</TableCell>
            <TableCell alignmentHorizontal='center'>
                {getMarketPlaceFromId(Number(marketplaceId)).name}
            </TableCell>
            <EarningsPeriodCell
                paymentStartDate={paymentStartDate}
                paymentEndDate={paymentEndDate}
            />
            <DueDateCell approvalPhaseInfo={approvalPhaseInfo} />
            <TableCell alignmentHorizontal='center'>
                {isBusinessPhaseEmpty ? (
                    <span>N/A</span>
                ) : (
                    <>
                        {renderStatusSummary(CustomerApprovalStatus.PENDING, business.pending)}
                        {renderStatusSummary(
                            CustomerApprovalStatus.IN_PROGRESS,
                            business.inprogress
                        )}
                        {renderStatusSummary(CustomerApprovalStatus.APPROVED, business.approved)}
                        {renderStatusSummary(CustomerApprovalStatus.FAILED, business.failed)}
                    </>
                )}
            </TableCell>
            <TableCell alignmentHorizontal='center'>
                {isFinancePhaseEmpty ? (
                    <span>N/A</span>
                ) : (
                    <>
                        {renderStatusSummary(CustomerApprovalStatus.PENDING, finance.pending)}
                        {renderStatusSummary(
                            CustomerApprovalStatus.IN_PROGRESS,
                            finance.inprogress
                        )}
                        {renderStatusSummary(CustomerApprovalStatus.APPROVED, finance.approved)}
                        {renderStatusSummary(CustomerApprovalStatus.FAILED, finance.failed)}
                    </>
                )}
            </TableCell>
            <TableCell alignmentHorizontal='center'>
                <Button size='small' onClick={onDownload}>
                    Email
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default memo(
    ApprovalsSummaryTableRow,
    (prevProps, nextProps) => prevProps.summary === nextProps.summary
);
