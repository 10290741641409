import Button from "@amzn/meridian/button";
import Modal, { ModalFooter } from "@amzn/meridian/modal";
import Row from "@amzn/meridian/row";
import Table, { TableRow, TableCell } from "@amzn/meridian/table";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  filterApprovalsForConfirmation,
  formatDateAsString,
  summaryBuilder,
} from "src/helpers/bulk_approval";
import { RootState } from "src/reducers";
import {
  clearSubmitStatus,
  updateBulkApprovalState,
  updateInput,
  updateSubmitStatus,
} from "src/reducers/bulk_approval/actions";
import Text from "@amzn/meridian/text";
import { useAsync } from "react-async";
import { submitBulkApprovals } from "src/apis/bulk_approval";
import Loader from "@amzn/meridian/loader";
import Tag from "@amzn/meridian/tag";
import Alert from "@amzn/meridian/alert";
import { BulkApprovalInfo } from "./BulkApprovalInfo";

/**
 * Component which renders a Modal containing the approval lines which
 * are selected by the user.
 * It does a call to PlaceBulkApprovalAPI when the user clicks on submit
 * and surfaces the status.
 */
export const SubmitBulkApproval: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const bulkApprovalStateSelector = (state: RootState) => state.bulk_approval;
  const bulkApprovalState = useSelector(bulkApprovalStateSelector);
  const showModal = bulkApprovalState.showConfirmation;
  const singleRequest = bulkApprovalState.singleRequest;
  const allApprovals = bulkApprovalState.approvals;
  const isSubmitted = bulkApprovalState.isSubmitted;
  const submitPayload = bulkApprovalState.submitPayload;
  const submitError = bulkApprovalState.submitError;
  const filteredApprovals = filterApprovalsForConfirmation(
    allApprovals,
    singleRequest
  );

  const ConfirmationHeader = (
    <TableRow>
      <TableCell alignmentHorizontal="center">Business</TableCell>
      <TableCell alignmentHorizontal="center">GL</TableCell>
      <TableCell alignmentHorizontal="center">Marketplace</TableCell>
      <TableCell alignmentHorizontal="center">Earnings Period</TableCell>
      <TableCell alignmentHorizontal="center">Total Amount</TableCell>
      <TableCell alignmentHorizontal="center">No.of Payments</TableCell>
    </TableRow>
  );

  const StatusHeader = (
    <TableRow>
      <TableCell alignmentHorizontal="center">Business</TableCell>
      <TableCell alignmentHorizontal="center">GL</TableCell>
      <TableCell alignmentHorizontal="center">Marketplace</TableCell>
      <TableCell alignmentHorizontal="center">Earnings Period</TableCell>
      <TableCell alignmentHorizontal="center">Status</TableCell>
    </TableRow>
  );

  const ConfirmationLines = filteredApprovals.map((approval) => {
    const payload = approval.payload.payload;
    const summary = summaryBuilder(payload);
    return (
      <TableRow key={approval.payload.id}>
        <BulkApprovalInfo summary={summary} approvalPayload={payload} />
      </TableRow>
    );
  });

  const StatusLines = submitPayload?.bulkApprovalResponseList?.map(
    (approval, id) => {
      return (
        <TableRow key={id}>
          <TableCell alignmentHorizontal="center">
            {approval.vendorProgram}
            <br />
            {approval.approvalType === "early" ? <Tag>Early</Tag> : null}{" "}
          </TableCell>
          <TableCell alignmentHorizontal="center">
            {approval.glProductGroup}
          </TableCell>
          <TableCell alignmentHorizontal="center">
            {approval.marketplaceId}
          </TableCell>
          <TableCell alignmentHorizontal="center">
            {formatDateAsString(approval.paymentStartDate)}
            <br />
            to
            <br />
            {formatDateAsString(approval.paymentEndDate)}
          </TableCell>
          <TableCell alignmentHorizontal="center">
            {approval.errorMessage ? (
              <Tag type="error">Failed</Tag>
            ) : (
              <Tag type="success">Success</Tag>
            )}
          </TableCell>
        </TableRow>
      );
    }
  );

  const { isPending, run } = useAsync({
    deferFn: submitBulkApprovals,
    onResolve: function (data) {
      dispatch(updateSubmitStatus(data));
    },
    onReject: function () {
      dispatch(updateBulkApprovalState("submitError", true));
    },
  });

  const closeHandler = () => {
    dispatch(clearSubmitStatus());
    if (isSubmitted) {
      dispatch(updateInput("business", bulkApprovalState.inputGroup.business));
    }
  };

  return (
    <Modal
      title="Submit Bulk Approval"
      open={showModal}
      scrollContainer="viewport"
      closeLabel="Close"
      aria-describedby="modal-description"
      width={976}
    >
      {isPending ? (
        <Row alignmentHorizontal="center" spacingInset="500">
          <Loader size="large" type="circular" />
        </Row>
      ) : !isSubmitted && !submitError ? (
        <>
          <Alert size="large" type="informational">
            <Text type="b300">
              The No.of Payments and the Total Amount shown are the current
              values. Placing an approval will approve these disbursements, as
              well as any other disbursements which are processed after you
              approve for the selected time period.
            </Text>
          </Alert>
          <br />
          <Text type="b500"> Are you sure want to approve ?</Text>
          <br />
          <Table
            headerRows={1}
            showDividers={true}
            showStripes={true}
            spacing="small"
          >
            {ConfirmationHeader}
            {ConfirmationLines}
          </Table>{" "}
        </>
      ) : !submitError ? (
        <>
          <Text type="b500">
            {submitPayload?.status === "SUCCESS"
              ? "Success! Approvals Submitted. The disbursements will be approved within 12 hours"
              : "Error placing approvals. Please retry the approvals which has failed"}
          </Text>
          <br />
          <Table
            headerRows={1}
            showDividers={true}
            showStripes={true}
            spacing="small"
          >
            {StatusHeader}
            {StatusLines}
          </Table>
        </>
      ) : (
        <Row alignmentHorizontal="center" spacingInset="500">
          <Alert size="large" type="error">
            <Text type="b500">
              We have encountered an error while approving.
              <br />
              Please retry.
            </Text>
          </Alert>
        </Row>
      )}
      {!isPending && (
        <ModalFooter>
          <Row alignmentHorizontal="end" widths="fit">
            <Button type="secondary" size="small" onClick={closeHandler}>
              Close
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                run(filteredApprovals);
              }}
              disabled={isSubmitted}
            >
              {submitError ? "Retry" : "Submit"}
            </Button>
          </Row>
        </ModalFooter>
      )}
    </Modal>
  );
};
