import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { headerRumDomEvents } from 'src/constants/rumDomConstants';
import RumClient from 'src/services/rum_client';
import RumErrorBoundary from 'src/components/monitoring/RumErrorBoundary';

type tProps = {
    children: JSX.Element;
};

export const RumMonitor: React.FunctionComponent<tProps> = ({ children }) => {
    RumClient.init();

    useEffect(() => {
        RumClient.registerDomEvents(headerRumDomEvents);
    });

    const location = useLocation();
    useEffect(() => {
        RumClient.recordPageView(location.pathname);
    }, [location]);

    return <RumErrorBoundary>{children}</RumErrorBoundary>;
};
