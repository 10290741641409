import {
	PlaceBulkApprovalOutput,
	SendBulkApprovalReportOutput
} from '@amzn/digital-vendor-payments-gateway-client';

import {
	CLEAR_SUBMIT_STATUS,
	InputGroupKeys,
	REFRESH_APPROVALS,
	tApproval,
	tApprovalPhases,
	tBulkApprovalDirectUpdate,
	tInputGroup,
	UPDATE_BULKAPPROVAL_INPUT,
	UPDATE_BULKAPPROVAL_STATE,
	UPDATE_BULK_APPROVAL_DOWNLOAD_REPORT_REQUEST,
	UPDATE_DOWNLOAD_REPORT_SUBMIT_STATUS,
	UPDATE_SELECTED_APPROVALS,
	UPDATE_SINGLE_REQUEST,
	UPDATE_SUBMIT_STATUS
} from './types';

export function updateInput(stateVariable: InputGroupKeys, value: any): UPDATE_BULKAPPROVAL_INPUT {
	return {
		type: '@@BULK_APPROVAL/UPDATE_INPUT',
		payload: { [stateVariable]: value }
	};
}

export function updateInputMutiple(value: Partial<tInputGroup>): UPDATE_BULKAPPROVAL_INPUT {
	return {
		type: '@@BULK_APPROVAL/UPDATE_INPUT',
		payload: value
	};
}

export function updateInputCompletely(value: tInputGroup): UPDATE_BULKAPPROVAL_INPUT {
	return {
		type: '@@BULK_APPROVAL/UPDATE_INPUT',
		payload: value
	};
}

export function refreshApprovals(approvals: tApproval[]): REFRESH_APPROVALS {
	return {
		type: '@@BULK_APPROVAL/REFRESH_APPROVALS',
		approvals: approvals
	};
}

export function updateSelectedApprovals(ids: string[], value: boolean): UPDATE_SELECTED_APPROVALS {
	return {
		type: '@@BULK_APPROVAL/UPDATE_SELECTED_APPROVALS',
		ids: ids,
		value: value
	};
}

export function updateBulkApprovalState(
	field: tBulkApprovalDirectUpdate,
	value: boolean
): UPDATE_BULKAPPROVAL_STATE {
	return {
		type: '@@BULK_APPROVAL/UPDATE_STATE_DIRECT',
		value: value,
		key: field
	};
}

export function updateSingleApprovalRequest(
	id: string,
	phase: tApprovalPhases
): UPDATE_SINGLE_REQUEST {
	return {
		type: '@@BULK_APPROVAL/UPDATE_SINGLE_REQUEST',
		id,
		phase
	};
}

export function updateBulkApprovalReportDownloadRequest(
	id: string
): UPDATE_BULK_APPROVAL_DOWNLOAD_REPORT_REQUEST {
	return {
		type: '@@BULK_APPROVAL/UPDATE_BULK_APPROVAL_DOWNLOAD_REPORT_REQUEST',
		id
	};
}

export function clearSubmitStatus(): CLEAR_SUBMIT_STATUS {
	return {
		type: '@@BULK_APPROVAL/CLEAR_SUBMIT_STATUS'
	};
}

export function updateSubmitStatus(payload: PlaceBulkApprovalOutput): UPDATE_SUBMIT_STATUS {
	return {
		type: '@@BULK_APPROVAL/UPDATE_SUBMIT_STATUS',
		payload: payload
	};
}

export function updateDownloadReportSubmitStatus(
	payload: SendBulkApprovalReportOutput
): UPDATE_DOWNLOAD_REPORT_SUBMIT_STATUS {
	return {
		type: '@@BULK_APPROVAL/UPDATE_DOWNLOAD_REPORT_SUBMIT_STATUS',
		payload: payload
	};
}
