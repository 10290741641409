import React, { memo, useCallback } from 'react';

import Select, { SelectOption } from '@amzn/meridian/select';

import { Month } from '@app/constants';
import { getCurrentMonthAndYear } from '@app/util/date_util';

interface Props {
    selectedMonth?: Month;
    selectedYear?: number;
    // eslint-disable-next-line no-unused-vars
    onUpdateMonth: (month: Month) => void;
}

const { currentMonth, currentYear } = getCurrentMonthAndYear();

const MonthSelector: React.FunctionComponent<Props> = ({
    selectedMonth = currentMonth,
    selectedYear = currentYear,
    onUpdateMonth
}) => {
    const isCurrentYear = selectedYear === currentYear;
    const currMonth = currentMonth;

    const isMonthEnabled = useCallback(
        (month: number) => !isCurrentYear || month <= currMonth,
        [isCurrentYear]
    );

    return (
        <Select
            value={selectedMonth}
            onChange={onUpdateMonth}
            label='Month'
            placeholder='Select Month'
            size='xlarge'>
            {isMonthEnabled(0) && <SelectOption value={0} label='Jan' />}
            {isMonthEnabled(1) && <SelectOption value={1} label='Feb' />}
            {isMonthEnabled(2) && <SelectOption value={2} label='Mar' />}
            {isMonthEnabled(3) && <SelectOption value={3} label='Apr' />}
            {isMonthEnabled(4) && <SelectOption value={4} label='May' />}
            {isMonthEnabled(5) && <SelectOption value={5} label='Jun' />}
            {isMonthEnabled(6) && <SelectOption value={6} label='Jul' />}
            {isMonthEnabled(7) && <SelectOption value={7} label='Aug' />}
            {isMonthEnabled(8) && <SelectOption value={8} label='Sep' />}
            {isMonthEnabled(9) && <SelectOption value={9} label='Oct' />}
            {isMonthEnabled(10) && <SelectOption value={10} label='Nov' />}
            {isMonthEnabled(11) && <SelectOption value={11} label='Dec' />}
        </Select>
    );
};

export default memo(
    MonthSelector,
    (prevProps, nextProps) =>
        prevProps.selectedMonth === nextProps.selectedMonth &&
        prevProps.selectedYear === nextProps.selectedYear
);
