import React, { memo } from 'react';

import { TableCell, TableRow } from '@amzn/meridian/table';

interface Props {
	columnSpan: number;
}

const EmptyTableRow: React.FunctionComponent<Props> = ({ columnSpan }) => {
	return (
		<TableRow>
			<TableCell alignmentHorizontal='center' columnSpan={columnSpan}>
				No disbursements available
			</TableCell>
		</TableRow>
	);
};

export default memo(
	EmptyTableRow,
	(prevProps, nextProps) => prevProps.columnSpan === nextProps.columnSpan
);
