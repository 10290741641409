import { map } from 'lodash';
import React, { memo } from 'react';

import Select, { SelectOption } from '@amzn/meridian/select';

interface Props {
	selectedBusiness: string;
	businesses: string[];
	// eslint-disable-next-line no-unused-vars
	onUpdateBusiness: (business: string) => void;
}

const BusinessSelector: React.FunctionComponent<Props> = ({
	businesses,
	selectedBusiness,
	onUpdateBusiness
}) => {
	return (
		<Select
			value={selectedBusiness}
			onChange={onUpdateBusiness}
			label='Business'
			placeholder='Select business'
			size='xlarge'>
			{map(businesses, business => (
				<SelectOption key={business} value={business} label={business} />
			))}
		</Select>
	);
};

export default memo(
	BusinessSelector,
	(prevProps, nextProps) =>
		prevProps.selectedBusiness === nextProps.selectedBusiness &&
		prevProps.businesses === nextProps.businesses
);
