import {
    CurrencyDisbursementSummaryMap, CurrencyDisbursementTypeSummaryVarianceList, DisbursementSummary, ListDisbursementSummaryOutput
} from "@amzn/digital-vendor-payments-gateway-client";
import refreshTokens from "@amzn/meridian-tokens/base/icon/refresh";
import Alert from "@amzn/meridian/alert";
import Box from "@amzn/meridian/box";
import Button from "@amzn/meridian/button";
import Column from "@amzn/meridian/column/column";
import Icon from "@amzn/meridian/icon";
import Legend, { LegendProvider } from "@amzn/meridian/legend";
import victoryBarPlugin from "@amzn/meridian/legend/plugin/victory-bar";
import victoryTooltipPlugin from "@amzn/meridian/legend/plugin/victory-tooltip";
import Loader from "@amzn/meridian/loader";
import Row from "@amzn/meridian/row";
import Table, { TableCell, TableRow } from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import useVictoryTheme from "@amzn/meridian/use-victory-theme";
import React from "react";
import { useAsync } from "react-async";
import { useDispatch, useSelector } from "react-redux";
import { listDisbursementSummary } from "src/apis/list_disbursement";
import { getCurrentStageConfig } from "src/constants/stage";
import { createKatalMetricsPublisher } from "src/helpers/katal_metrics_emitter";
import { RootState } from "src/reducers";
import {
    refreshSummaries,
    updateDisbursementSummaryState
} from "src/reducers/disbursement_summary/actions";
import { getDateFormatStringFromDate } from "src/util/date_util";
import { formatGraphXAxisLabels, formatString } from "src/util/string_util";
import { v4 as uuid } from "uuid";
import {
    VictoryAxis, VictoryBar, VictoryChart, VictoryStack, VictoryVoronoiContainer
} from "victory";


/**
 * Component which renders the bulk approval lines from the data fetched from
 * ListAPI whenever input in InputGroup changes.
 */

interface DisbursementSummaryGraphDataPoint {
    key: string,
    label: string,
    data: {disbursementStatus: string, value: number , count: number}[];
}

interface DisbursementVarianceGraphDataPoint {
    key: string,
    label: string,
    data: {month: string, value: number}[];
}

interface GlGraphData {
    disbursementSummaryDataPoints:DisbursementSummaryGraphDataPoint[];
    disbursementSummaryVarianceDataPoints:DisbursementVarianceGraphDataPoint[];
    disbursementType: String;
    disbursementTypeSummaryMaxDigitCountValue : number;
    disbursementTypeVarianceSummaryMaxDigitCountValue : number;
}

const style = {
    color: '#232F3E',
    fontFamily: '"Amazon Ember",Arial,sans-serif' ,
    fontSize: '14px' ,
    fontWeight: 600
}

function numOfDigits(x:number) {
    return Math.max(Math.floor(Math.log10(Math.abs(x))), 0) + 1;
}

function emitMetricOnPageLoad(gl:string,load_status:string){
    const actionMetricsPublisher = createKatalMetricsPublisher('disbursement_summary_page_load');
    actionMetricsPublisher.publishCounterMonitor(load_status+'_'+gl, 1);
}

const Header = (
    <TableRow>
        <TableCell alignmentHorizontal="center">Disbursement Type Summary Amounts</TableCell>
        <TableCell alignmentHorizontal="center">Disbursement Type Monthly Variance Amounts</TableCell>
    </TableRow>
);

export const graphClickEvent = (graphData: object ,disbursementType:string, year: string , month: string , gl: string) => {
            let disbursementGroup:string = '';
            let x = Object.entries(graphData);
            for(let i = 0 ; i < x.length; i++ ){
                let dataEntry = x[i];
                if(dataEntry[0] === 'datum')
                {
                    let graphDataPoints = Object.entries(dataEntry[1]);
                    for(let j = 0 ; j < graphDataPoints.length ; j++){
                        let graphDataPoint = graphDataPoints[j];
                        if(typeof graphDataPoint[1] === "string" && graphDataPoint[0]==='disbursementStatus')
                            disbursementGroup = graphDataPoint[1];
                    }
                }
            }
            let currentMonthDateString:string = year+'-'+month+'-01';
            let currentMonthStartDate : Date = new Date(currentMonthDateString);

            // +1 is done to current month , as Apr months effective date lies in May 1(inclusive) - June 1(exclusive)
            currentMonthStartDate.setMonth(currentMonthStartDate.getMonth()+1);
            const graphQueryStartDate:string = getDateFormatStringFromDate(currentMonthStartDate);

            let nextMonthDateString:string = year+'-'+month+'-01';
            let nextMonthStartDate : Date = new Date(nextMonthDateString);
            nextMonthStartDate.setMonth(nextMonthStartDate.getMonth() + 2);
            nextMonthStartDate.setDate(nextMonthStartDate.getDate() - 1);
            const graphQueryEndDate:string = getDateFormatStringFromDate(nextMonthStartDate);
            const stageConfig = getCurrentStageConfig();

            //Format in query string is mm%2Fdd%2Fyyyy
            const queryStartDate: string = formatString('{0}%2F{1}%2F{2}',graphQueryStartDate.substr(5,2),graphQueryStartDate.substr(8,2)
                ,graphQueryStartDate.substr(0,4));
            const queryEndDate: string = formatString('{0}%2F{1}%2F{2}',graphQueryEndDate.substr(5,2),graphQueryEndDate.substr(8,2)
                ,graphQueryEndDate.substr(0,4));

            let listDisbursementLink = formatString(listDisbursementLinkPattern,((stageConfig.REALM !=='NA')?"-"+stageConfig.REALM.toLowerCase():"")
                ,((stageConfig.STAGE==="beta")?".integ":""),gl,queryStartDate,queryEndDate,disbursementType);

            const actionMetricsPublisher = createKatalMetricsPublisher('disbursement_summary_graph_click');

            actionMetricsPublisher.publishCounterMonitor(disbursementType, 1);

            global.open(listDisbursementLink);
        };

const listDisbursementLinkPattern: string = 'https://dapr{0}{1}.amazon.com/dvps/listDisbursements.jsp?glProductGroup={2}'
    +'&startDate={3}&endDate={4}&disbursementIds=&vendorCode=&disbursementStatus='
    +'&disbursementType={5}&marketplace=&royaltyCurrencyCode=&holdStatus=&salesType=';

  export const DisbursementSummaryGraphs: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const disbursementSummarySelector = (state: RootState) => state.disbursement_summary;
    const disbursementSummaryValue = useSelector(disbursementSummarySelector);

    const disbursementSummaryInput = disbursementSummaryValue.inputGroup;
    const summaries = disbursementSummaryValue.summaries;

    const refreshSummariesHandler = (data: ListDisbursementSummaryOutput) => {
        let payload: {
            glName: String, payload: DisbursementSummary, checked: boolean, id: string
        }[] = [];
        const disbursementSummaryMap = Object.entries(data.disbursementSummaryMap);
        for (let [key, value] of disbursementSummaryMap) {
            payload.push(
            {
                glName: data.glName,
                payload: value,
                checked: false,
                id: uuid()
            });
        }
        dispatch(refreshSummaries(payload));
    };


    const {isPending, reload} = useAsync({
        promiseFn: listDisbursementSummary,
        onResolve: refreshSummariesHandler,
        onReject: function (error) {
            console.error(error);
            dispatch(updateDisbursementSummaryState("fetchError", true));
        },
        input_group: disbursementSummaryInput,
        year: disbursementSummaryInput.year,
        month: disbursementSummaryInput.month,
        gl: disbursementSummaryInput.gl,
        watchFn: function (current, prev) {
            if (
                (current.input_group !== prev.input_group ||
                    current.input_group.gl !== prev.input_group.gl) &&
                current.year &&
                current.month
            ) {
                return true;
            }
            return false;
        },
    });

    const createGraphsDataPointsFromSummaries = () =>{
        let glGraphData :GlGraphData[] = [];
        summaries.forEach(summary => {
            const disbursementType: String = summary.payload.disbursementType;
            const disbursementSummaryDataPointsOutput
                = createDisbursementTypeSummary(Object.entries(summary.payload.disbursementTypeSummaryMap));
            const disbursementSummaryDataPoints:DisbursementSummaryGraphDataPoint[]
                = disbursementSummaryDataPointsOutput.disbursementSummaryGraphDataPoints;
            const disbursementTypeSummaryMaxValue = disbursementSummaryDataPointsOutput.maxGraphValue;

            const disbursementTypeVarianceSummaryOutput
                = createDisbursementTypeVarianceSummary(Object.entries(summary.payload.disbursementTypeVarianceMap));
            const disbursementSummaryVarianceDataPoints:DisbursementVarianceGraphDataPoint[]
                = disbursementTypeVarianceSummaryOutput.disbursementVarianceGraphDataPoints;
            const disbursementTypeVarianceSummaryMaxValue = disbursementTypeVarianceSummaryOutput.maxGraphValue;

            //Edge case - currencies might be present in API output , but there are no disbursements
            //Pushing blank data in such case
            disbursementSummaryDataPoints.forEach( (disbursementSummaryDataPoint) => {
                if(disbursementSummaryDataPoint.data.length == 0){
                    disbursementSummaryDataPoint.data.push({
                        disbursementStatus:" ",
                        value: 0.0,
                        count: 0
                    });
                };
                disbursementSummaryDataPoint.data.forEach( (disbursementSummaryGraphData) => {
                    disbursementSummaryGraphData.disbursementStatus = formatGraphXAxisLabels(disbursementSummaryGraphData.disbursementStatus);
                });
            });

            // If both summary and variance are missing data points , we wont be showing the disbursementType row on UI
            if(disbursementSummaryDataPoints.length != 0 || disbursementSummaryVarianceDataPoints.length != 0) {
                /* Pushing blank data in case there are no summary . If blank data is not there in list , corresponding graph would show unknown characters
                and not show value as zero */
                if(disbursementSummaryDataPoints.length == 0){
                    disbursementSummaryDataPoints.push(
                        {
                            key: " ",
                            label: " ",
                            data: [{disbursementStatus: " ", value: 0.0 , count: 0}]
                        }
                    )
                }

                /* Pushing blank data in case there are no summary . If blank data is not there in list , corresponding graph would show unknown characters
                and not show value as zero */
                if(disbursementSummaryVarianceDataPoints.length == 0){
                    disbursementSummaryVarianceDataPoints.push(
                        {
                            key: " ",
                            label: " ",
                            data: [{month: " ", value: 0.0}]
                        }
                    )
                }

                glGraphData.push(
                    {
                        disbursementType: disbursementType,
                        disbursementSummaryDataPoints: disbursementSummaryDataPoints,
                        disbursementSummaryVarianceDataPoints: disbursementSummaryVarianceDataPoints,

                        // +1 is done for the (-)negative sign
                        disbursementTypeSummaryMaxDigitCountValue: numOfDigits(disbursementTypeSummaryMaxValue) + 1,
                        disbursementTypeVarianceSummaryMaxDigitCountValue: numOfDigits(disbursementTypeVarianceSummaryMaxValue) + 1
                    }
                );
            }
        });
        return glGraphData;
    }

    const createDisbursementTypeSummary = (disbursementSummaryMap: [string, CurrencyDisbursementSummaryMap][]) => {
        let disbursementSummaryGraphDataPoints : DisbursementSummaryGraphDataPoint[] = [];
        let maxGraphValue:number = 0;
        for (let [key, value] of disbursementSummaryMap) {
            let disbursementSummaryMapValues = Object.entries(value);
            let disbursementGroupPoints : {disbursementStatus: string, value: number, count : number}[] = [];
            for (let [key, value] of disbursementSummaryMapValues) {
                maxGraphValue=Math.max(maxGraphValue,Math.abs(value.amountSum));
                disbursementGroupPoints.push(
                    {
                        disbursementStatus:key,
                        value: +value.amountSum,
                        count: +value.count
                    }
                );
            }
            disbursementSummaryGraphDataPoints.push(
                {
                    key: key,
                    label: key,
                    data: disbursementGroupPoints
                }
            );
        }
        return {
            disbursementSummaryGraphDataPoints:disbursementSummaryGraphDataPoints,
            maxGraphValue:maxGraphValue
        }
    };

      const createDisbursementTypeVarianceSummary = (disbursementTypeVarianceMap: [string, CurrencyDisbursementTypeSummaryVarianceList][]) => {
          let disbursementVarianceGraphDataPoints : DisbursementVarianceGraphDataPoint[] = [];
          let maxGraphValue:number = 0;
          for (let [key, value] of disbursementTypeVarianceMap) {
              let disbursementTypeVarianceMapValues = Object.entries(value);
              let disbursementTypeVariancePoints : {month: string, value: number}[] = [];
              for (let [key, value] of disbursementTypeVarianceMapValues) {
                  maxGraphValue=Math.max(maxGraphValue,Math.abs(value.amountSum));
                  disbursementTypeVariancePoints.push(
                      {
                          month: value.month,
                          value: +value.amountSum,
                      }
                  );
              }
              disbursementVarianceGraphDataPoints.push(
                  {
                      key: key,
                      label: key,
                      data: disbursementTypeVariancePoints
                  }
              );
          }
          return {
              disbursementVarianceGraphDataPoints:disbursementVarianceGraphDataPoints,
              maxGraphValue:maxGraphValue
          };
      }

    const formatDisbursementSummaryLabel = (lprops:any) => lprops.datum.value +' (' + lprops.datum.count + ')';

    const formatDisbursementVarianceLabel = (lprops:any) => lprops.datum.value ;

    const createDisbursementSummaryGraph = (disbursementType:string , disbursementSummaryGraphDataPoints:DisbursementSummaryGraphDataPoint[], disbursementTypeSummaryMaxDigitCountValue:number) =>{
        const theme = useVictoryTheme({ showIndependentGrid: false });
        return (
            <LegendProvider
                data={disbursementSummaryGraphDataPoints}
                plugins={[victoryBarPlugin({ theme }), victoryTooltipPlugin()]}
                aria-label="Bar chart graphing the summary of months' disbursements"
            >
                {({ getBarProps, voronoiContainerProps, Tooltip }) => (
                    <Column spacing="none" maxWidth={1000}>
                        <VictoryChart
                            theme={theme}
                            width={1250}
                            height={400}

                            //value on y-axis would auto-scale according to the values , each digit takes 9 px with 18px initial space
                            padding={{ left: (18+(9*disbursementTypeSummaryMaxDigitCountValue)) , bottom:40 ,right:60 }}

                            domainPadding={{ x: 50, y: 0 }}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    labels={formatDisbursementSummaryLabel}
                                    {...voronoiContainerProps}
                                />
                            }
                        >
                            <VictoryAxis />
                            <VictoryAxis dependentAxis />
                            <VictoryStack>
                                {disbursementSummaryGraphDataPoints.map(({ data, key }, stackedIndex) => (
                                    <VictoryBar
                                        events={[
                                            {
                                                target: 'data',
                                                eventHandlers: {
                                                    onClick: (event: EventListener, graphData: Map<string,Map<string,string>> ) => {
                                                        graphClickEvent(graphData,disbursementType,disbursementSummaryInput.year,disbursementSummaryInput.month,disbursementSummaryInput.gl);
                                                    },
                                                },
                                            },
                                        ]}
                                        key={key}
                                        data={data}
                                        x="disbursementStatus"
                                        y="value"
                                        barWidth={40}
                                        labels={formatDisbursementSummaryLabel}
                                        labelComponent={
                                            <Tooltip
                                                legendKey={key}
                                                ariaLabels={(props:any) =>
                                                    `${formatDisbursementSummaryLabel(props)}, ${props.datum.disbursementStatus}`
                                                }
                                            />
                                        }
                                        {...getBarProps(key, { stackedIndex })}
                                    />
                                ))}
                            </VictoryStack>
                        </VictoryChart>
                        <Legend direction="horizontal" />
                    </Column>
                )}
            </LegendProvider>
        )
  }

      const createDisbursementVarianceGraph = (disbursementType:string , graphDataPoints:DisbursementVarianceGraphDataPoint[] , disbursementTypeVarianceSummaryMaxDigitCountValue:number) =>{
          const theme = useVictoryTheme({ showIndependentGrid: false });
          return (
              <LegendProvider
                  data={graphDataPoints}
                  plugins={[victoryBarPlugin({ theme }), victoryTooltipPlugin()]}
                  aria-label="Bar chart graphing the summary of disbursements variances"
              >
                  {({ getBarProps, voronoiContainerProps, Tooltip }) => (
                      <Column spacing="none" maxWidth={1000}>
                          <VictoryChart
                              theme={theme}
                              width={1250}
                              height={400}
                              domainPadding={{ x: 50, y: 0 }}

                              //value on y-axis would auto-scale according to the values , each digit takes 9 px with 18px initial space
                              padding={{ left: (18+(9*disbursementTypeVarianceSummaryMaxDigitCountValue)) ,bottom:25 ,right:30 }}
                              containerComponent={
                                  <VictoryVoronoiContainer
                                      labels={formatDisbursementVarianceLabel}
                                      {...voronoiContainerProps}
                                  />
                              }
                          >
                              <VictoryAxis />
                              <VictoryAxis dependentAxis />
                              <VictoryStack>
                                  {graphDataPoints.map(({ data, key }, stackedIndex) => (
                                      <VictoryBar
                                          key={key}
                                          data={data}
                                          x="month"
                                          y="value"
                                          barWidth={40}
                                          labels={formatDisbursementVarianceLabel}
                                          labelComponent={
                                              <Tooltip
                                                  legendKey={key}
                                                  ariaLabels={(props:any) =>
                                                      `${formatDisbursementVarianceLabel(props)}, ${props.datum.month}`
                                                  }
                                              />
                                          }
                                          {...getBarProps(key, { stackedIndex })}
                                      />
                                  ))}
                              </VictoryStack>
                          </VictoryChart>
                          <Legend direction="horizontal" />
                      </Column>
                  )}
              </LegendProvider>
          )
      }

    const GLGraphs = () => {

        let glGraphData:GlGraphData[] = [];
        if(summaries.length != 0) {
            glGraphData = createGraphsDataPointsFromSummaries();
        }
        else{
            return (
                <Row alignmentHorizontal="center" spacingInset="600">
                    <Column heights="fit" alignmentHorizontal="center">
                        <Alert size="large" type="error">
                            <Text type="b500">
                                Number of summaries fetch is 0.
                                <br />
                                Try refreshing the page
                            </Text>
                        </Alert>
                        <Button type="icon" onClick={reload}>
                            <Icon tokens={refreshTokens}>Refresh</Icon>
                        </Button>
                    </Column>
                </Row>
            );
        }
        var rows: any[];
        rows = [];
        glGraphData.forEach(
            data => {
                rows.push(
                    <Column key={data.disbursementType.toString()}>
                        <Row>
                        <Column
                             alignmentVertical="center"
                             width="100%"
                             alignmentHorizontal="center"
                        >
                            <span style={style}>{data.disbursementType.toString()}</span>
                        </Column>
                        <Column
                            alignmentVertical="center"
                            width="100%"
                            alignmentHorizontal="center"
                        >
                            <span style={style}>{data.disbursementType.toString()}</span>
                        </Column>
                        </Row>
                  <Row
                      spacingInset="400"
                      alignmentVertical="stretch"
                      width="100%"
                  >
                      <Box type="outline" spacingInset="400">
                        {createDisbursementSummaryGraph(data.disbursementType.toString(),data.disbursementSummaryDataPoints,data.disbursementTypeSummaryMaxDigitCountValue)}
                          <br/>
                          <span style={style}>Number in brackets indicates count of disbursements</span>
                      </Box>
                      <Box type="outline" spacingInset="400">
                        {createDisbursementVarianceGraph(data.disbursementType.toString(),data.disbursementSummaryVarianceDataPoints, data.disbursementTypeVarianceSummaryMaxDigitCountValue)}
                          <br/>
                      </Box>
                  </Row>
                </Column>
                );
            }
        )
        if(rows.length != 0) {
            // GL 351 summary data is cached in VDMS side which gets refreshed every 20 mins
            // Adding banner in case GL 351 is selected
            const isGL351 = disbursementSummaryInput.gl == '351';
            return (
                <div>
                    <Row
                        spacingInset="400"
                        alignmentVertical="stretch"
                        wrap="down"
                        width="100%"
                        widths={["grid-12"]}
                    >
                        <Table
                            headerRows={1}
                            spacing="small"
                        >
                            {isGL351 ? (
                                <Row width="100%" alignmentHorizontal="center">
                                    <Alert type="warning">The data shown below may be outdated by 20 mins.</Alert>
                                </Row>
                            ) : (
                                <Row></Row>
                            )}
                            {Header}
                        </Table>
                    </Row>
                    {rows}
                </div>
            );
        }else {
            return (
                <div>
                    <Row alignmentHorizontal="center" spacingInset="500">
                        <Alert size="large" type="informational">
                            <Text type="b500">
                                There have been no disbursements for the last 3 months for this GL.
                            </Text>
                        </Alert>
                    </Row>
                </div>
            );
        }

    }

    return <>
        {isPending ? (
            <Row alignmentHorizontal="center" spacingInset="500">
                <Column heights="fill" alignmentHorizontal="center">
                    <Alert size="large" type="informational">
                        <Text type="b500">
                            Please Wait – We are fetching summaries
                            <br />
                            This might take upto 10 seconds based on business volume.
                        </Text>
                    </Alert>
                    <Loader size="large" type="circular" />
                </Column>
            </Row>
        ) : disbursementSummaryValue.fetchError ? (
            <>
                {emitMetricOnPageLoad(disbursementSummaryInput.gl,'failure')}
                <Row alignmentHorizontal="center" spacingInset="600">
                    <Column heights="fit" alignmentHorizontal="center">
                        {/*todo : Adding some information about error . Also make this change in case of bulk approval page.*/}
                        <Alert size="large" type="error">
                            <Text type="b500">
                                We have encountered an error while fetching the summaries.
                                <br />
                                Try refreshing the page
                            </Text>
                        </Alert>
                        <Button type="icon" onClick={reload}>
                            <Icon tokens={refreshTokens}>Refresh</Icon>
                        </Button>
                    </Column>
                </Row>
            </>
        ) : (
            <>
                {emitMetricOnPageLoad(disbursementSummaryInput.gl,'success')}
                <GLGraphs></GLGraphs>
            </>
        )}
    </>;
};

