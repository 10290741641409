import AWS from 'aws-sdk';

import {
    CaspianApprovalsTransformerClient,
    Comparator,
    FilterKey,
    GetApprovalsCommand,
    GetApprovalsCommandOutput
} from '@amzn/caspianapprovalstransformerservice-client';

const MAX_RESULTS = 150;

let clientInstance: CaspianApprovalsTransformerClient;

export async function createCATSClientInstance(
    endpoint: string,
    region: string
): Promise<CaspianApprovalsTransformerClient> {
    if (!clientInstance) {
        try {
            // the credentials are placeholders and will be automatically replaced by the default file
            clientInstance = await new CaspianApprovalsTransformerClient({
                endpoint: endpoint,
                region: region,
                credentials: new AWS.Credentials({
                    accessKeyId: 'XXXXXXXXXXXXXXXXXXXX',
                    secretAccessKey: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX',
                    sessionToken: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
                })
            });
        } catch {
            throw new Error('Could not create client instance');
        }
    }

    return clientInstance!;
}

export async function getDisbursementsFromMarketplaceApproval(
    approvalType: string,
    business: string,
    marketplace: string,
    gl: number,
    paymentStartDate: Date,
    paymentEndDate: Date,
    userName: string,
    token?: string
): Promise<GetApprovalsCommandOutput> {
    const command = new GetApprovalsCommand({
        gl: gl,
        userName: userName,
        filters: [
            {
                key: FilterKey.TYPE,
                comparator: Comparator.EQ,
                value: { str: business }
            },
            {
                key: FilterKey.MARKETPLACE,
                comparator: Comparator.EQ,
                value: { str: marketplace }
            },
            {
                key: FilterKey.PERIOD_START,
                comparator: Comparator.GE,
                value: { date: paymentStartDate }
            },
            {
                key: FilterKey.PERIOD_END,
                comparator: Comparator.LE,
                value: { date: paymentEndDate }
            },
            {
                key: FilterKey.DISBURSEMENT_STATUS,
                comparator: Comparator.EQ,
                value: { str: approvalType }
            },
            {
                key: FilterKey.DISBURSEMENT_PROGRESS,
                comparator: Comparator.EQ,
                value: { str: 'PROCESSING' }
            }
        ],
        nextToken: token,
        maxResults: MAX_RESULTS
    });
    return clientInstance!.send(command);
}
