import {
  BulkApproval,
  ListBulkApprovalOutput
} from "@amzn/digital-vendor-payments-gateway-client";
import Button from "@amzn/meridian/button";
import Checkbox from "@amzn/meridian/checkbox";
import Link from "@amzn/meridian/link";
import Row from "@amzn/meridian/row";
import Table, {
  TableActionBar, TableCell, TableRow
} from "@amzn/meridian/table";
import Text from "@amzn/meridian/text";
import Tooltip from "@amzn/meridian/tooltip";
import React from "react";
import { BulkApprovalOperations, Resources } from "src/authorization/types";
import Authorize from "../common/Authorize";
// @ts-ignore
import infoKnockoutTokens from "@amzn/meridian-tokens/base/icon/info-knockout";
// @ts-ignore
import refreshTokens from "@amzn/meridian-tokens/base/icon/refresh";
// @ts-ignore
import alertTokens from "@amzn/meridian-tokens/base/icon/alert";
// @ts-ignore
import caretDownTokens from "@amzn/meridian-tokens/base/icon/caret-down";
// @ts-ignore
import caretUpTokens from "@amzn/meridian-tokens/base/icon/caret-up";

import { v4 as uuid } from "uuid";

import Alert from "@amzn/meridian/alert";
import Column from "@amzn/meridian/column/column";
import Icon from "@amzn/meridian/icon";
import Loader from "@amzn/meridian/loader";
import Tag from "@amzn/meridian/tag";
import { useAsync } from "react-async";
import { useDispatch, useSelector } from "react-redux";
import { listBulkApprovals } from "src/apis/bulk_approval";
import {
  canSelectApproval,
  formatDateAsString,
  isApprovalWindowOpen, isSodViolated, SLATagHelper, summaryBuilder,
  tSummary
} from "src/helpers/bulk_approval";
import { createKatalMetricsPublisher } from "src/helpers/katal_metrics_emitter";
import { RootState } from "src/reducers";
import {
  refreshApprovals, updateBulkApprovalReportDownloadRequest,
  updateBulkApprovalState,
  updateSelectedApprovals,
  updateSingleApprovalRequest
} from "src/reducers/bulk_approval/actions";
import { tApproval, tApprovalPhases } from "src/reducers/bulk_approval/types";
import { BulkApprovalInfo } from "./BulkApprovalInfo";

function emitMetricOnPageLoad(load_status:string , business:string){
  const actionMetricsPublisher = createKatalMetricsPublisher('bulk_approval_page_load');
  actionMetricsPublisher.publishCounterMonitor(load_status+'_'+business, 1);
}

/**
 * Component which renders the bulk approval lines from the data fetched from
 * ListAPI whenever input in InputGroup changes.
 */
export const ListBulkApproval: React.FunctionComponent = () => {
  const dispatch = useDispatch();

  const bulkApprovalSelector = (state: RootState) => state.bulk_approval;
  const bulkApprovalValue = useSelector(bulkApprovalSelector);

  const bulkApprovalInput = bulkApprovalValue.inputGroup;
  const approvals = bulkApprovalValue.approvals;
  const selectAll = bulkApprovalValue.selectAll;

  const refreshApprovalsHandler = (data: ListBulkApprovalOutput) => {
    const payload = data.bulkApprovalList.map((element) => {
      return {
        payload: element,
        checked: false,
        id: uuid(),
      };
    });
    dispatch(refreshApprovals(payload));
  };

  const { isPending, reload } = useAsync({
    promiseFn: listBulkApprovals,
    onResolve: refreshApprovalsHandler,
    onReject: function (error) {
      console.error(error);
      dispatch(updateBulkApprovalState("fetchError", true));
    },
    input_group: bulkApprovalInput,
    date_range: bulkApprovalInput.date_range,
    business: bulkApprovalInput.business,
    marketplace: bulkApprovalInput.marketplace,
    date_range_type: bulkApprovalInput.date_range_type,
    disbursement_status_filter: bulkApprovalInput.disb_status_filter,
    watchFn: function (current, prev) {
      if (
        (current.input_group !== prev.input_group ||
        current.input_group.business !== prev.input_group.business) &&
        current.date_range[0] &&
        current.date_range[1]
      ) {
        return true;
      }
      return false;
    },
  });

  const bulkApprovalReportDownloadHandler = (id: string) => {
    dispatch(updateBulkApprovalReportDownloadRequest(id));
  };

  const singleApprovalHandler = (id: string, phase: tApprovalPhases) => {
    dispatch(updateSingleApprovalRequest(id, phase));
  };

  const approvalSelectHandler = (val: boolean, id: string) => {
    dispatch(updateSelectedApprovals([id], val));
  };

  const selectAllHandler = (value: boolean) => {
    const ids = approvals
      .filter((approval) => {
        return canSelectApproval(approval);
      })
      .map((approval) => approval.id);
    dispatch(updateBulkApprovalState("selectAll", value));
    dispatch(updateSelectedApprovals(ids, value));
  };

  const enableApproveAll = !approvals.some((e) => e.checked);

  const ApprovalLines = approvals.map((element) => {
    const approvalPayload = element.payload;
    const summary = summaryBuilder(approvalPayload);
    const sla = SLATagHelper(approvalPayload);

    return (
      <TableRow key={element.id}>
        <TableCell>
          <RenderCheckbox
            approval={element}
            approvalSelectHandler={approvalSelectHandler}
          ></RenderCheckbox>
        </TableCell>
        <BulkApprovalInfo approvalPayload={approvalPayload} summary={summary} />
        {
          (bulkApprovalInput.disb_status_filter) ? 
          ( 
            <>
              <TableCell alignmentHorizontal="center">
                {bulkApprovalInput.disb_status_filter}
              </TableCell>
            </>
          ) :
          (
            <>
              <TableCell alignmentHorizontal="center">
                <RenderValues
                  summary={summary}
                  render={(s: tSummary) => {
                    return (
                      <Row
                        alignmentHorizontal="start"
                        spacingInset="none"
                        spacing="none"
                        width="100%"
                        widths={["90%", "10%"]}
                      >
                        <span>
                          {s.variance?.toLocaleString() + " " + s.currency}{" "}
                        </span>
                        <span>
                          {s.variance > 0 ? (
                            <Icon className="green-text" tokens={caretUpTokens} />
                          ) : (
                            <Icon className="red-text" tokens={caretDownTokens} />
                          )}
                        </span>
                        <br />
                      </Row>
                    );
                  } } />
              </TableCell>
              <TableCell alignmentHorizontal="center">
                <RenderValues
                  summary={summary}
                  render={(s: tSummary) => {
                    return s.variance_percent !== undefined &&
                      s.variance_percent !== null ? (
                      <>
                        {`${s.variance_percent.toFixed(1)}%`}
                        <br />
                      </>
                    ) : (
                      "N/A"
                    );
                  } } />
              </TableCell>
            </>
        )}
        <TableCell alignmentHorizontal="center">
          {formatDateAsString(
            approvalPayload.businessPhaseInfo?.approvalDueDate
          )}
          <br />
          {sla.showTag && <Tag type={sla.status}>{sla.text}</Tag>}
        </TableCell>
        <ApprovalInfo
          item={approvalPayload}
          phase="business"
          id={element.id}
          approveHandler={singleApprovalHandler}
        ></ApprovalInfo>
        <ApprovalInfo
          item={approvalPayload}
          phase="finance"
          id={element.id}
          approveHandler={singleApprovalHandler}
        ></ApprovalInfo>
        <TableCell alignmentHorizontal="center">
          <Button size="small" disabled={summary == null || summary.length == 0
              || summary[0].payments == null || summary[0].payments == 0} onClick={() => bulkApprovalReportDownloadHandler(element.id)}>
            Download
          </Button>
        </TableCell>
      </TableRow>
    );
  });

  const Header = (
    <TableRow>
      <TableCell>
        <Checkbox
          onChange={(value) => {
            selectAllHandler(value);
          }}
          checked={selectAll}
        />
      </TableCell>
      <TableCell alignmentHorizontal="center">Business</TableCell>
      <TableCell alignmentHorizontal="center">GL</TableCell>
      <TableCell alignmentHorizontal="center">Marketplace</TableCell>
      <TableCell alignmentHorizontal="center">Earnings Period</TableCell>
      <TableCell alignmentHorizontal="center">Total Amount</TableCell>
      <TableCell alignmentHorizontal="center">No.of Payments</TableCell>
      { !(bulkApprovalInput.disb_status_filter) ? (
            <>
              <TableCell alignmentHorizontal="center">
                Total Amount Variance{" "}
                <Tooltip
                  position="end"
                  title="Current Earnings Period Total Amount – Previous Earnings Period Total Amount"
                >
                  <Icon tokens={infoKnockoutTokens} />
                </Tooltip>
              </TableCell>
              <TableCell alignmentHorizontal="center">
                  Variance %{" "}
                  <Tooltip
                    position="end"
                    title="(Total Amount Variance / Previous Earnings Period Total Amount) * 100"
                  >
                    <Icon tokens={infoKnockoutTokens} />
                  </Tooltip>
              </TableCell>
            </> ) :
            (
            <TableCell alignmentHorizontal="center">
              Disbursement Status{" "} 
              <Tooltip
              position="end"
              title="The total amount and number of payments are for the disbursement status filter displayed below"
              >
                <Icon tokens={infoKnockoutTokens} />
              </Tooltip>
            </TableCell>
        )}
      <TableCell alignmentHorizontal="center">Due Date</TableCell>
      <TableCell alignmentHorizontal="center">Business Approval</TableCell>
      <TableCell alignmentHorizontal="center">Finance Approval</TableCell>
      <TableCell alignmentHorizontal="center">
        Email Disbursements Report{" "}
        <Tooltip
            position="end"
            title="Click Download button to send report to your Amazon mail id"
        >
          <Icon tokens={infoKnockoutTokens} />
        </Tooltip>
      </TableCell>
    </TableRow>
  );

  return <>
    {isPending ? (
      <Row alignmentHorizontal="center" spacingInset="500">
        <Column heights="fill" alignmentHorizontal="center">
          <Alert size="large" type="informational">
            <Text type="b500">
              Please Wait – We are fetching all the payments and calculating
              variances, <br />
              This might take upto 10 seconds based on business volume.
            </Text>
          </Alert>
          <Loader size="large" type="circular" />
        </Column>
      </Row>
    ) : bulkApprovalValue.fetchError ? (
      <Row alignmentHorizontal="center" spacingInset="600">
        {emitMetricOnPageLoad('failure',bulkApprovalInput.business)}
        <Column heights="fit" alignmentHorizontal="center">
          <Alert size="large" type="error">
            <Text type="b500">
              We have encountered an error while fetching the approvals.
              <br />
              Try refreshing the page
            </Text>
          </Alert>
          <Button type="icon" onClick={reload}>
            <Icon tokens={refreshTokens}>Refresh</Icon>
          </Button>
        </Column>
      </Row>
    ) : (
      <Row
        spacingInset="400"
        alignmentVertical="stretch"
        wrap="down"
        width="100%"
        widths={["grid-12"]}
      >
        {emitMetricOnPageLoad('success',bulkApprovalInput.business)}
        <Table
          headerRows={1}
          showDividers={true}
          showStripes={true}
          spacing="small"
        >
          <TableActionBar widths={[300, "fill"]}>
            <Text type="h300"> BULK APPROVALS </Text>
            <div />
            <Button
              type="primary"
              onClick={() => {
                dispatch(updateBulkApprovalState("showConfirmation", true));
              }}
              disabled={enableApproveAll}
            >
              Approve Selected
            </Button>
          </TableActionBar>
          {Header}
          {ApprovalLines}
        </Table>
      </Row>
    )}
  </>;
};

export const RenderValues = ({
  summary,
  render,
}: {
  summary: tSummary[] | undefined;
  render: any;
}) => {
  return (
    <>
      {" "}
      {summary && summary.length
        ? summary.map((s, index) => {
            return <span key={index}>{render(s, index)} </span>;
          })
        : "N/A"}
    </>
  );
};

/**
 * Component which renders the approval info or approval button if user has access
 * to do approval.
 */
const ApprovalInfo = ({
  item,
  phase,
  id,
  approveHandler,
}: {
  item: BulkApproval;
  phase: tApprovalPhases;
  id: string;
  approveHandler: (id: string, phase: tApprovalPhases) => void;
}) => {
  const isBusiness = phase === "business";
  const phaseInfo = isBusiness ? item.businessPhaseInfo : item.financePhaseInfo;
  const enableApproval = isApprovalWindowOpen(item);
  const sodViolation = isSodViolated(item, phase);

  return (
    <TableCell alignmentHorizontal="center">
      {phaseInfo?.bulkApprovalId ? (
        <span>
          {" "}
          <Link
            href={"https://phonetool.amazon.com/users/" + phaseInfo.approver}
            target="_blank"
            rel="noreferrer noopener"
          >
            {phaseInfo.approver}
          </Link>{" "}
          <br />
          {formatDateAsString(phaseInfo.approvalPlacementDate)}
        </span>
      ) : (
        <Authorize
          resource={Resources.BULK_APPROVAL}
          operation={
            isBusiness
              ? BulkApprovalOperations.BUSINESS_APPROVAL
              : BulkApprovalOperations.FINANCE_APPROVAL
          }
          attributes={{
            vp: item.business,
            mp: item.marketplaceId,
            typ: item.approvalType,
          }}
        >
          <Authorize.SUCCESS>
            {enableApproval && !sodViolation ? (
              <Button size="small" onClick={() => approveHandler(id, phase)}>
                Approve
              </Button>
            ) : (sodViolation ? (
              <Tooltip
                position="top"
                title={"The approval button is disabled due to SoD control as VM & FM approvals cannot be placed by same person. Please ask another person with permission to perform the approval"}
              >
                <Icon className="red-text" tokens={alertTokens} />
              </Tooltip>
            ) : (
              <Tooltip
                position="top"
                title={`Approval will be enabled on ${formatDateAsString(
                  item.businessPhaseInfo?.approvalWindowStartDate
                )}`}
              >
                <Icon tokens={alertTokens} />
              </Tooltip>
            ))}
          </Authorize.SUCCESS>
          <Authorize.FAILURE>Approval Pending</Authorize.FAILURE>
        </Authorize>
      )}
    </TableCell>
  );
};

/**
 * Component which renders checkbox if user has permission to exactly one phase.
 * This also takes care of the approval window.
 */
const RenderCheckbox = ({
  approval,
  approvalSelectHandler,
}: {
  approval: tApproval;
  approvalSelectHandler: (val: boolean, id: string) => void;
}) => {
  return (
    <>
      {canSelectApproval(approval) ? (
        <Checkbox
          checked={approval.checked}
          onChange={(val) => {
            approvalSelectHandler(val, approval.id);
          }}
        />
      ) : null}
    </>
  );
};
