import { chain, isEmpty, map } from 'lodash';
import React, { useMemo } from 'react';
import CollapsibleSection from 'src/components/common/CollapsibleSection';

import Table from '@amzn/meridian/table';

import { EmptyTableRow } from '@app/components/common';
import { bulkSnapshotApprovalApi, useFiltersSelector } from '@app/reducers';
import { getPaymentEndDate, getPaymentStartDate } from '@app/util/date_util';

import { ApprovalsHistoryHeaderRow } from './ApprovalsHistoryHeaderRow';
import ApprovalsHistoryTableRow from './ApprovalsHistoryTableRow';

const COLUMN_SPAN_APPROVALS_HISTORY = 12;
const ApprovalsHistory: React.FunctionComponent = () => {
    const { month, year, business, marketPlace, datePeriodType } = useFiltersSelector();

    const { currentData } =
        bulkSnapshotApprovalApi.endpoints.listBulkApprovalHistoryV2.useQueryState({
            marketplaceId: marketPlace.id,
            vendorProgram: business,
            paymentStartDate: getPaymentStartDate(month, year),
            paymentEndDate: getPaymentEndDate(month, year),
            dateRangeType: datePeriodType
        });

    const snapshots = useMemo(() => {
        return chain(currentData)
            .flatMap(({ bulkApprovalHistorySnapShots }) => bulkApprovalHistorySnapShots)
            .orderBy(
                snapshot => snapshot.bulkApprovalSnapshot.approvalPhaseInfo.approvalPlacementDate,
                'desc'
            )
            .value();
    }, [currentData]);
    const isEmptyData = isEmpty(snapshots);

    return (
        <CollapsibleSection pageTitle='Approval History'>
            <Table
                data-testid='approval-history-table'
                headerRows={1}
                showDividers
                spacing='small'
                rowComponents={[
                    ApprovalsHistoryHeaderRow,
                    ApprovalsHistoryTableRow,
                    EmptyTableRow
                ]}>
                <ApprovalsHistoryHeaderRow />
                {isEmptyData ? (
                    <EmptyTableRow columnSpan={COLUMN_SPAN_APPROVALS_HISTORY} />
                ) : (
                    map(snapshots, snapshot => (
                        <ApprovalsHistoryTableRow
                            key={snapshot.bulkApprovalSnapshot.snapShotId}
                            historySnapshot={snapshot}
                        />
                    ))
                )}
            </Table>
        </CollapsibleSection>
    );
};

export default ApprovalsHistory;
