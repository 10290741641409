import React from "react";
import Text from "@amzn/meridian/text";
import Column from "@amzn/meridian/column";
import Row from "@amzn/meridian/row";
import HomePageCard from "src/components/home/HomePageCard";
import Link from "@amzn/meridian/link";
import { useDispatch } from "react-redux";
import { updateFeedbackState } from "src/reducers/feedback/actions";
import {
  TT_QUICKLINK,
  FEATURE_REQUEST_QUICKLINK,
  DAPR_URLS,
  SNTP_LINK,
  FAQ_LINK,
  PERMISSION_LINK
} from "src/constants/urls";
import { getCurrentStageConfig } from "src/constants/stage";
import { useHistory } from "react-router-dom";
import { PAGE_LOCATIONS } from "src/constants/app";

const About: React.FunctionComponent = () => {
  let dispatch = useDispatch();
  let history = useHistory();
  const stageConfig = getCurrentStageConfig();
  const listDisbursementsUrl =
    DAPR_URLS[stageConfig.STAGE][stageConfig.REALM].list;
  const bulkApprovalUrl = DAPR_URLS[stageConfig.STAGE][stageConfig.REALM].bulk;
  return (
    <Column heights="fill" alignmentHorizontal="start">
      <Row
        spacingInset="none"
        alignmentVertical="stretch"
        wrap="down"
        width="100%"
        widths={["grid-4", "grid-4", "grid-4", "grid-4", "grid-4", "grid-4"]}
      >
        <HomePageCard heading="Getting Started">        
          <Text type="b300">
            View{" "}
            <Link href={FAQ_LINK} type="secondary"
              target="_blank"
              rel="noopener noreferrer">
              FAQs
            </Link>{" "} and {" "}
            <Link href={SNTP_LINK} type="secondary"
              target="_blank"
              rel="noopener noreferrer">
              Amazon’s Spending &amp; Transaction Policy,
            </Link>{" "}
            or{" "}
            <Link href={PERMISSION_LINK} type="secondary"
              target="_blank"
              rel="noopener noreferrer">
              Request Access
            </Link>
          </Text>
        </HomePageCard>
        <HomePageCard heading="Approve">         
          <Text type="b300">
          Approve disbursements{" "}
            <Link
              href={listDisbursementsUrl}
              type="secondary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Individually
            </Link>{" "}
            or in{" "}
            <Link
              onClick={history.push}
              href={PAGE_LOCATIONS.BULK_APPROVAL}
              type="secondary"
            >
              Bulk
            </Link>
          </Text>
        </HomePageCard>
        <HomePageCard heading="Action on Holds" isComingSoon={true}>
          <Text type="b300">
            Action on disbursements that have been placed on hold
          </Text>
        </HomePageCard>
        <HomePageCard heading="Manage Thresholds" isComingSoon={true}>
          <Text type="b300">
            Set or change thresholds for disbursement amount or vendor and
            disbursements that breach those thresholds will automatically be
            placed on hold each month
          </Text>
        </HomePageCard>
        <HomePageCard heading="Reports" isComingSoon={true}>
          <Text type="b300">
            Download a report of your disbursement data, including a history of
            approvals
          </Text>
        </HomePageCard>
        <HomePageCard heading="Get Help">
          <Text type="b300">
            <Link
              href={TT_QUICKLINK}
              target="_blank"
              rel="noopener noreferrer"
              type="secondary"
            >
              Report an issue,
            </Link>{" "}
            <Link
              href={FEATURE_REQUEST_QUICKLINK}
              target="_blank"
              rel="noopener noreferrer"
              type="secondary"
            >
              Request a feature,
            </Link>{" "}
            or{" "}
            <Link
              type="secondary"
              onClick={() =>
                dispatch(updateFeedbackState("isFeedbackOpen", true))
              }
            >
              Provide feedback
            </Link>
          </Text>
        </HomePageCard>
      </Row>
    </Column>
  );
};

export default About;
