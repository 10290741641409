import React, { useState } from 'react';

import withAnimateMount, { AnimateFade } from '@amzn/meridian/animate-mount';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Column from '@amzn/meridian/column';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import caretDownTokens from '@amzn/meridian-tokens/base/icon/caret-down';
import caretUpTokens from '@amzn/meridian-tokens/base/icon/caret-up';

// Because Meridian does not support AnimateMount with Typescript out of the box, we export their interface
// again with Typescript class definitions resulting in the ts-ignore
// see: https://sage.amazon.com/posts/725774
// eslint-disable-next-line
// @ts-ignore
export const AnimateFadeScaleWithMount: React.FC<AnimateSlideInWithMountProps> = withAnimateMount(AnimateFade);

interface Props {
    pageTitle: string;
}

const CollapsibleSection: React.FunctionComponent<Props> = ({ pageTitle, children }) => {
    const [open, setOpen] = useState(true);
    const onClick = () => setOpen(!open);

    return (
        <Column spacing='400'>
            <div onClick={onClick}>
                <Row>
                    <Text type='h400'>{pageTitle}</Text>
                    <Button
                        type='icon'
                        onClick={onClick}
                        size='large'
                        data-testid={'collapse-expand-button'}>
                        <Icon tokens={open ? caretUpTokens : caretDownTokens}>{open ? 'Collapse' : 'Open'}</Icon>
                    </Button>
                </Row>
            </div>
            <AnimateFadeScaleWithMount open={open} duration='150ms' easing='linear'>
                {({
                    className,
                    onTransitionEnd,
                    animatedRootRef
                }: {
                    className: string;
                    onTransitionEnd: any;
                    animatedRootRef: any;
                }) => (
                    <div
                        className={className}
                        ref={animatedRootRef}
                        onTransitionEnd={onTransitionEnd}>
                        <Box type='outline' spacingInset='400' overflowX='auto'>
                            {children}
                        </Box>
                    </div>
                )}
            </AnimateFadeScaleWithMount>
        </Column>
    );
};

export default CollapsibleSection;

export interface AnimateSlideInWithMountProps {
    /**
     * A function that returns the elements to animate.
     */
    // eslint-disable-next-line no-unused-vars
    children: (...args: never[]) => unknown;
    /**
     * If true the children will be mounted, otherwise they will be unmounted.
     */
    open: boolean;
    /**
     * Only applies to height based transitions. Allows for cases when the
     * desired effect is animating height to a value that's different than
     * the element's scrollHeight.
     */
    animateToHeight?: string | number;
    /**
     * The duration of the animation. This should be a valid CSS animation
     * duration (e.g. `"100ms"` or `"1s"`).
     */
    duration?: string;
    /**
     * The easing function of the animation. This should be a valid CSS easing
     * function (e.g. `"linear"` or `"ease"`).
     */
    easing?: string;
    /**
     * The function provided here will be called if the component transitions
     * from a closed state to an open state, or if it is mounted in an open
     * state. Note that this is called as soon as the state changes, which
     * occurs *before* the elements being mounted are actually available in
     * the DOM.
     */
    // eslint-disable-next-line no-unused-vars
    onOpen?: (...args: never[]) => unknown;
}
