import { map } from 'lodash';
import React, { memo } from 'react';

import Select, { SelectOption } from '@amzn/meridian/select';

import { ALL_MARKETPLACE, MarketPlace } from '@app/constants';

interface Props {
	marketPlaces: MarketPlace[];
	selectedMarketPlace?: MarketPlace;
	// eslint-disable-next-line no-unused-vars
	onUpdateMarketPlace: (value: number) => void;
}

//TODO: fetch only the selected VP.
const MarketplaceSelector: React.FunctionComponent<Props> = ({
	marketPlaces,
	selectedMarketPlace = ALL_MARKETPLACE,
	onUpdateMarketPlace
}) => {
	return (
		<Select
			value={selectedMarketPlace.id}
			onChange={onUpdateMarketPlace}
			label='Marketplace'
			placeholder='Select marketplace'
			size='xlarge'>
			<SelectOption key={999} value={0} label='ALL' />
			{map(marketPlaces, ({ id, name }) => (
				<SelectOption key={id} value={id} label={name} />
			))}
		</Select>
	);
};

export default memo(
	MarketplaceSelector,
	(prevProps, nextProps) =>
		prevProps.selectedMarketPlace === nextProps.selectedMarketPlace &&
		prevProps.marketPlaces === nextProps.marketPlaces
);
