/* eslint-disable @typescript-eslint/no-magic-numbers */
/*
 * Copy of https://code.amazon.com/packages/MidwayIdentityCredentialProvider/blobs/mainline/--/src/tokenRetriever.js
 * that can't be imported because it's not defined in the credentials.js file or exported there.
 */
const generateNonce = function(): string {
  let nonce = "";
  const characterSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 64; i = i + 1) {
    nonce =
      nonce +
      characterSet.charAt(Math.floor(Math.random() * characterSet.length));
  }
  return nonce;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const buildQuery = function(parameters: any) {
  return Object.keys(parameters).reduce(
    (accumulator, key) => `${accumulator + key}=${parameters[key]}&`,
    "?"
  );
};

const buildSSOUrl = function(): string {
  const queryParams = {
    response_type: "id_token",
    client_id: encodeURIComponent(window.location.host),
    redirect_uri: encodeURIComponent(window.location.href),
    scope: "openid",
    nonce: generateNonce()
  };

  return `https://midway-auth.amazon.com/SSO${buildQuery(queryParams)}`;
};

const buildRedirectUrl = function(): string {
  const queryParams = {
    client_id: encodeURIComponent(window.location.host),
    redirect_uri: encodeURIComponent(window.location.href),
    response_type: "id_token",
    scope: "openid",
    nonce: generateNonce()
  };

  return `https://midway-auth.amazon.com/login?next=/SSO/redirect${encodeURIComponent(
    buildQuery(queryParams)
  )}`;
};

export const getTokenOrRedirect = async function(): Promise<string> {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.open("GET", buildSSOUrl());
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.responseText);
      } else {
        window.location.assign(buildRedirectUrl());
      }
    };
    xhr.send();
  });
};
