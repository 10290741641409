import { Reducer } from 'redux';

import { AppActionTypes, AppState } from './types';
const initialState: AppState = {
    isInitializing: true,
    errored: false,
    showNewExperience: false
};

export const AppReducer: Reducer<AppState> = (state: AppState = initialState, action) => {
    switch ((action as AppActionTypes).type) {
        case '@@APP/UPDATE_APP_STATE':
            return { ...state, ...action.payload };
        default:
            return state;
    }
};
