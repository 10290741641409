import { createSlice } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { flatMap } from 'lodash';

import {
    BulkApprovalHistorySnapShot,
    BulkApprovalSnapShot,
    CutoffConfigList,
    DateRangeTypeValueEnum,
    ListBulkApprovalHistoryInputV2,
    ListBulkApprovalHistoryOutputV2,
    ListBulkApprovalInputV2,
    ListBulkApprovalOutputV2,
    PlaceBulkApprovalInputV2,
    PlaceBulkApprovalOutputV2,
    SendBulkApprovalReportInputV2,
    SendBulkApprovalReportOutputV2
} from '@amzn/digital-vendor-payments-gateway-client';

import {
    callCutoffConfig,
    callListBulkApprovalHistoryV2,
    callListBulkApprovalV2,
    callPlaceBulkApprovalV2,
    callSendBulkApprovalReportV2
} from '@app/apis/bulkSnapshotApproval';
import { callApprovalsSummaryApi } from '@app/apis/summary';
import { ALL_MARKETPLACE, Month } from '@app/constants';
import { getCurrentMonthAndYear } from '@app/util/date_util';

import {
    hidePlaceBulkApprovalModalReducer,
    hideSendBulkApprovalReportModalReducer,
    showPlaceBulkApprovalModalReducer,
    showSendBulkApprovalReportModalReducer,
    updateAvailableBusinessesReducer,
    updateAvailableMarketPlacesReducer,
    updateBusinessReducer,
    updateDatePeriodTypeReducer,
    updateMarketPlaceReducer,
    updateMonthReducer,
    updateMultipleFiltersReducer,
    updateYearReducer
} from './reducer';
import { ApprovalsSummary, BulkSnapShotApprovalState } from './types';

const getInitialState = (): BulkSnapShotApprovalState => {
    const { currentMonth, currentYear } = getCurrentMonthAndYear();

    return {
        filters: {
            month: currentMonth as Month,
            year: currentYear,
            business: '',
            marketPlace: ALL_MARKETPLACE,
            datePeriodType: DateRangeTypeValueEnum.EARNING_PERIOD
        },
        businesses: [],
        marketPlaces: [],
        sendBulkApprovalReport: { showModal: false },
        placeBulkApproval: { showModal: false, snapshots: [] }
    };
};

const bulkSnapshotApprovalSlice = createSlice({
    name: 'bulkSnapshot',
    initialState: getInitialState(),
    reducers: {
        updateMonth: updateMonthReducer,
        updateYear: updateYearReducer,
        updateBusiness: updateBusinessReducer,
        updateMarketPlace: updateMarketPlaceReducer,
        updateDatePeriodType: updateDatePeriodTypeReducer,
        updateMultipleFilters: updateMultipleFiltersReducer,
        updateAvailableBusinesses: updateAvailableBusinessesReducer,
        updateAvailableMarketPlaces: updateAvailableMarketPlacesReducer,
        showSendBulkApprovalReportModal: showSendBulkApprovalReportModalReducer,
        hideSendBulkApprovalReportModal: hideSendBulkApprovalReportModalReducer,
        showPlaceBulkApprovalModal: showPlaceBulkApprovalModalReducer,
        hidePlaceBulkApprovalModal: hidePlaceBulkApprovalModalReducer
    }
});

export const bulkSnapshotApprovalApi = createApi({
    /**
     * Note: Using queryFn in endpoints overrides the baseUrl. Hence any value can be specified as baseUrl
     * Reference: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#customizing-queries-with-queryfn
     */
    baseQuery: fetchBaseQuery({ baseUrl: '/xyz' }),
    tagTypes: [
        'ListBulkApprovalV2',
        'ListBulkApprovalHistoryV2',
        'ApprovalsSummary',
        'CutoffConfig'
    ],
    reducerPath: 'bulkSnapshotApprovalApi',
    endpoints: build => ({
        listBulkApprovalV2: build.query<ListBulkApprovalOutputV2[], ListBulkApprovalInputV2>({
            queryFn: async (input, { dispatch, getState }) => {
                try {
                    return { data: await callListBulkApprovalV2(input, dispatch, getState) };
                } catch (err: any) {
                    return { error: err };
                }
            },
            providesTags: [{ type: 'ListBulkApprovalV2', id: 'LIST' }]
        }),
        sendBulkApprovalReportV2: build.mutation<
            SendBulkApprovalReportOutputV2,
            SendBulkApprovalReportInputV2
        >({
            queryFn: async input => {
                try {
                    return { data: await callSendBulkApprovalReportV2(input) };
                } catch (err: any) {
                    return { error: err };
                }
            }
        }),
        placeBulkApprovalV2: build.mutation<PlaceBulkApprovalOutputV2, PlaceBulkApprovalInputV2>({
            queryFn: async input => {
                try {
                    return { data: await callPlaceBulkApprovalV2(input) };
                } catch (err: any) {
                    return { error: err };
                }
            }
        }),
        listBulkApprovalHistoryV2: build.query<
            ListBulkApprovalHistoryOutputV2[],
            ListBulkApprovalHistoryInputV2
        >({
            queryFn: async (input, { dispatch, getState }) => {
                try {
                    return { data: await callListBulkApprovalHistoryV2(input, dispatch, getState) };
                } catch (err: any) {
                    return { error: err };
                }
            },
            providesTags: [{ type: 'ListBulkApprovalHistoryV2', id: 'LIST' }]
        }),
        approvalsSummary: build.query<
            ApprovalsSummary[],
            {
                currPending: ListBulkApprovalOutputV2[];
                currHistory: ListBulkApprovalHistoryOutputV2[];
                prevPending: ListBulkApprovalOutputV2[];
                prevHistory: ListBulkApprovalHistoryOutputV2[];
                business: string;
                marketplaceId: number;
            }
        >({
            queryFn: ({
                currPending,
                prevPending,
                currHistory,
                prevHistory,
                business,
                marketplaceId
            }) => {
                return {
                    data: callApprovalsSummaryApi(
                        getSnapshots(currPending),
                        getHistorySnapshots(currHistory),
                        getSnapshots(prevPending),
                        getHistorySnapshots(prevHistory),
                        business,
                        marketplaceId
                    )
                };
            },
            providesTags: [{ type: 'ApprovalsSummary', id: 'LIST' }]
        }),
        cutoffConfig: build.query<CutoffConfigList, void>({
            queryFn: async () => {
                try {
                    return { data: await callCutoffConfig() };
                } catch (err: any) {
                    return { error: err };
                }
            },
            providesTags: [{ type: 'CutoffConfig', id: 'LIST' }]
        })
    })
});

const getSnapshots = (outputs: ListBulkApprovalOutputV2[]): BulkApprovalSnapShot[] => {
    return flatMap(outputs, ({ bulkApprovalSnapShots }) => bulkApprovalSnapShots);
};

const getHistorySnapshots = (
    outputs: ListBulkApprovalHistoryOutputV2[]
): BulkApprovalHistorySnapShot[] => {
    return flatMap(outputs, ({ bulkApprovalHistorySnapShots }) => bulkApprovalHistorySnapShots);
};

export const BulkSnapShotReducer = bulkSnapshotApprovalSlice.reducer;

export const {
    updateBusiness,
    updateDatePeriodType,
    updateMarketPlace,
    updateMonth,
    updateYear,
    updateMultipleFilters,
    updateAvailableBusinesses,
    updateAvailableMarketPlaces,
    showSendBulkApprovalReportModal,
    hideSendBulkApprovalReportModal,
    showPlaceBulkApprovalModal,
    hidePlaceBulkApprovalModal
} = bulkSnapshotApprovalSlice.actions;

export const {
    useListBulkApprovalV2Query,
    useSendBulkApprovalReportV2Mutation,
    usePlaceBulkApprovalV2Mutation,
    useListBulkApprovalHistoryV2Query,
    useApprovalsSummaryQuery,
    useLazyCutoffConfigQuery
} = bulkSnapshotApprovalApi;
