import {tApproval, tInputGroup} from "src/reducers/bulk_approval/types";
import {digitalVendorPaymentGateway} from "src/services/digital-payment-gateway-client";
import {
  approvalPhases,
  BulkApprovalRequestList,
  DateRangeTypeValueEnum,
  DateTypeAttributeEnum,
  DisbursementStatusFilterEnum,
  ListBulkApprovalInput,
  ListBulkApprovalOutput,
  PlaceBulkApprovalInput,
  PlaceBulkApprovalOutput,
  SendBulkApprovalReportInput,
  SendBulkApprovalReportOutput,
} from "@amzn/digital-vendor-payments-gateway-client";

export const listBulkApprovals = async ({
  date_range,
  business,
  marketplace,
  date_range_type,
  disbursement_status_filter
}: any): Promise<ListBulkApprovalOutput> => {
  const startDate = Date.parse(date_range[0]) / 1000;
  const endDate = Date.parse(date_range[1]) / 1000;
  const input: ListBulkApprovalInput = {
    paymentStartDate: startDate,
    paymentEndDate: endDate,
  };
  if (business) {
    input.business = business;
  }
  if (marketplace) {
    input.marketplaceId = marketplace;
  }
  if (date_range_type) {
    input.dateRangeType = date_range_type;
  }
  if (disbursement_status_filter) {
    input.disbursementStatusFilter = disbursement_status_filter;
  }
  return digitalVendorPaymentGateway.listBulkApproval(input);
};

export type tSumbitRequest = { payload: tApproval; phase: approvalPhases};

export type tSubmitDownloadBulkApprovalRequest = { payload: tApproval; bulkApprovalPageFilters:tInputGroup };

export const submitBulkApprovals = async ([
  request,
]: any): Promise<PlaceBulkApprovalOutput> => {
  const requests = request as tSumbitRequest[];
  const requestList: BulkApprovalRequestList = requests.map((request) => {
    const payload = request.payload.payload;
    return {
      vendorProgram: payload.business,
      paymentStartDate: payload.paymentStartDate,
      paymentEndDate: payload.paymentEndDate,
      marketplaceId: payload.marketplaceId,
      approvalPhase: request.phase,
      approvalType: payload.approvalType,
    };
  });
  const placeInput: PlaceBulkApprovalInput = {
    bulkApprovalRequestList: requestList,
  };
  return digitalVendorPaymentGateway.placeBulkApproval(placeInput);
};

export const sendBulkApprovalReport = async (request: any ):
    Promise<SendBulkApprovalReportOutput> => {
  const payload = request[0].payload.payload;
  const bulkApprovalPageFilters:tInputGroup = request[0].bulkApprovalPageFilters;
  const sendInput: SendBulkApprovalReportInput = {
    paymentStartDate: payload.paymentStartDate,
    paymentEndDate: payload.paymentEndDate,
    glProductGroup: payload.glProductGroup,
    dateType: DateTypeAttributeEnum.EFFECTIVE_DATE,
    marketplaceIds: [payload.marketplaceId.toString()],
    disbursementTypes: [payload.business],
    approvalType: payload.approvalType,
    //Currently irrespective of date range selected on UI , we are passing earning period to backend
    dateRangeType : DateRangeTypeValueEnum.EARNING_PERIOD,
  };
  if(bulkApprovalPageFilters.disb_status_filter != null && bulkApprovalPageFilters.disb_status_filter != '' ){
    sendInput.disbursementStatusFilter = bulkApprovalPageFilters.disb_status_filter as DisbursementStatusFilterEnum;
  }
  return digitalVendorPaymentGateway.sendBulkApprovalReport(sendInput);
};