import {
    tDisbursementSummaryInputGroup,
} from "src/reducers/disbursement_summary/types";
import {differenceInMonths, isFirstDayOfMonth, parseISO} from "date-fns";
import {getGls} from "src/components/disbursementsummary/DisbursementSummaryInputGroup";
import {getMonthNameFromMonthNumber, getMonthNumberStringFromMonthNumber} from "src/util/date_util";
import {DISBURSEMENTS_SUMMARY_MONTH_DROPDOWN_COUNT} from "../constants/app"

export const isValidDateForDisbursementSummary = (date: string) => {
        const inputDate = parseISO(date);
        const isFirst = isFirstDayOfMonth(inputDate);
        return isFirst;
};

export const isValidRangeForDisbursementSummary = (startDate: string, endDate: string) => {
    const end = parseISO(endDate);
    const difference = differenceInMonths(end, parseISO(startDate));
    return difference == 1;
};

export const isDateRangeWithinLastThreeMonths = (startDate: string, endDate: string) => {
    let currentMonthFirstDate: Date = new Date();
    currentMonthFirstDate.setDate(1);
    let fourthPreviousMonthFirstDate: Date = new Date();
    fourthPreviousMonthFirstDate.setDate(1);
    fourthPreviousMonthFirstDate.setMonth(fourthPreviousMonthFirstDate.getMonth() - 4);
    const queryStartDate = parseISO(startDate);
    const queryEndDate = parseISO(endDate);
    return (queryStartDate > fourthPreviousMonthFirstDate) && (queryEndDate < currentMonthFirstDate);
};

const getDisbSummaryEndDate = (inputDateString: String) => {
    const regex = /-/g;
    const dateDelimiter = '/';
    const inputDate : Date = new Date(inputDateString.replace(regex, dateDelimiter));
    let endDate = inputDate;
    //Set month: If value is value is greater than 11 then it will increase the year.
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/setMonth
    endDate.setMonth(inputDate.getMonth()+1); 
    const year = endDate.getFullYear();
    // 1) GetMonth: An integer number, between 0 and 11, representing the month in the given date according to local time. 
    // 0 corresponds to January, 1 to February, and so on. https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth
    // 2) Given month does not have prefix "0" for single digit. So adding prefix "0" and getting last 2 charc
    const month = ("0" + (endDate.getMonth() + 1)).slice(-2);
    const endDateString = `${year}-${month}-01`;
    return endDateString;
}


export const formQueryString = (input: tDisbursementSummaryInputGroup ) => {
    let queryString = "?";
    if (input.year && input.month){
        let currentMonthDateString:string = `${input.year}-${input.month}-01`;
        queryString += 'start_date='+currentMonthDateString+'&';
        queryString += 'end_date=' + getDisbSummaryEndDate(currentMonthDateString) +'&';
    }
    if (input.gl) {
        queryString += `gl=${input.gl}&`;
    }
    return queryString;
};

export const hasGlPermissions = () => {
    const gls = getGls();
    return gls.length > 0;
};

export const canAssignGl = (gl: string) => {
    let canAssignGlFlag : Boolean = false;
    getGls().forEach( a =>
        canAssignGlFlag = canAssignGlFlag || (a.glNum === gl)
    )
    return canAssignGlFlag;
};

export const getMonthDropDownValues = (currentDate: Date) => {
    const monthDropDownValues:{dropDownLabel:string , value:string}[] = [];
    const currentMonthNumber = currentDate.getMonth();
    for(let startMonthNumber: number = currentMonthNumber-DISBURSEMENTS_SUMMARY_MONTH_DROPDOWN_COUNT ; 
                startMonthNumber < currentMonthNumber ; startMonthNumber = startMonthNumber+1){
        let dropDownYear: number = (startMonthNumber<0 ? currentDate.getUTCFullYear()-1 : currentDate.getUTCFullYear());
        let dropDownMonth: number = (startMonthNumber+12)%12;
        monthDropDownValues.push(
            {
                dropDownLabel: getMonthNameFromMonthNumber(dropDownMonth) + ' ' +  dropDownYear,
                value: dropDownYear + ' ' + getMonthNumberStringFromMonthNumber(dropDownMonth)
            });
    }
    return monthDropDownValues;
}
