/**
 * __webpack_public_path__ allows changing the public path at runtime.
 *
 * See https://webpack.js.org/guides/public-path/#on-the-fly
 *
 **/

// @ts-ignore
__webpack_public_path__ = '/';

export {};