import DateRangePicker from "@amzn/meridian/date-range-picker";
import Icon from "@amzn/meridian/icon";
import Row from "@amzn/meridian/row";
import Select, { SelectOption } from "@amzn/meridian/select";
import Tooltip from "@amzn/meridian/tooltip";
import differenceInMonths from "date-fns/differenceInMonths";
import parseISO from "date-fns/parseISO";
import _ from "lodash";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserClaims } from "src/authorization/token_handler";
import { marketplaceToCC } from "src/constants/marketplace";
import { RootState } from "src/reducers";
import { updateInput } from "src/reducers/bulk_approval/actions";
import { InputGroupKeys } from "src/reducers/bulk_approval/types";
// @ts-ignore
import {
  DateRangeTypeValueEnum,
  DisbursementStatusFilterEnum
} from "@amzn/digital-vendor-payments-gateway-client";
import infoKnockoutTokens from "@amzn/meridian-tokens/base/icon/info-knockout";
import Column from "@amzn/meridian/column";
import { useHistory } from "react-router-dom";
import { formQueryString } from "src/helpers/bulk_approval";
import {
  isValidDate,
  isValidRange
} from "src/validators/bulk_approval";

export const getBusiness = () =>
  _.uniq(
    fetchUserClaims()?.bulk_approval.view.map(
      (viewAttribute) => viewAttribute.vp
    )
  ).sort();

export const getMarketplaces = () =>
  _.uniq(
    fetchUserClaims()?.bulk_approval.view.map(
      (viewAttribute) => viewAttribute.mp
    )
  ).sort();

export const getInitalDateRangeType = () => {
  return DateRangeTypeValueEnum.EARNING_PERIOD;
}

/**
 * Component which renders input elements for bulk approval
 * and updates the input values in the state
 */
export const InputGroup: React.FunctionComponent = () => {
  /**
   * useDispatch() is a hook provided by redux which replaces 
   * the function mapDispatchToProps(), 
   * for more info: https://react-redux.js.org/api/hooks
   */
  const dispatch = useDispatch();
  const bulkApprovalInputSelector = (state: RootState) =>
    state.bulk_approval.inputGroup;
  const bulkApprovalInput = useSelector(bulkApprovalInputSelector);
  const businesses = getBusiness();
  const history = useHistory();

  const updateQueryParam = () => {
    const queryString = formQueryString(bulkApprovalInput);
    history.push({
      search: queryString,
    });
  };

  /**
   * Use effect is another hook provided by react
   * https://reactjs.org/docs/hooks-effect.html
   */
  useEffect(() => {
    const dateRange = bulkApprovalInput.date_range;
    if (dateRange[0] && dateRange[1]) {
      updateQueryParam();
    }
  }, [bulkApprovalInput]);

  const updateBulkApprovalInput = (
    key: InputGroupKeys,
    value: string | (string | undefined)[]
  ) => {
    dispatch(updateInput(key, value));
  };  

  const isDisabledDate = useCallback((date, startDate) => {
    const validDate = isValidDate(date);
    const validRange = isValidRange(date, startDate);
    return !validDate || !validRange;
  }, []);

  const dateChangeHandler = (val: string[]) => {
    if (differenceInMonths(parseISO(val[1]), parseISO(val[0])) > 3) {
      updateBulkApprovalInput("date_range", [val[0], undefined]);
    } else {
      updateBulkApprovalInput("date_range", val);
    }
  };

  return (
    <Row
      spacingInset="400"
      alignmentVertical="stretch"
      wrap="down"
      width="100%"
      widths={["grid-4", "grid-4", "grid-4"]}
    >
      <Row
        spacingInset="none"
        alignmentVertical="stretch"
        wrap="down"
        width="100%"
        widths={["5%", "95%"]}
      >
        <Column heights="fill" alignmentVertical="center">
          <Tooltip
            position="bottom"
            title="Date range consisting of only 3 months is allowed"
          >
            <Icon tokens={infoKnockoutTokens} />
          </Tooltip>
        </Column>
        <DateRangePicker
          value={bulkApprovalInput.date_range}
          onChange={dateChangeHandler}
          disabledDates={(date) =>
            isDisabledDate(date, bulkApprovalInput.date_range[0])
          }
          startLabel="Start date"
          endLabel="End date"
          monthsInView={1}
          locale="en-CA"
          size="xlarge"
        ></DateRangePicker>
      </Row>
      <Row
        spacingInset="none"
        alignmentHorizontal="start"
        width="100%"
        widths={["grid-6","grid-6"]}
      >
        <Select
          value={bulkApprovalInput.business}
          onChange={(val) => {
            updateBulkApprovalInput("business", val);
          }}
          placeholder="Business"
          width={300}
          label="Business"
          id="Business"
          size="xlarge">
          {businesses.map((item, index) => (
            <SelectOption key={index} value={item} label={item} />
          ))}
        </Select>
        <Select
          value={bulkApprovalInput.marketplace}
          onChange={(val) => {
            updateBulkApprovalInput("marketplace", val);
          }}
          placeholder="Marketplace"
          width={300}
          label="Marketplace"
          id="Marketplace"
          size="xlarge">
          <SelectOption key={999} value={0} label="ALL" />
          {getMarketplaces().map((item, index) => (
              // Render the SelectOption only if marketplace is defined.
              // Without this check, SelectOption will throw label undefined error.
              marketplaceToCC[item] && <SelectOption
                  key={index}
                  value={item}
                  label={marketplaceToCC[item]}
              />
          ))}
        </Select>
      </Row>
      <Row
          spacingInset="none"
          alignmentVertical="stretch"
          wrap="down"
          width="100%"
          widths={["grid-6", "grid-6"]}
        >
          <Row
            spacingInset="none"
            alignmentVertical="stretch"
            wrap="down"
            width="100%"
            widths={["10%", "90%"]}
          >
            <Column heights="fill" alignmentVertical="center">
              <Tooltip
                position="bottom"
                title="##Earning Period - Is the month that the vendor is paid for.

                 ##Effective Date - This date is set during finalization and VDMS uses it to 
                 identify when a bucket has to be finalised. For Example Earning period for
                 march disbursements would be created in April."
              >
                <Icon tokens={infoKnockoutTokens} />
              </Tooltip>
            </Column>
            <Select
              value={bulkApprovalInput.date_range_type}
              onChange={(val) => {
                updateBulkApprovalInput("date_range_type", val);
              }}
              label="Search Type"
              id="Search Type"
              size="xlarge">
              {Object.keys(DateRangeTypeValueEnum).map((item, index) => (
                <SelectOption
                  key={index} // Each child in selectOption should have a unique Key
                  value={item}
                  label={item}
                />
              ))}
            </Select>
          </Row>
          <Row
            spacingInset="none"
            alignmentVertical="stretch"
            wrap="down"
            width="100%"
            widths={["10%", "90%"]}
          >
          <Column heights="fill" alignmentVertical="center">
            <Tooltip
              position="bottom"
              title="Use the Disbursement status filter to filter
              the total amount and number of payments"
            >
              <Icon tokens={infoKnockoutTokens} />
            </Tooltip>
          </Column>
          <Select
            value={bulkApprovalInput.disb_status_filter}
            onChange={(val) => {
              updateBulkApprovalInput("disb_status_filter", val);
            }}
            label="Disbursement Status"
            id="Disbursement Status"
            size="xlarge">
            {Object.keys(DisbursementStatusFilterEnum).map((item, index) => (
              <SelectOption
                key={index}
                value={item}
                label={item}
              />
            ))}
          </Select>
          </Row>
        </Row>
    </Row>
  );
};
