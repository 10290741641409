import { tDomains, tRegions } from 'src/constants/app';
import { APPLICATION_URLS } from 'src/constants/urls';
import { PAYMENT_APPROVAL_SERVICE } from 'src/services/digital-payment-gateway-client';
export interface IAmplifyConfigs {
	COGNITO: {
		IDENTITY_POOL_ID: string;
	};
}

type AWSRegions = 'us-east-1' | 'us-west-2' | 'ap-southeast-1' | 'eu-west-1' | 'cn-north-1';

type ServiceEndpoint = {
	name: string;
	endpoint: string;
	region: AWSRegions;
};

export type RumConfig = {
	guestRoleArn: string;
	identityPoolId: string;
	endpoint: string;
	applicationId: string;
};

export interface IAppStageConfig {
	AMPLIFY: IAmplifyConfigs;
	STAGE: tDomains;
	AWS_REGION: AWSRegions;
	REALM: tRegions;
	SERVICE_ENDPOINTS: Array<ServiceEndpoint>;
	DAPR_ENDPOINT: string;
	CATS_ENDPOINT: string;
	RUM_CONFIG: RumConfig | null;
}

const alphaConfig: IAppStageConfig = {
	AMPLIFY: {
		COGNITO: {
			IDENTITY_POOL_ID: 'us-east-1:3ccc4782-3a3f-4292-b4b6-d67f738fe5e0'
		}
	},
	AWS_REGION: 'us-east-1',
	CATS_ENDPOINT: '',
	STAGE: 'beta',
	REALM: 'NA',
	SERVICE_ENDPOINTS: [
		{
			name: PAYMENT_APPROVAL_SERVICE,
			endpoint: 'https://lq64nkwsef.execute-api.us-east-1.amazonaws.com/beta',
			region: 'us-east-1'
		}
	],
	DAPR_ENDPOINT: 'https://dapr.integ.amazon.com/dvps/listDisbursements.jsp',
    RUM_CONFIG: {
		guestRoleArn: 'arn:aws:iam::619791108054:role/RUM-Monitor-us-east-1-619791108054-2379470200861-Unauth',
		identityPoolId: 'us-east-1:39eda72d-fe67-4c95-9cd9-a67f19e93baa',
		endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
		applicationId: 'd4cfb1c1-bbf8-409b-b9a3-a0169f089a74'
    }
};

export const stageConfig: Record<string, IAppStageConfig> = {
	'https://caspian-payments.a2z.com:4231': alphaConfig,
	[APPLICATION_URLS.beta.NA]: {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'us-east-1:3ccc4782-3a3f-4292-b4b6-d67f738fe5e0'
			}
		},
		AWS_REGION: 'us-east-1',
		CATS_ENDPOINT: '',
		STAGE: 'beta',
		REALM: 'NA',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://lq64nkwsef.execute-api.us-east-1.amazonaws.com/beta',
				region: 'us-east-1'
			}
		],
		DAPR_ENDPOINT: 'https://dapr.integ.amazon.com/dvps/listDisbursements.jsp',
        RUM_CONFIG: {
            guestRoleArn: 'arn:aws:iam::619791108054:role/DigitalVendorPaymentsDashboardWe-CPATRumClientRole-1PJK968UHDK57',
            identityPoolId: 'us-east-1:68aafb26-73e1-4a8b-a38b-6e40fdc241d9',
            endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
            applicationId: '23620218-0cb7-4c56-bab4-f2854c43c848'
        }
	},
	'https://alvihamz.caspian-payments-fe.royalties.digital.amazon.dev': {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'us-west-2:04bb2f04-4a02-4355-bcd5-ab2d8fd10867'
			}
		},
		STAGE: 'beta',
		AWS_REGION: 'us-west-2',
		REALM: 'FE',
		CATS_ENDPOINT: '',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://alvihamz.caspian-payments-fe.royalties.digital.amazon.dev/beta',
				region: 'us-west-2'
			}
		],
		DAPR_ENDPOINT: 'https://dapr-fe.integ.amazon.com/dvps/listDisbursements.jsp',
		RUM_CONFIG: {
			guestRoleArn: 'arn:aws:iam::602795633681:role/alvihamz-web-CPATRumClientRole-I59W5ZXG9ZKG',
			identityPoolId: 'us-west-2:274352cc-eb92-4958-834d-0331daaf2336',
			endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
			applicationId: 'bcbfc378-2287-46fd-a347-cb9df93d60c6'
		}
	},
	'https://alvihamz.caspian-payments-eu.royalties.digital.amazon.dev': {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'eu-west-1:86f85c6f-ecd4-490f-b289-b45dccd28806'
			}
		},
		AWS_REGION: 'eu-west-1',
		CATS_ENDPOINT: '',
		STAGE: 'beta',
		REALM: 'EU',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://obgefgrne1.execute-api.eu-west-1.amazonaws.com/beta',
				region: 'eu-west-1'
			}
		],
		DAPR_ENDPOINT: 'https://dapr-eu.integ.amazon.com/dvps/listDisbursements.jsp',
		RUM_CONFIG: {
			guestRoleArn: 'arn:aws:iam::602795633681:role/alvihamz-web-CPATRumClientRole-1F62VJMH2FUD',
			identityPoolId: 'eu-west-1:a210e3bc-9ce9-4e2d-b47f-d3cfff7a15bc',
			endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
			applicationId: '8a06a611-4af5-4a03-a4c0-92604713615c'
		}
	},
	'https://alvihamz.caspian-payments-cn.royalties.digital.amazon.dev': {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'cn-north-1:e1701408-63c4-4c32-8568-6c2fe17ded2a'
			}
		},
		AWS_REGION: 'cn-north-1',
		CATS_ENDPOINT: '',
		STAGE: 'beta',
		REALM: 'CN',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://hgj4btes5h.execute-api.cn-north-1.amazonaws.com.cn/beta',
				region: 'cn-north-1'
			}
		],
		DAPR_ENDPOINT: 'https://dapr-cn.integ.amazon.com/dvps/listDisbursements.jsp',
		RUM_CONFIG: {
			guestRoleArn: "arn:aws:iam::619791108054:role/DigitalVendorPaymentsDashboardWe-CPATRumClientRole-S87WIX3RH8QB",
			identityPoolId: "ap-southeast-1:719ff672-98e6-49d6-b3dc-dd17a2d7f9b4",
			endpoint: "https://dataplane.rum.ap-southeast-1.amazonaws.com",
			applicationId: 'bbc1711e-bba9-457d-b6dc-206f17b2c871'
		}
	},
	[APPLICATION_URLS.beta.FE]: {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'us-west-2:ee84bc91-284c-4727-9a0d-4beeca5a1c1b'
			}
		},
		AWS_REGION: 'us-west-2',
		CATS_ENDPOINT: '',
		STAGE: 'beta',
		REALM: 'FE',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://4mi5molxu1.execute-api.us-west-2.amazonaws.com/beta',
				region: 'us-west-2'
			}
		],
		DAPR_ENDPOINT: 'https://dapr-fe.integ.amazon.com/dvps/listDisbursements.jsp',
        RUM_CONFIG: {
            guestRoleArn: 'arn:aws:iam::619791108054:role/DigitalVendorPaymentsDashboardWe-CPATRumClientRole-SUOVMXMO9TPW',
            identityPoolId: 'us-west-2:c00d0843-5a8d-4479-80e9-89b8a1e84f12',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            applicationId: 'bcbfc378-2287-46fd-a347-cb9df93d60c6'
        }
	},
	[APPLICATION_URLS.beta.EU]: {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'eu-west-1:3bcda954-479f-48ee-9914-76151e85a4ee'
			}
		},
		AWS_REGION: 'eu-west-1',
		CATS_ENDPOINT: '',
		STAGE: 'beta',
		REALM: 'EU',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://obgefgrne1.execute-api.eu-west-1.amazonaws.com/beta',
				region: 'eu-west-1'
			}
		],
		DAPR_ENDPOINT: 'https://dapr-eu.integ.amazon.com/dvps/listDisbursements.jsp',
        RUM_CONFIG: {
            guestRoleArn: 'arn:aws:iam::619791108054:role/DigitalVendorPaymentsDashboardWe-CPATRumClientRole-1IOUIIFWZH3GR',
            identityPoolId: 'eu-west-1:78422c1c-e93f-4ba8-b8d2-e2c0e39d91f4',
            endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
            applicationId: '2409a8d5-7bb2-4780-ab2e-b19b7fbbc71c'
        }
	},
	[APPLICATION_URLS.beta.CN]: {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'cn-north-1:e1701408-63c4-4c32-8568-6c2fe17ded2a'
			}
		},
		AWS_REGION: 'cn-north-1',
		CATS_ENDPOINT: '',
		STAGE: 'beta',
		REALM: 'CN',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://hgj4btes5h.execute-api.cn-north-1.amazonaws.com.cn/beta',
				region: 'cn-north-1'
			}
		],
		DAPR_ENDPOINT: 'https://dapr-cn.integ.amazon.com/dvps/listDisbursements.jsp',
        RUM_CONFIG: {
			guestRoleArn: "arn:aws:iam::619791108054:role/DigitalVendorPaymentsDashboardWe-CPATRumClientRole-S87WIX3RH8QB",
			identityPoolId: "ap-southeast-1:719ff672-98e6-49d6-b3dc-dd17a2d7f9b4",
			endpoint: "https://dataplane.rum.ap-southeast-1.amazonaws.com",
			applicationId: 'bbc1711e-bba9-457d-b6dc-206f17b2c871'
		}
	},
	[APPLICATION_URLS.prod.NA]: {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'us-east-1:bf65e507-7e54-42a4-b240-add3710db447'
			}
		},
		AWS_REGION: 'us-east-1',
		CATS_ENDPOINT: '',
		STAGE: 'prod',
		REALM: 'NA',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://pj6gtkl85l.execute-api.us-east-1.amazonaws.com/prod-na',
				region: 'us-east-1'
			}
		],
		DAPR_ENDPOINT: 'https://dapr.amazon.com/dvps/listDisbursements.jsp',
        RUM_CONFIG: {
            guestRoleArn: 'arn:aws:iam::479238083108:role/DigitalVendorPaymentsDashboardWe-CPATRumClientRole-VFWL09HALL7V',
            identityPoolId: 'us-east-1:667d317f-00c5-42b1-943d-e5f0f733fa7a',
            endpoint: 'https://dataplane.rum.us-east-1.amazonaws.com',
            applicationId: '7ff5858a-77c0-453f-a979-857398a80c75'
        }
	},
	[APPLICATION_URLS.prod.FE]: {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'us-west-2:044fcbc7-44e9-4929-955a-5602598bb0db'
			}
		},
		AWS_REGION: 'us-west-2',
		CATS_ENDPOINT: '',
		STAGE: 'prod',
		REALM: 'FE',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://o872vr7lrf.execute-api.us-west-2.amazonaws.com/prod-fe',
				region: 'us-west-2'
			}
		],
		DAPR_ENDPOINT: 'https://dapr-fe.amazon.com/dvps/listDisbursements.jsp',
        RUM_CONFIG: {
            guestRoleArn: 'arn:aws:iam::479238083108:role/DigitalVendorPaymentsDashboardWe-CPATRumClientRole-V03EGNRHIREE',
            identityPoolId: 'us-west-2:cb7619e9-df1e-461e-873b-196b1fdaa04b',
            endpoint: 'https://dataplane.rum.us-west-2.amazonaws.com',
            applicationId: '80ccd3cc-dd76-468a-a36c-4a9ab8267ae2'
        }
	},
	[APPLICATION_URLS.prod.EU]: {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'eu-west-1:f37a1c77-5814-4dec-adc5-8a9b180449bc'
			}
		},
		AWS_REGION: 'eu-west-1',
		CATS_ENDPOINT: '',
		STAGE: 'prod',
		REALM: 'EU',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://bvic3vlixl.execute-api.eu-west-1.amazonaws.com/prod-eu',
				region: 'eu-west-1'
			}
		],
		DAPR_ENDPOINT: 'https://dapr-eu.amazon.com/dvps/listDisbursements.jsp',
        RUM_CONFIG: {
            guestRoleArn: 'arn:aws:iam::479238083108:role/DigitalVendorPaymentsDashboardWe-CPATRumClientRole-15LHOFWBYD23Y',
            identityPoolId: 'eu-west-1:49f700e9-9a84-4922-8e09-bd5267b287c4',
            endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
            applicationId: '8a06a611-4af5-4a03-a4c0-92604713615c'
        }
	},
	[APPLICATION_URLS.prod.CN]: {
		AMPLIFY: {
			COGNITO: {
				IDENTITY_POOL_ID: 'cn-north-1:a4fcb495-768d-4bb5-b4f0-a29523bec1a1'
			}
		},
		AWS_REGION: 'cn-north-1',
		CATS_ENDPOINT: '',
		STAGE: 'prod',
		REALM: 'CN',
		SERVICE_ENDPOINTS: [
			{
				name: PAYMENT_APPROVAL_SERVICE,
				endpoint: 'https://v91dox2md9.execute-api.cn-north-1.amazonaws.com.cn/prod-cn',
				region: 'cn-north-1'
			}
		],
		DAPR_ENDPOINT: 'https://dapr-cn.amazon.com/dvps/listDisbursements.jsp',
        RUM_CONFIG: {
			guestRoleArn: "arn:aws:iam::479238083108:role/DigitalVendorPaymentsDashboardWe-CPATRumClientRole-17RVPJYZSVL2L",
			identityPoolId: "ap-southeast-1:827dfaac-d0b5-4a6b-be64-c5cd54fefdb2",
			endpoint: "https://dataplane.rum.ap-southeast-1.amazonaws.com",
			applicationId: '95f75b8d-d959-43b3-b5d9-20fc4e6d1a1d'
		}
	}
};

export const getCurrentStageConfig = (domain?: string): IAppStageConfig => {
	let config: IAppStageConfig;
	console.log(domain);
	console.log(window.location.origin);
	if (domain) {
		config = stageConfig[domain];
	} else {
		config = stageConfig[window.location.origin];
	}
	if (!config) {
		throw 'Error Initialising Stage Config';
	}
	return config;
};
