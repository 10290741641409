import { join } from 'lodash';
import React, { memo } from 'react';

import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';

interface Props {
	hasAllSodViolations: boolean;
	marketplaces: string[];
}

const AlertSodViolation: React.FunctionComponent<Props> = ({
	hasAllSodViolations,
	marketplaces
}) => {
	const formattedMarketplaces = hasAllSodViolations
		? ''
		: `for ${join(marketplaces, ', ')} marketplace(s) `;

	return (
		<Row alignmentHorizontal='center' spacingInset='500'>
			<Column heights='fill' alignmentHorizontal='center'>
				<Alert size='large' type='informational'>
					<Text type='b500'>
						{`Approval is disabled ${formattedMarketplaces}because of a Segregation of Duties (SOD) violation. The same user cannot be part of both business and finance approval groups. Please remove yourself from one of the groups to approve.`}
					</Text>
				</Alert>
			</Column>
		</Row>
	);
};

export default memo(
	AlertSodViolation,
	(prevProps, nextProps) =>
		prevProps.hasAllSodViolations === nextProps.hasAllSodViolations &&
		prevProps.marketplaces === nextProps.marketplaces
);
