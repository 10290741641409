import { Cache } from "aws-amplify";
import { getTokenOrRedirect } from "./tokenRetriever";
import { FederatedResponse } from "@aws-amplify/auth/lib/types/Auth";

/*
 * Copy of a package we don't want to depend on:
 * https://code/packages/Westgabr-talent-appsync/blobs/49a68b75da0f752d0a24268e732ea9281008e702/--/src/index.js#L22
 * this removes query parameters which is used to removed the parameters that midway adds after a redirection
 */

export interface IMidwayUserData {
  username: string;
  name: string;
}

const deleteQueryParameter = function(key: string) {
  const queryParams = new URLSearchParams(window.location.search);

  if (!queryParams.get(key)) {
    return;
  }
  queryParams.delete(key);
  const newUrl = new URL(window.location.href);

  newUrl.search = queryParams.toString();
  window.history.replaceState({}, "", newUrl.toString());
};

const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (err) {
    console.error(`Error occured during parsing JWT ${err}`);
  }
};

const parseUser = function(token: string): IMidwayUserData {
  const parsedToken = parseJwt(token);

  return {
    username: parsedToken.sub,
    name: parsedToken.sub
  };
};

/**
 * This function fetches the midway token if
 * midway token is expired it will redirect the user to midway webpage
 * and gets midway token as response
 * @returns Promise<string> 
 */
export const getMidwayToken = async function(): Promise<string> {
  const cachedFederatedInfo = Cache.getItem("federatedInfo");
  const currentTimestamp = Date.now();
  let midwayToken: string;
  const oneSecond = 1000;

  if (
    cachedFederatedInfo &&
    currentTimestamp < cachedFederatedInfo.expires_at * oneSecond
  ) {
    midwayToken = cachedFederatedInfo.token;
  } else {
    midwayToken = await getTokenOrRedirect();
  }
  /**
   * Midway reponse back will contain the id_token in the URL
   * So delete it
   */
  deleteQueryParameter("id_token");
  deleteQueryParameter("state");
  return midwayToken;
};

export let refreshMidway = async function(): Promise<FederatedResponse> {
  const token = await getMidwayToken();
  const parsedToken = parseJwt(token);

  return {
    token,
    expires_at: parsedToken.exp
  };
};

export const getUsername = async function getUsername(
  data: FederatedResponse
): Promise<IMidwayUserData> {
  const token = data.token || Cache.getItem("federatedInfo").token;

  return parseUser(token);
};
