import React, { memo } from 'react';
import { CustomerApprovalStatusToolTip } from 'src/constants';

import { BulkApprovalHistorySnapShot } from '@amzn/digital-vendor-payments-gateway-client';
import Button from '@amzn/meridian/button';
import Link from '@amzn/meridian/link';
import { TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';

import {
    formatDateAsString,
    getApprovalName,
    getCustomerApprovalStatus,
    getMarketPlaceFromId
} from '@app/helpers';

import {
    AmountCountCell,
    AmountSummaryCell,
    BusinessCell,
    DueDateCell,
    EarningsPeriodCell
} from '../cells';

interface Props {
    historySnapshot: BulkApprovalHistorySnapShot;
}

const ApprovalsHistoryTableRow: React.FunctionComponent<Props> = ({
    historySnapshot: { bulkApprovalSnapshot, bulkApprovalStatus }
}) => {
    const { snapShotId, disbursementType, paymentStartDate, paymentEndDate, amountSummary } =
        bulkApprovalSnapshot;
    const { approvalType, glProductGroup, marketplaceId, approvalPhaseInfo, snapShotApprovalType } =
        bulkApprovalSnapshot;

    const approvalStatus = getCustomerApprovalStatus(bulkApprovalStatus?.approvalProcessStatus);

    return (
        <TableRow key={snapShotId} highlightOnHover>
            <BusinessCell disbursementType={disbursementType} approvalType={approvalType} />
            <TableCell alignmentHorizontal='center'>{glProductGroup}</TableCell>
            <TableCell alignmentHorizontal='center'>
                {getMarketPlaceFromId(Number(marketplaceId)).name}
            </TableCell>
            <EarningsPeriodCell
                paymentStartDate={paymentStartDate}
                paymentEndDate={paymentEndDate}
            />
            <AmountSummaryCell amountSummary={amountSummary} />
            <AmountCountCell amountSummary={amountSummary} />
            <DueDateCell approvalPhaseInfo={approvalPhaseInfo} showTag={false} />
            <TableCell alignmentHorizontal='center'>
                <Link
                    href={`https://phonetool.amazon.com/users/${approvalPhaseInfo.approver}`}
                    target='_blank'>
                    {approvalPhaseInfo.approver}
                </Link>
            </TableCell>
            <TableCell alignmentHorizontal='center'>
                {formatDateAsString(approvalPhaseInfo.approvalPlacementDate)}
            </TableCell>
            <TableCell alignmentHorizontal='center'>
                {getApprovalName(snapShotApprovalType)}
            </TableCell>
            <TableCell alignmentHorizontal='center'>
                <Tooltip position='end' title={CustomerApprovalStatusToolTip[approvalStatus]}>
                    <Link type='secondary'>
                        <Text>{approvalStatus}</Text>
                    </Link>
                </Tooltip>
            </TableCell>
            <TableCell alignmentHorizontal='center'>
                <Button
                    size='small'
                    href={bulkApprovalStatus?.reportFilePath}
                    target='_blank'
                    disabled={!bulkApprovalStatus?.reportFilePath}>
                    Download
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default memo(
    ApprovalsHistoryTableRow,
    (
        { historySnapshot: { bulkApprovalSnapshot: prevSnapshot, bulkApprovalStatus: prevStatus } },
        { historySnapshot: { bulkApprovalSnapshot: nextSnapshot, bulkApprovalStatus: nextStatus } }
    ) =>
        prevSnapshot.snapShotId === nextSnapshot.snapShotId &&
        prevSnapshot.approvalPhaseInfo.approver === nextSnapshot.approvalPhaseInfo.approver &&
        prevSnapshot.amountSummary === nextSnapshot.amountSummary &&
        prevStatus === nextStatus
);
