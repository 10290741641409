import { parseISO, isFirstDayOfMonth, differenceInMonths } from "date-fns";
import {
  getBusiness,
  getMarketplaces,
} from "src/components/bulkapproval/InputGroup";
import {
   ADDITIONAL_FILTERS_SUPPORTED_DISBURSEMENT_TYPES 
} from "src/constants/app";
import { optionalString } from "src/helpers/query_param";
import {
  DateRangeTypeValueEnum,
  DisbursementStatusFilterEnum
} from "@amzn/digital-vendor-payments-gateway-client"


export const isValidDate = (date: string) => {
  const now = new Date();
  const inputDate = parseISO(date);
  const isFirst = isFirstDayOfMonth(inputDate);
  const isGreaterThanNow = now > inputDate;
  return isGreaterThanNow && isFirst;
};

export const isValidDateRangeType = (dateRangeType: string) => {
  return dateRangeType in DateRangeTypeValueEnum;
}

export const isValidDisbursementStatusFilter = (disbStatus: string) => {
  return disbStatus in DisbursementStatusFilterEnum;
}

export const isValidRange = (endDate: string, startDate: string) => {
  const end = parseISO(endDate);
  const difference = differenceInMonths(end, parseISO(startDate));
  return difference < 4;
};

export const canAssignBusiness = (business: string) => {
  return getBusiness().includes(business);
};

export const canAssignMarketplace = (marketplace: string) => {
  return getMarketplaces().includes(parseInt(marketplace));
};
