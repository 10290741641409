// TODO: Remove the need for this mapping or fetch info from Compendium dynamically.
export const marketplaceToCC: Record<number, string> = {
	1: 'US',
	3: 'GB',
	4: 'DE',
	5: 'FR',
	6: 'JP',
	7: 'CA',
	44551: 'ES',
	123311: 'ES',
	35691: 'IT',
	101611: 'IT',
	3240: 'CN',
	526970: 'BR',
	142980: 'BR',
	771770: 'MX',
	181720: 'MX',
	111172: 'AU',
	12542: 'AU',
	44571: 'IN',
	123591: 'IN',
	330721: 'AUDIBLE_IN',
	285511: 'AUDIBLE_IN',
	91470: 'AUDIBLE_US',
	45680: 'AUDIBLE_US',
	321810: 'AUDIBLE_CA',
	1368090: 'AUDIBLE_CA',
	203492340: 'AUDIBLE_BR',
	1526484160: 'AUDIBLE_BR',
	12602: 'AUDIBLE_AU',
	111202: 'AUDIBLE_AU',
	12932: 'AUDIBLE_JP',
	121192: 'AUDIBLE_JP',
	113071: 'AUDIBLE_GB',
	36061: 'AUDIBLE_GB',
	113081: 'AUDIBLE_DE',
	36071: 'AUDIBLE_DE',
	175001: 'AUDIBLE_FR',
	100631: 'AUDIBLE_FR',
	285501: 'AUDIBLE_IT',
	330701: 'AUDIBLE_IT',
	140163351: 'AUDIBLE_ES',
	433716121: 'AUDIBLE_ES',
	328451: 'NL',
	260281: 'NL',
	338801: 'AE',
	314421: 'AE',
	338811: 'SA',
	314691: 'SA',
	623225021: 'EG',
	63384901: 'EG',
	1546015300: 'CO',
	333892530: 'CO',
	1367890: 'ROW_NA',
	316340: 'ROW_NA',
	331051: 'ROE_EU',
	306801: 'ROE_EU',
	106631: 'RESTAURANTS_UK',
	194721: 'RESTAURANTS_UK',
	223310: 'RESTAURANTS_US',
	97890: 'RESTAURANTS_US',
	386046071: 'RESTAURANTS_IN',
	139630111: 'RESTAURANTS_IN',
	712115121: 'PL',
	154936711: 'PL',
	704403121: 'SE',
	161450861: 'SE',
	679831071: 'BE',
	240547401: 'BE',

};

export type MarketPlace = {
	id: number;
	name: string;
};

export const ALL_MARKETPLACE: MarketPlace = { id: 0, name: 'ALL' };
