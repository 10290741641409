import React from 'react';
import RumClient from 'src/services/rum_client';

// Error Boundaries need to be components due to componentDidCatch not having a hook
class RumErrorBoundary extends React.Component {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: any, info: any) {
        this.setState(null);
        RumClient.recordError(error);
    }
    render() {
        return this.props.children;
    }
}
export default RumErrorBoundary;
