import React, { memo } from 'react';

import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import { TableCell, TableRow } from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';
import Tooltip from '@amzn/meridian/tooltip';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';

const ApprovalsSummaryHeaderRow: React.FunctionComponent = () => {
    return (
        <TableRow>
            <TableCell alignmentHorizontal='center' header>
                Business
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                GL
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Marketplace
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Earnings Period
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Due Date
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Business Approval
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Finance Approval <br />
                <Tooltip
                    position='end'
                    title={
                        <Text color='inverted'>
                            The number of payments shown under Financial Approval may sometimes not
                            match Business Approval. This is due to three reasons: <br /> (1) Some
                            payments part of this bucket have been approved in DAPR. <br />
                            (2) Some payments are still pending Business Approval. <br />
                            (3) The system may be processing some Business Approval payments and are
                            not available yet.
                        </Text>
                    }>
                    <Link type='secondary'>Why is the number of payments different?</Link>
                </Tooltip>
            </TableCell>
            <TableCell alignmentHorizontal='center' header>
                Email Report{' '}
                <Tooltip
                    position='end'
                    title='By clicking email, we will send you an email with a CSV report containing the payments mentioned'>
                    <Icon tokens={infoKnockoutTokens} />
                </Tooltip>
            </TableCell>
        </TableRow>
    );
};

export default memo(ApprovalsSummaryHeaderRow);
