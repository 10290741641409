import { Auth } from "aws-amplify";
import {
  getUsername,
  refreshMidway,
  IMidwayUserData
} from "./midwayTokenRetriever";
import { FederatedResponse } from "@aws-amplify/auth/lib/types/Auth";
import { AUTHENTICATION_PROVIDER } from "src/constants/app";

export const authenticateAmplify = async () => {
  try {
    const credentials: FederatedResponse = await refreshMidway();
    console.log(credentials);
    const user: IMidwayUserData = await getUsername(credentials);
    console.log(user);
    try {
      /*
       * currentAuthenticated can be used to check if a user is logged in when the page is loaded.
       * It will throw an error if there is no user logged in
       * https://aws-amplify.github.io/docs/js/authentication#retrieve-current-authenticated-user
       */
      console.log('currentAuthenticatedUser');
      await Auth.currentAuthenticatedUser();
    } catch (err) {
      await Auth.federatedSignIn(AUTHENTICATION_PROVIDER, credentials, user);
    }
    return user;
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(`Error occured during authentication ${err}`);
  }
};
