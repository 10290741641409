import { ThunkDispatch } from '@reduxjs/toolkit';

import {
    CutoffConfigList,
    ListBulkApprovalHistoryInputV2,
    ListBulkApprovalHistoryOutputV2,
    ListBulkApprovalInputV2,
    ListBulkApprovalOutputV2,
    PlaceBulkApprovalInputV2,
    PlaceBulkApprovalOutputV2,
    SendBulkApprovalReportInputV2,
    SendBulkApprovalReportOutputV2
} from '@amzn/digital-vendor-payments-gateway-client';

import { getAvailableMarketPlacesForBusiness, getQueryDataFromState } from '@app/helpers';
import { bulkSnapshotApprovalApi } from '@app/reducers';
import { digitalVendorPaymentGateway } from '@app/services';

export const callListBulkApprovalV2 = async (
    input: ListBulkApprovalInputV2,
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => unknown
): Promise<ListBulkApprovalOutputV2[]> => {
    if (input.marketplaceId !== 0) {
        // if we are loading a single marketplace
        return Promise.all([digitalVendorPaymentGateway.listBulkApprovalV2(input)]);
    }

    const marketPlaces = getAvailableMarketPlacesForBusiness(input.vendorProgram);

    const listBulkPromises: Promise<ListBulkApprovalOutputV2>[] = marketPlaces.map(
        async ({ id }: { id: number }) => {
            const mpInput: ListBulkApprovalInputV2 = {
                ...input,
                marketplaceId: id
            };
            const prevOutput = getQueryDataFromState({
                query: 'listBulkApprovalV2',
                input: mpInput,
                getState
            });

            if (prevOutput) {
                return prevOutput;
            }

            const output = await digitalVendorPaymentGateway.listBulkApprovalV2(mpInput);

            // update in-memory state for individual marketplace
            dispatch(
                bulkSnapshotApprovalApi.util.upsertQueryData('listBulkApprovalV2', mpInput, [
                    output
                ])
            );

            return output;
        }
    );

    return Promise.all(listBulkPromises);
};

export const callSendBulkApprovalReportV2 = async (
    input: SendBulkApprovalReportInputV2
): Promise<SendBulkApprovalReportOutputV2> => {
    return digitalVendorPaymentGateway.sendBulkApprovalReportV2(input);
};

export const callPlaceBulkApprovalV2 = async (
    input: PlaceBulkApprovalInputV2
): Promise<PlaceBulkApprovalOutputV2> => {
    return digitalVendorPaymentGateway.placeBulkApprovalV2(input);
};

export const callListBulkApprovalHistoryV2 = async (
    input: ListBulkApprovalHistoryInputV2,
    dispatch: ThunkDispatch<any, any, any>,
    getState: () => unknown
): Promise<ListBulkApprovalHistoryOutputV2[]> => {
    if (input.marketplaceId !== 0) {
        // if we are loading a single marketplace
        return Promise.all([digitalVendorPaymentGateway.listBulkApprovalHistoryV2(input)]);
    }

    const marketPlaces = getAvailableMarketPlacesForBusiness(input.vendorProgram);

    const listBulkHistoryPromises: Promise<ListBulkApprovalHistoryOutputV2>[] = marketPlaces.map(
        async ({ id }: { id: number }) => {
            const mpInput: ListBulkApprovalHistoryInputV2 = {
                ...input,
                marketplaceId: id
            };

            const prevOutput = getQueryDataFromState({
                query: 'listBulkApprovalHistoryV2',
                input: mpInput,
                getState
            });
            if (prevOutput) {
                return prevOutput;
            }

            const output = await digitalVendorPaymentGateway.listBulkApprovalHistoryV2(mpInput);

            // update in-memory state for individual marketplace
            dispatch(
                bulkSnapshotApprovalApi.util.upsertQueryData('listBulkApprovalHistoryV2', mpInput, [
                    output
                ])
            );
            return output;
        }
    );

    return Promise.all(listBulkHistoryPromises);
};

export const callCutoffConfig = async (): Promise<CutoffConfigList> => {
    return (await digitalVendorPaymentGateway.getCutoffConfig({})).cutoffConfigs;
};
