import { UserState, UPDATE_USER_DATA, UserActionTypes } from "./types";
const initialState: UserState = {
  username: ""
};
export function UserReducer(
  state = initialState,
  action: UserActionTypes
): UserState {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}
