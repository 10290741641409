import { chain, isEmpty } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import CollapsibleSection from 'src/components/common/CollapsibleSection';

import { DateRangeTypeValueEnum } from '@amzn/digital-vendor-payments-gateway-client';
import Table from '@amzn/meridian/table';

import { EmptyTableRow } from '@app/components/common';
import {
    ApprovalsSummary,
    bulkSnapshotApprovalApi,
    showSendBulkApprovalReportModal,
    useFiltersSelector
} from '@app/reducers';
import {
    getPaymentEndDate,
    getPaymentStartDate,
    getPrevMonthPaymentEndDate,
    getPrevMonthPaymentStartDate
} from '@app/util/date_util';

import ApprovalsSummaryHeaderRow from './ApprovalsSummaryHeaderRow';
import ApprovalsSummaryTableRow from './ApprovalsSummaryTableRow';

const COLUMN_SPAN_APPROVALS_SUMMARY = 8;

const ApprovalsSummaryTable: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { month, year, business, marketPlace, datePeriodType } = useFiltersSelector();

    const { currentData: currPendingApprovals } =
        bulkSnapshotApprovalApi.endpoints.listBulkApprovalV2.useQueryState({
            marketplaceId: marketPlace.id,
            vendorProgram: business,
            paymentStartDate: getPaymentStartDate(month, year),
            paymentEndDate: getPaymentEndDate(month, year),
            dateRangeType: datePeriodType
        });
    const { currentData: currHistoryApprovals } =
        bulkSnapshotApprovalApi.endpoints.listBulkApprovalHistoryV2.useQueryState({
            marketplaceId: marketPlace.id,
            vendorProgram: business,
            paymentStartDate: getPaymentStartDate(month, year),
            paymentEndDate: getPaymentEndDate(month, year),
            dateRangeType: datePeriodType
        });
    const { currentData: prevPendingApprovals } =
        bulkSnapshotApprovalApi.endpoints.listBulkApprovalV2.useQueryState({
            marketplaceId: marketPlace.id,
            vendorProgram: business,
            paymentStartDate: getPrevMonthPaymentStartDate(month, year),
            paymentEndDate: getPrevMonthPaymentEndDate(month, year),
            dateRangeType: datePeriodType
        });
    const { currentData: prevHistoryApprovals } =
        bulkSnapshotApprovalApi.endpoints.listBulkApprovalHistoryV2.useQueryState({
            marketplaceId: marketPlace.id,
            vendorProgram: business,
            paymentStartDate: getPrevMonthPaymentStartDate(month, year),
            paymentEndDate: getPrevMonthPaymentEndDate(month, year),
            dateRangeType: datePeriodType
        });
    const { currentData } = bulkSnapshotApprovalApi.endpoints.approvalsSummary.useQueryState({
        currPending: currPendingApprovals!,
        currHistory: currHistoryApprovals!,
        prevPending: prevPendingApprovals!,
        prevHistory: prevHistoryApprovals!,
        business,
        marketplaceId: marketPlace.id
    });

    const isEmptyData = isEmpty(currentData);

    const downloadReport = (summary: ApprovalsSummary) => () => {
        const { glProductGroup, marketplaceId, approvalType, vendorProgram } = summary;
        const { paymentStartDate, paymentEndDate } = summary;

        dispatch(
            showSendBulkApprovalReportModal({
                glProductGroup,
                marketplaceId,
                approvalType,
                vendorProgram,
                paymentStartDate,
                paymentEndDate,
                //Currently irrespective of date range selected on UI, we are passing earning period to backend
                dateRangeType: DateRangeTypeValueEnum.EARNING_PERIOD
            })
        );
    };

    return (
        <CollapsibleSection pageTitle='Approval Tracker'>
            <Table
                data-testid='summary-table'
                headerRows={1}
                showDividers={true}
                spacing='small'
                rowComponents={[
                    ApprovalsSummaryHeaderRow,
                    ApprovalsSummaryTableRow,
                    EmptyTableRow
                ]}>
                <ApprovalsSummaryHeaderRow />
                {isEmptyData ? (
                    <EmptyTableRow columnSpan={COLUMN_SPAN_APPROVALS_SUMMARY} />
                ) : (
                    chain(currentData)
                        .sortBy(summary => summary.approvalPhaseInfo.approvalDueDate)
                        .map(summary => (
                            <ApprovalsSummaryTableRow
                                key={`${summary.approvalType} - ${summary.marketplaceId}`}
                                summary={summary}
                                onDownload={downloadReport(summary)}
                            />
                        ))
                        .value()
                )}
            </Table>
        </CollapsibleSection>
    );
};

export default ApprovalsSummaryTable;
