import { flatMap, forEach, isEmpty, map } from 'lodash';
import { CustomerApprovalStatus } from 'src/constants';

import {
    BulkApprovalHistorySnapShot,
    BulkApprovalSnapShot
} from '@amzn/digital-vendor-payments-gateway-client';

import {
    computeTotalAmountSummary,
    filterHistorySnapshotsByMarketplace,
    filterHistorySnapshotsByPhase,
    filterHistorySnapshotsByType,
    filterSnapshotsByMarketplace,
    filterSnapshotsByPhase,
    filterSnapshotsByType,
    getAvailableMarketPlacesForBusiness,
    groupTotalAmountsByCustomerApprovalStatus
} from '@app/helpers';
import { ApprovalsSummary } from '@app/reducers';

export const callApprovalsSummaryApi = (
    currPending: BulkApprovalSnapShot[],
    currHistory: BulkApprovalHistorySnapShot[],
    prevPending: BulkApprovalSnapShot[],
    prevHistory: BulkApprovalHistorySnapShot[],
    business: string,
    marketplaceId: number
): ApprovalsSummary[] => {
    if (marketplaceId !== 0) {
        return computeApprovalsSummaryForApprovalTypes(
            currPending,
            currHistory,
            prevPending,
            prevHistory
        );
    }

    const marketPlaces = getAvailableMarketPlacesForBusiness(business);

    return flatMap(marketPlaces, ({ id }) => {
        const mkplId = `${id}`;

        return computeApprovalsSummaryForApprovalTypes(
            filterSnapshotsByMarketplace(currPending, mkplId),
            filterHistorySnapshotsByMarketplace(currHistory, mkplId),
            filterSnapshotsByMarketplace(prevPending, mkplId),
            filterHistorySnapshotsByMarketplace(prevHistory, mkplId)
        );
    });
};

const computeApprovalsSummaryForApprovalTypes = (
    currPending: BulkApprovalSnapShot[],
    currHistory: BulkApprovalHistorySnapShot[],
    prevPending: BulkApprovalSnapShot[],
    prevHistory: BulkApprovalHistorySnapShot[]
): ApprovalsSummary[] => {
    const normalSummary = computeApprovalsSummary(
        filterSnapshotsByType(currPending, 'normal'),
        filterHistorySnapshotsByType(currHistory, 'normal'),
        filterSnapshotsByType(prevPending, 'normal'),
        filterHistorySnapshotsByType(prevHistory, 'normal')
    );
    const earlySummary = computeApprovalsSummary(
        filterSnapshotsByType(currPending, 'early'),
        filterHistorySnapshotsByType(currHistory, 'early'),
        filterSnapshotsByType(prevPending, 'early'),
        filterHistorySnapshotsByType(prevHistory, 'early')
    );

    if (normalSummary === undefined && earlySummary === undefined) {
        return [];
    } else if (earlySummary === undefined) {
        return [normalSummary!];
    } else if (normalSummary === undefined) {
        return [earlySummary];
    } else {
        return [normalSummary, earlySummary];
    }
};

const computeApprovalsSummary = (
    currPending: BulkApprovalSnapShot[],
    currHistory: BulkApprovalHistorySnapShot[],
    prevPending: BulkApprovalSnapShot[],
    prevHistory: BulkApprovalHistorySnapShot[]
): ApprovalsSummary | undefined => {
    const currAllSnapshots = [
        ...currPending,
        ...map(currHistory, ({ bulkApprovalSnapshot }) => bulkApprovalSnapshot)
    ];
    const prevAllSnapshots = [
        ...prevPending,
        ...map(prevHistory, ({ bulkApprovalSnapshot }) => bulkApprovalSnapshot)
    ];

    // do not show summary if no snapshots yet for current month
    if (isEmpty(currAllSnapshots)) {
        return undefined;
    }

    const baseSnapshot = currAllSnapshots[0];
    const businessHistory = filterHistorySnapshotsByPhase(currHistory, 'VM_ONLY');
    const financeHistory = filterHistorySnapshotsByPhase(currHistory, 'FM_ONLY');
    const currAmountSummary = computeTotalAmountSummary(currAllSnapshots);
    const prevAmountSummary = computeTotalAmountSummary(prevAllSnapshots);

    const totalBusinessAmounts = groupTotalAmountsByCustomerApprovalStatus(businessHistory);
    const totalFinanceAmounts = groupTotalAmountsByCustomerApprovalStatus(financeHistory);

    return {
        ...baseSnapshot,
        amountSummary: currAmountSummary,
        variances: computeTotalVariance(currAmountSummary, prevAmountSummary),
        business: {
            pending: computeTotalAmountSummary(filterSnapshotsByPhase(currPending, 'VM_ONLY')),
            approved: totalBusinessAmounts[CustomerApprovalStatus.APPROVED],
            inprogress: totalBusinessAmounts[CustomerApprovalStatus.IN_PROGRESS],
            failed: totalBusinessAmounts[CustomerApprovalStatus.FAILED]
        },
        finance: {
            pending: computeTotalAmountSummary(filterSnapshotsByPhase(currPending, 'FM_ONLY')),
            approved: totalFinanceAmounts[CustomerApprovalStatus.APPROVED],
            inprogress: totalFinanceAmounts[CustomerApprovalStatus.IN_PROGRESS],
            failed: totalFinanceAmounts[CustomerApprovalStatus.FAILED]
        }
    };
};

const computeTotalVariance = (
    currAmountSummary: ApprovalsSummary['amountSummary'],
    prevAmountSummary: ApprovalsSummary['amountSummary']
): ApprovalsSummary['variances'] => {
    const variances: ApprovalsSummary['variances'] = {};
    // looping through currencies of current month
    forEach(currAmountSummary, (curr, currency) => {
        const prev = prevAmountSummary[currency];

        if (prev === undefined || prev.count === 0) {
            variances[currency] = { totalVariance: curr.amount, variancePercent: 100 };
        } else {
            const totalVariance = curr.amount - prev.amount;
            variances[currency] = {
                totalVariance,
                variancePercent: (totalVariance * 100) / prev.amount
            };
        }
    });
    // looping through currencies of previous month
    forEach(prevAmountSummary, (prev, currency) => {
        const curr = currAmountSummary[currency];

        if (curr === undefined || curr.count === 0) {
            variances[currency] = { totalVariance: -prev.amount, variancePercent: -100 };
        }
    });

    return variances;
};
