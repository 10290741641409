import { chain, map } from 'lodash';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import {
    BulkApprovalSnapShot,
    DateRangeTypeValueEnum,
    ListBulkApprovalOutputV2
} from '@amzn/digital-vendor-payments-gateway-client';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Table from '@amzn/meridian/table';
import Text from '@amzn/meridian/text';

import { EmptyTableRow } from '@app/components/common';
import {
    bulkSnapshotApprovalApi,
    showPlaceBulkApprovalModal,
    showSendBulkApprovalReportModal,
    useFiltersSelector
} from '@app/reducers';
import { getPaymentEndDate, getPaymentStartDate } from '@app/util/date_util';

import PendingApprovalsTableCard from './PendingApprovalsTableCard';

const findSnapshots = (
    responses: ListBulkApprovalOutputV2[],
    ids: string[]
): BulkApprovalSnapShot[] => {
    return chain(responses)
        .flatMap(({ bulkApprovalSnapShots }) => bulkApprovalSnapShots)
        .filter(snapshot => ids.includes(snapshot.snapShotId))
        .value();
};

const COLUMN_SPAN_PENDING_APPROVALS = 10;

/**
 * Creates Table to be displayed in the pending approval section.
 * @returns Pending approvals section
 */
const PendingApprovalsV2: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { month, year, business, marketPlace, datePeriodType } = useFiltersSelector();

    const { currentData } = bulkSnapshotApprovalApi.endpoints.listBulkApprovalV2.useQueryState({
        marketplaceId: marketPlace.id,
        vendorProgram: business,
        paymentStartDate: getPaymentStartDate(month, year),
        paymentEndDate: getPaymentEndDate(month, year),
        dateRangeType: datePeriodType
    });

    const isEmpty = useMemo(
        () =>
            chain(currentData)
                .flatMap(({ bulkApprovalSnapShots }) => bulkApprovalSnapShots)
                .sortBy(snapshot => snapshot.approvalPhaseInfo.approvalDueDate)
                .isEmpty()
                .value(),
        [currentData]
    );

    const approveSnapshot = (id: string) => {
        dispatchApprovalRequests([id]);
    };

    const dispatchApprovalRequests = (selectedSnapshotIds: string[]) => {
        dispatch(showPlaceBulkApprovalModal(findSnapshots(currentData!, selectedSnapshotIds)));
    };

    const downloadSnapshot = (snapshot: BulkApprovalSnapShot) => {
        dispatch(
            showSendBulkApprovalReportModal({
                ...snapshot,
                lastUpdatedDateFrom: snapshot.fromDate,
                lastUpdatedDateTo: snapshot.toDate,
                //Currently irrespective of date range selected on UI, we are passing earning period to backend
                dateRangeType: DateRangeTypeValueEnum.EARNING_PERIOD
            })
        );
    };

    return (
        <Column>
            <Text type='h400'> Pending Approvals</Text>
            <div data-testid='pending-approvals-section'>
                {isEmpty ? (
                    <Table rowComponents={[EmptyTableRow]}>
                        <EmptyTableRow columnSpan={COLUMN_SPAN_PENDING_APPROVALS} />
                    </Table>
                ) : (
                    <Row
                        data-testid='pending-approvals-section-cards'
                        wrap='down'
                        minWidth={360}
                        minHeight={380}
                        width='100%'
                        alignmentVertical='stretch'>
                        {chain(currentData)
                            .flatMap(
                                ({
                                    bulkApprovalSnapShots,
                                    businessApprovalAllowed,
                                    financeApprovalAllowed
                                }) => {
                                    return map(bulkApprovalSnapShots, snapshot => (
                                        <PendingApprovalsTableCard
                                            data-testid='pending-approvals-section-card'
                                            key={snapshot.snapShotId}
                                            snapshot={snapshot}
                                            businessApprovalAllowed={businessApprovalAllowed}
                                            financeApprovalAllowed={financeApprovalAllowed}
                                            onApproveSnapshot={() =>
                                                approveSnapshot(snapshot.snapShotId)
                                            }
                                            onDownload={() => downloadSnapshot(snapshot)}
                                        />
                                    ));
                                }
                            )
                            .sortBy(card => card.props.snapshot.approvalPhaseInfo.approvalDueDate)
                            .value()}
                    </Row>
                )}
            </div>
        </Column>
    );
};

export default PendingApprovalsV2;
