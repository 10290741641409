import "../configuration/publicPath.ts";
import ReactDOM from "react-dom";
import App from "./components/App";
import AppInitWrapper from "./components/AppInitWrapper";
import { Provider } from "react-redux";
import "./index.scss";
import React from "react";
import store from "src/configure_store";

// // @ts-ignore
// export const publicPath = '{{Region}}' === 'ap-southeast-1'
//         ? '/'
//         : '{{CloudFrontProtocol}}://{{CloudFrontDomain}}/';
//
// console.log(process.env.AWS_REGION);
// console.log('publicPath ' + publicPath);
//
// // @ts-ignore
// __webpack_public_path__ = '{{CloudFrontProtocol}}://{{CloudFrontDomain}}/';

document.addEventListener("DOMContentLoaded", () => {
  ReactDOM.render(
    <Provider store={store()}>
      <AppInitWrapper>
        <App />
      </AppInitWrapper>
    </Provider>,
    document.getElementById("root")
  );
});
