import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { updateFeedbackState } from 'src/reducers/feedback/actions';

import caretDownTokens from '@amzn/meridian-tokens/base/icon/caret-down';
import Alert from '@amzn/meridian/alert';
import Button from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import Link from '@amzn/meridian/link';
import Masthead, { MastheadLink, MastheadTitle } from '@amzn/meridian/masthead';
import Menu, { MenuItem } from '@amzn/meridian/menu';
import Row from '@amzn/meridian/row';
import Text from '@amzn/meridian/text';
import Coachmark from '@amzn/meridian/coachmark';
import Column from '@amzn/meridian/column';

import Feedback from '@app/components/home/Feedback';
import { PAGE_LOCATIONS } from '@app/constants/app';
import { getCurrentStageConfig } from '@app/constants/stage';
import {
	APPLICATION_URLS,
	DAPR_URLS,
	FAQ_LINK,
	FEATURE_REQUEST_QUICKLINK,
	PERMISSION_LINK,
	SNTP_LINK,
	TT_QUICKLINK
} from '@app/constants/urls';

const Header: React.FunctionComponent = () => {
	//Hooks declaration
	let history = useHistory();
	let location = useLocation();
	let helpRef = useRef();
	let regionRef = useRef();
	const [bannerOpen, setBannerOpen] = useState(true);
	const [helpOpen, setHelpOpen] = useState(false);
	const [regionOpen, setRegionOpen] = useState(false);
	const dispatch = useDispatch();

	const VINE_LAUNCH_WIKI_URL = 'https://w.amazon.com/bin/view/UpcomingChangesinCaspianPaymentApproval'

	//Constants
	const stageConfig = getCurrentStageConfig();
	const listDisbursementsUrl = DAPR_URLS[stageConfig.STAGE][stageConfig.REALM].list;

	//Action Handlers
	const onClickShowFeedback = () => {
		dispatch(updateFeedbackState('isFeedbackOpen', true));
	};

	const openInNewTab = (e: string) => {
		window.open(e, '_blank', 'noopener,noreferrer');
	};

	return (
		<div>
            {bannerOpen ? (
                <Alert type='informational' onClose={setBannerOpen}>
                    <Text>
                        We are updating how CPAT performs approvals. To view the changes,
                        click&nbsp;
                        <Link href={VINE_LAUNCH_WIKI_URL} target='_blank' rel='noopener noreferrer'>
                            here
                        </Link>
                        <Coachmark popoverPosition='top'>
                            <Column width='200px'>
                                <Text>
                                    Payment Approval is changing in 2023, we recommend you review
                                    the changes on the&nbsp;
                                    <Link
                                        href={VINE_LAUNCH_WIKI_URL}
                                        target='_blank'
                                        rel='noopener noreferrer'>
                                        wiki
                                    </Link>
                                </Text>
                            </Column>
                        </Coachmark>
                    </Text>
                </Alert>
            ) : (
                <></>
            )}
			<Masthead size='large'>
				<MastheadTitle href='#'>
					<Text type='h200'>Caspian Payment Approval</Text>
				</MastheadTitle>
				<MastheadLink
					href='/'
					selected={location.pathname === PAGE_LOCATIONS.HOME}
					onClick={history.push}>
					Home
				</MastheadLink>
				<MastheadLink
					data-rum-id={'btn-header-dapr'}
					href={listDisbursementsUrl}
					onClick={openInNewTab}
				>
					Disbursements
				</MastheadLink>
				<MastheadLink
					href={PAGE_LOCATIONS.DISBURSEMENT_SUMMARY}
					onClick={history.push}
					selected={location.pathname === PAGE_LOCATIONS.DISBURSEMENT_SUMMARY}>
					Disbursements Summary
				</MastheadLink>
				<MastheadLink
					href={PAGE_LOCATIONS.BULK_APPROVAL}
					onClick={history.push}
					selected={location.pathname === PAGE_LOCATIONS.BULK_APPROVAL}>
					Bulk Approvals
				</MastheadLink>
				{/* <MastheadLink href={bulkApprovalUrl} onClick={openInNewTab}></MastheadLink>
				<MastheadLink>Holds</MastheadLink>
				<MastheadLink
					href='/disbursements'
					onClick={history.push}
					selected={location.pathname === PAGE_LOCATIONS.DISBURSEMENTS}>
					Reports
				</MastheadLink> */}
				<MastheadLink onClick={() => setHelpOpen(true)}>
					<Text ref={helpRef}>
						Help
						<Icon tokens={caretDownTokens}/>
					</Text>
					<HelpMenu anchor={helpRef} isOpen={helpOpen} setOpen={setHelpOpen}></HelpMenu>
				</MastheadLink>
				{/** Components on the right side of the header **/}
				<Button
					data-rum-id={'btn-header-feedback'}
					type='icon'
				>
					<div className='smiley-header' onClick={onClickShowFeedback}></div>
				</Button>
				<Feedback/>
				<Button
					data-rum-id={'btn-header-region-selector'}
					ref={regionRef}
					onClick={() => setRegionOpen(true)}
					size="small"
				>
					<Row spacing="200">
						<Icon tokens={caretDownTokens}/>
						<Text color="inverted" type="b200">
							Region: {stageConfig.REALM}
						</Text>
					</Row>
				</Button>
				<RegionMenu
					anchor={regionRef}
					isOpen={regionOpen}
					setOpen={setRegionOpen}></RegionMenu>
			</Masthead>
		</div>
	);
};

interface ImenuProps {
	isOpen: boolean;
	anchor: React.MutableRefObject<any>;
	// eslint-disable-next-line no-unused-vars
	setOpen: (a: boolean) => any;
}

const HelpMenu: React.FunctionComponent<ImenuProps> = ({ anchor, isOpen, setOpen }) => {
	return (
		<Menu
			anchorNode={anchor.current}
			open={isOpen}
			position='bottom'
			onClose={() => {
				setOpen(false);
			}}>
			<MenuItem href={FAQ_LINK} target='_blank' rel='noopener noreferrer'>
				FAQs
			</MenuItem>
			<MenuItem href={SNTP_LINK} target='_blank' rel='noopener noreferrer'>
				Spending and Transaction Policy
			</MenuItem>
			<MenuItem href={PERMISSION_LINK} target='_blank' rel='noopener noreferrer'>
				Request Access
			</MenuItem>
			<MenuItem href={TT_QUICKLINK} target='_blank' rel='noopener noreferrer'>
				Report an Issue
			</MenuItem>
			<MenuItem href={FEATURE_REQUEST_QUICKLINK} target='_blank' rel='noopener noreferrer'>
				Request a Feature
			</MenuItem>
		</Menu>
	);
};

const RegionMenu: React.FunctionComponent<ImenuProps> = ({ anchor, isOpen, setOpen }) => {
	const stageConfig = getCurrentStageConfig();
	return (
		<Menu
			anchorNode={anchor.current}
			open={isOpen}
			position='bottom'
			onClose={() => {
				setOpen(false);
			}}>
			<MenuItem href={APPLICATION_URLS[stageConfig.STAGE].NA}>NA</MenuItem>
			<MenuItem href={APPLICATION_URLS[stageConfig.STAGE].EU}>EU</MenuItem>
			<MenuItem href={APPLICATION_URLS[stageConfig.STAGE].FE}>FE</MenuItem>
			<MenuItem href={APPLICATION_URLS[stageConfig.STAGE].CN}>CN</MenuItem>
		</Menu>
	);
};
export default Header;
