import { BulkApproval } from "@amzn/digital-vendor-payments-gateway-client";
import { TableCell } from "@amzn/meridian/table";
import Tag from "@amzn/meridian/tag";
import React from "react";
import { marketplaceToCC } from "src/constants/marketplace";
import { tSummary, formatDateAsString } from "src/helpers/bulk_approval";
import { RenderValues } from "./BulkApprovalList";

/**
 * Reusable fragment which surfaces basic information of bulk approval.
 */
export const BulkApprovalInfo = ({
  approvalPayload,
  summary,
}: {
  approvalPayload: BulkApproval;
  summary: tSummary[] | undefined;
}) => {
  return (
    <>
      <TableCell alignmentHorizontal="center">
        {approvalPayload.business} <br />
        {approvalPayload.approvalType === "early" ? (
          <Tag>Early</Tag>
        ) : null}{" "}
      </TableCell>
      <TableCell alignmentHorizontal="center">
        {approvalPayload.glProductGroup}
      </TableCell>
      <TableCell alignmentHorizontal="center">
        {marketplaceToCC[approvalPayload.marketplaceId]}
      </TableCell>
      <TableCell alignmentHorizontal="center">
        {formatDateAsString(approvalPayload.paymentStartDate)}
        <br />
        to
        <br />
        {formatDateAsString(approvalPayload.paymentEndDate)}
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <RenderValues
          summary={summary}
          render={(s: tSummary) => {
            return (
              <>
                {s.totalAmount !== undefined && s.totalAmount !== null
                  ? s.totalAmount.toLocaleString() + " " + s.currency
                  : "N/A"}
                <br />
              </>
            );
          }}
        />
      </TableCell>
      <TableCell alignmentHorizontal="center">
        <RenderValues
          summary={summary}
          render={(s: tSummary) => {
            return (
              <>
                {s.payments !== undefined && s.payments !== null
                  ? s.payments.toLocaleString()
                  : "N/A"}
                <br />
              </>
            );
          }}
        />
      </TableCell>
    </>
  );
};
