import { isAfter, parseISO } from 'date-fns';
import { find } from 'lodash';
import { useCallback } from 'react';

import { bulkSnapshotApprovalApi } from '@app/reducers';

export const useCutoffConfig = () => {
    const { currentData: cutoffConfigs } =
        bulkSnapshotApprovalApi.endpoints.cutoffConfig.useQueryState();
    const othersConfig = find(cutoffConfigs, ({ vendorProgram }) => vendorProgram === 'Others');

    const isNewExperienceEnabled = useCallback(
        (business: string, paymentEndDate: Date) => {
            const businessConfig = find(
                cutoffConfigs,
                ({ vendorProgram }) => vendorProgram === business
            );

            return (
                (businessConfig &&
                    isAfter(paymentEndDate, parseISO(businessConfig.fromPaymentStartDate))) ||
                (othersConfig &&
                    isAfter(paymentEndDate, parseISO(othersConfig.fromPaymentStartDate)))
            );
        },
        [cutoffConfigs]
    );

    return { isNewExperienceEnabled };
};
