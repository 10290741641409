import React, { memo } from 'react';

import {
    BulkApprovalResponseV2,
    BulkApprovalSnapShot
} from '@amzn/digital-vendor-payments-gateway-client';
import { TableCell, TableRow } from '@amzn/meridian/table';
import Tag from '@amzn/meridian/tag';

import { BusinessCell, EarningsPeriodCell } from '@app/components/bulkSnapshotApproval/cells';
import { getMarketPlaceFromId } from '@app/helpers';

interface Props {
    response: BulkApprovalResponseV2;
    snapshot: BulkApprovalSnapShot;
}

const StatusRow: React.FunctionComponent<Props> = ({ response, snapshot }) => {
    const { snapShotId, approvalType, disbursementType, glProductGroup, marketplaceId } = snapshot;
    const { paymentStartDate, paymentEndDate } = snapshot;

    return (
        <TableRow key={snapShotId}>
            <BusinessCell approvalType={approvalType} disbursementType={disbursementType} />
            <TableCell alignmentHorizontal='center'>{glProductGroup}</TableCell>
            <TableCell alignmentHorizontal='center'>
                {getMarketPlaceFromId(Number(marketplaceId)).name}
            </TableCell>
            <EarningsPeriodCell
                paymentStartDate={paymentStartDate}
                paymentEndDate={paymentEndDate}
            />
            <TableCell alignmentHorizontal='center'>
                {response.status === 'failed' ? (
                    <Tag type='error'>Failed</Tag>
                ) : (
                    <Tag type='success'>Success</Tag>
                )}
            </TableCell>
        </TableRow>
    );
};

export default memo(
    StatusRow,
    (prevProps, nextProps) =>
        prevProps.snapshot.snapShotId === nextProps.snapshot.snapShotId &&
        prevProps.response.status === nextProps.response.status
);
