import React, { memo } from 'react';

import {
	DateRangeTypeValue,
	DateRangeTypeValueEnum
} from '@amzn/digital-vendor-payments-gateway-client';
import infoKnockoutTokens from '@amzn/meridian-tokens/base/icon/info-knockout';
import Icon from '@amzn/meridian/icon';
import Row from '@amzn/meridian/row';
import Select, { SelectOption } from '@amzn/meridian/select';
import Tooltip from '@amzn/meridian/tooltip';

interface Props {
	datePeriodType?: DateRangeTypeValue;
	// eslint-disable-next-line no-unused-vars
	onUpdateDatePeriodType: (value: DateRangeTypeValue) => void;
}

const DatePeriodTypeSelector: React.FunctionComponent<Props> = ({
	datePeriodType = DateRangeTypeValueEnum.EARNING_PERIOD,
	onUpdateDatePeriodType
}) => {
	return (
		<Row alignmentVertical='center' widths={['fit', 'fill']}>
			<Tooltip
				position='bottom'
				title='##Earning Period - Is the month that the vendor is paid for.
                 ##Effective Date - This date is set during finalization and VDMS uses it to 
                 identify when a bucket has to be finalised. For Example Earning period for
                 march disbursements would be created in April.'>
				<Icon tokens={infoKnockoutTokens} />
			</Tooltip>
			<Select
				value={datePeriodType}
				onChange={onUpdateDatePeriodType}
				label='Type'
				placeholder='Select Date Period Type'
				size='xlarge'>
				{Object.keys(DateRangeTypeValueEnum).map((item, index) => (
					<SelectOption key={index} value={item} label={item} />
				))}
			</Select>
		</Row>
	);
};

export default memo(
	DatePeriodTypeSelector,
	(prevProps, nextProps) => prevProps.datePeriodType === nextProps.datePeriodType
);
