import React from "react";
import { fetchUserClaims } from "src/authorization/token_handler";
import Row from "@amzn/meridian/row/row";
import Alert from "@amzn/meridian/alert";
import Text from "@amzn/meridian/text";
import {DisbursementSummaryInputGroup} from "src/components/disbursementsummary/DisbursementSummaryInputGroup";
import {DisbursementSummaryGraphs} from "src/components/disbursementsummary/DisbursementSummaryGraphs";

const DisbursementSummary: React.FunctionComponent = () => {
    const viewPermissions = fetchUserClaims()?.list_disbursements.summary_view;
    return viewPermissions && viewPermissions.length ? (
        <>
            <DisbursementSummaryInputGroup> </DisbursementSummaryInputGroup>
            <DisbursementSummaryGraphs> </DisbursementSummaryGraphs>
        </>
    ) : (
        <Row alignmentHorizontal="center" spacingInset="large">
            <Alert size="large" type="informational">
                <Text type="b500">
                    You don't have access to view/approve disbursement summary for any business.
                    <br />
                    Visit the request access link from Help section to get permissions.
                </Text>
            </Alert>
        </Row>
    );
};
export default DisbursementSummary;
