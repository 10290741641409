import { chain, every, first, isEmpty } from 'lodash';
import React from 'react';
import { fetchUserClaims } from 'src/authorization/token_handler';

import { ListBulkApprovalOutputV2 } from '@amzn/digital-vendor-payments-gateway-client';
import Column from '@amzn/meridian/column';
import Divider from '@amzn/meridian/divider';
import Heading from '@amzn/meridian/heading';

import {
    ApprovalsHistory,
    ApprovalsSummary,
    BulkSnapshotFilter,
    PendingApprovals
} from '@app/components/bulkSnapshotApproval';
import { marketplaceToCC } from '@app/constants';
import {
    useApprovalsSummaryQuery,
    useFiltersSelector,
    useListBulkApprovalHistoryV2Query,
    useListBulkApprovalV2Query
} from '@app/reducers';
import {
    getPaymentEndDate,
    getPaymentStartDate,
    getPrevMonthPaymentEndDate,
    getPrevMonthPaymentStartDate
} from '@app/util/date_util';

import { BulkApprovalAlert } from './alert';
import { SendBulkApprovalReport, SubmitBulkApproval } from './modal';

const showBulkApproval = (): boolean => {
    const viewPermissions = fetchUserClaims()?.bulk_approval.view;
    return viewPermissions && viewPermissions.length > 0 ? true : false;
};

const sodViolationsPredicate = ({
    businessApprovalAllowed,
    financeApprovalAllowed,
    bulkApprovalSnapShots
}: ListBulkApprovalOutputV2) =>
    businessApprovalAllowed && financeApprovalAllowed && !isEmpty(bulkApprovalSnapShots);

const BulkSnapshotApproval: React.FunctionComponent = () => {
    const { month, year, business, marketPlace, datePeriodType } = useFiltersSelector();

    const {
        currentData: currPendingApprovals,
        isFetching: isFetchingPendingApprovals,
        isError: isErrorPendingApprovals,
        refetch: refetchPendingApprovals
    } = useListBulkApprovalV2Query({
        marketplaceId: marketPlace.id,
        vendorProgram: business,
        paymentStartDate: getPaymentStartDate(month, year),
        paymentEndDate: getPaymentEndDate(month, year),
        dateRangeType: datePeriodType
    });
    const {
        currentData: currHistoryApprovals,
        isFetching: isFetchingApprovalsHistory,
        isError: isErrorApprovalsHistory,
        refetch: refetchApprovalsHistory
    } = useListBulkApprovalHistoryV2Query({
        marketplaceId: marketPlace.id,
        vendorProgram: business,
        paymentStartDate: getPaymentStartDate(month, year),
        paymentEndDate: getPaymentEndDate(month, year),
        dateRangeType: datePeriodType
    });
    const {
        currentData: prevPendingApprovals,
        isFetching: isFetchingPendingApprovalsPrev,
        isError: isErrorPendingApprovalsPrev,
        refetch: refetchPendingApprovalsPrev
    } = useListBulkApprovalV2Query({
        marketplaceId: marketPlace.id,
        vendorProgram: business,
        paymentStartDate: getPrevMonthPaymentStartDate(month, year),
        paymentEndDate: getPrevMonthPaymentEndDate(month, year),
        dateRangeType: datePeriodType
    });
    const {
        currentData: prevHistoryApprovals,
        isFetching: isFetchingApprovalsHistoryPrev,
        isError: isErrorApprovalsHistoryPrev,
        refetch: refetchApprovalsHistoryPrev
    } = useListBulkApprovalHistoryV2Query({
        marketplaceId: marketPlace.id,
        vendorProgram: business,
        paymentStartDate: getPrevMonthPaymentStartDate(month, year),
        paymentEndDate: getPrevMonthPaymentEndDate(month, year),
        dateRangeType: datePeriodType
    });

    const isFetchingRemote =
        isFetchingPendingApprovals ||
        isFetchingApprovalsHistory ||
        isFetchingPendingApprovalsPrev ||
        isFetchingApprovalsHistoryPrev;
    const isErrorRemote =
        isErrorPendingApprovals ||
        isErrorApprovalsHistory ||
        isErrorPendingApprovalsPrev ||
        isErrorApprovalsHistoryPrev;

    const { isFetching: isFetchingApprovalsSummary, isError: isErrorApprovalsSummary } =
        useApprovalsSummaryQuery(
            {
                currPending: currPendingApprovals!,
                currHistory: currHistoryApprovals!,
                prevPending: prevPendingApprovals!,
                prevHistory: prevHistoryApprovals!,
                business,
                marketplaceId: marketPlace.id
            },
            { skip: isFetchingRemote || isErrorRemote }
        );

    const refetchAll = () => {
        // refetch only errored out queries
        isErrorPendingApprovals && refetchPendingApprovals();
        isErrorApprovalsHistory && refetchApprovalsHistory();
        isErrorPendingApprovalsPrev && refetchPendingApprovalsPrev();
        isErrorApprovalsHistoryPrev && refetchApprovalsHistoryPrev();
    };

    const hasNoPermissions = every(
        currPendingApprovals,
        ({ businessApprovalAllowed, financeApprovalAllowed, viewAllowed }) =>
            !businessApprovalAllowed && !financeApprovalAllowed && !viewAllowed
    );
    const hasNoSnapshots =
        every(currPendingApprovals, ({ bulkApprovalSnapShots }) =>
            isEmpty(bulkApprovalSnapShots)
        ) &&
        every(currHistoryApprovals, ({ bulkApprovalHistorySnapShots }) =>
            isEmpty(bulkApprovalHistorySnapShots)
        );
    const hasAllSodViolations = every(currPendingApprovals, sodViolationsPredicate);
    const sodMarketplaces = chain(currPendingApprovals)
        .filter(sodViolationsPredicate)
        .map(({ bulkApprovalSnapShots }): string => {
            const id = Number(first(bulkApprovalSnapShots)!!.marketplaceId);
            return marketplaceToCC[id];
        })
        .value();

    const isFetching = isFetchingRemote || isFetchingApprovalsSummary;
    const isError = isErrorRemote || isErrorApprovalsSummary;

    return showBulkApproval() ? (
        <Column spacingInset='500' spacing='500' backgroundColor='#F9FAFA'>
            <Heading level={1} type='d100'>
                {'Bulk Approvals'}
            </Heading>
            <BulkSnapshotFilter />
            {isFetching ? (
                <BulkApprovalAlert.Loading />
            ) : isError ? (
                <BulkApprovalAlert.Error business={business} reload={refetchAll} />
            ) : (
                <>
                    {!isEmpty(sodMarketplaces) && (
                        <BulkApprovalAlert.SodViolation
                            marketplaces={sodMarketplaces}
                            hasAllSodViolations={hasAllSodViolations}
                        />
                    )}
                    {hasNoPermissions && hasNoSnapshots ? (
                        <BulkApprovalAlert.NoPermissions />
                    ) : (
                        <>
                            <PendingApprovals />
                            <Divider />
                            <ApprovalsSummary />
                            <ApprovalsHistory />
                            <SendBulkApprovalReport />
                            <SubmitBulkApproval />
                        </>
                    )}
                </>
            )}
        </Column>
    ) : (
        <BulkApprovalAlert.NoPermissions />
    );
};

export default BulkSnapshotApproval;
