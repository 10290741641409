import React, { memo } from 'react';

import { ApprovalPhaseInfo } from '@amzn/digital-vendor-payments-gateway-client';
import { TableCell } from '@amzn/meridian/table';
import Tag from '@amzn/meridian/tag';

import { dueDateTagHelper, formatDateAsString } from '@app/helpers';

interface Props {
    approvalPhaseInfo: ApprovalPhaseInfo;
    showTag?: boolean;
}

const DueDateCell: React.FunctionComponent<Props> = ({ approvalPhaseInfo, showTag = true }) => {
    const dueDate = dueDateTagHelper(approvalPhaseInfo);

    return (
        <TableCell alignmentHorizontal='center'>
            {formatDateAsString(approvalPhaseInfo.approvalDueDate)}
            <br />
            {showTag && <Tag type={dueDate.status}>{dueDate.text}</Tag>}
        </TableCell>
    );
};

export default memo(
    DueDateCell,
    (prevProps, nextProps) =>
        prevProps.approvalPhaseInfo === nextProps.approvalPhaseInfo &&
        prevProps.showTag === nextProps.showTag
);
