import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import { bulkSnapshotApprovalApi, rootReducer } from '@app/reducers';

export default function configureStore() {
	const middlewares = [thunk, bulkSnapshotApprovalApi.middleware];
	const middlewareEnhancer = applyMiddleware(...middlewares);
	const enhancers = [middlewareEnhancer];
	const composedEnhancers = composeWithDevTools(...enhancers);
	return createStore(rootReducer, composedEnhancers);
}
