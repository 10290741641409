import AlertError from './Error';
import { AlertLoading } from './Loading';
import { AlertNoPermissions } from './NoPermissions';
import AlertSodViolation from './SodViolation';

export const BulkApprovalAlert = {
	Loading: AlertLoading,
	Error: AlertError,
	NoPermissions: AlertNoPermissions,
	SodViolation: AlertSodViolation
};
