export type BulkApprovalAttributes = {
  vp: string; //vendor_program
  mp: number; //marketplace
  typ: string; //approval_type
};

export type BulkApprovalClaims = {
  finance_approval: BulkApprovalAttributes[];
  business_approval: BulkApprovalAttributes[];
  view: BulkApprovalAttributes[];
};

/**
 * Example
 * { "2": ["TVOD", ""] }
 */
export type UnstructuredMarketplaces = {
  [m: string]: string[]
};

/**
 * Example
 * { "normal": { "2": ["TVOD"] } }
 */
export type UnstructuredBulkApprovalAttributes = {
  [s: string]: UnstructuredMarketplaces
};

/**
 * Example
 * { "view": { "normal": { "2": ["TVOD"] } } }
 */
export type UnstructuredBulkApprovalClaims = {
  [s: string]: UnstructuredBulkApprovalAttributes
};

export type DisbursementSummaryAttributes = {
  gl: string; //gl
  dt: string[]; //disbursement_types
  gn: string; //gl name
};

export type ListDisbursementClaims = {
  summary_view: DisbursementSummaryAttributes[];
};

export type UserData = {
  user_id: string;
};

export type UserClaims = {
  user: UserData;
  bulk_approval: BulkApprovalClaims;
  list_disbursements: ListDisbursementClaims;
};

export type AuthTokenLocalStorage = {
  token: string;
  compressed: string;
  expires_at: number;
};

export enum BulkApprovalOperations {
  FINANCE_APPROVAL = "finance_approval",
  BUSINESS_APPROVAL = "business_approval",
  VIEW = "view"
}

export enum Resources {
  BULK_APPROVAL = "bulk_approval"
}

export type ResourceOperations = BulkApprovalOperations;
export type ResourceAttributes = BulkApprovalAttributes;
