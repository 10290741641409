import React, { Dispatch, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateInputCompletely as updateBulkApprovalInputCompletely } from 'src/reducers/bulk_approval';
import { updateInputCompletely as updateDisbursementSummaryInputCompletely } from 'src/reducers/disbursement_summary';

import Alert from '@amzn/meridian/alert';
import Column from '@amzn/meridian/column';
import Loader from '@amzn/meridian/loader';
import Text from '@amzn/meridian/text';

import { PAGE_LOCATIONS } from '@app/constants';
import {
	parseBulkApprovalParams,
	parseBulkSnapshotApprovalParams,
	parseListDisbursementSummaryParams,
	tParsedValue
} from '@app/helpers';
import { updateAppState, updateMultipleFilters as updateBulkSnapshotFilters } from '@app/reducers';

type tProps = {
	children: JSX.Element;
};

export const WithQueryParam: React.FunctionComponent<tProps> = ({ children }) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const [parsed, setParsed] = useState(false);
	const [errors, setError] = useState<undefined | null | string[]>(null);

	useEffect(() => {
		const queryParams = new URLSearchParams(location.search);
		const page = location.pathname;
		const errors = updateParams(page, queryParams, dispatch, parsed);
		setError(errors);
		setParsed(true);
	}, [location]);

	return parsed ? (
		errors ? (
			<Column
				height='50vh'
				heights='fit'
				alignmentHorizontal='center'
				alignmentVertical='center'>
				<Alert type='error' size='large'>
					<Text type='b400'>
						The URL parameters has the following errors.
						<br />
					</Text>
					{errors.map((error, index) => {
						return (
							<Text type='b400' key={index}>
								{index + 1}. {error}
								<br />
							</Text>
						);
					})}
					<Text type='b400'>Please correct them or use the links in the header.</Text>
				</Alert>
			</Column>
		) : (
			children
		)
	) : (
		<Column
			height='100vh'
			heights='fit'
			alignmentHorizontal='center'
			alignmentVertical='center'>
			<Loader type='circular' />
		</Column>
	);
};

/**
 * This function validates the query params
 * and displays errors if user passes invalid
 * query params
 * @returns tParsedValue
 */
const updateParams = (
	page: string,
	params: URLSearchParams,
	dispatch: Dispatch<any>,
	parsed: boolean
) => {
	let parsedValue: tParsedValue | null = null;
	if (page === PAGE_LOCATIONS.BULK_APPROVAL) {
		const startDate = params.get('start_date');
		const endDate = params.get('end_date');
		const business = params.get('business');
		const marketplace = params.get('marketplace');
		const dateRangeType = params.get('date_range_type');
		const disbursementStatusFilter = params.get('disb_status_filter');
		parsedValue = parseBulkApprovalParams(
			startDate,
			endDate,
			business,
			marketplace,
			dateRangeType,
			disbursementStatusFilter
		);
		// old ui url valid
		if (parsedValue?.bulkApprovalInputs && !parsed) {
			dispatch(updateAppState('showNewExperience', false));
			dispatch(updateBulkApprovalInputCompletely(parsedValue.bulkApprovalInputs));
			return parsedValue?.errors;
		}

		const monthInput = params.get('month');
		const yearInput = params.get('year');
		const businessInput = params.get('business');
		const marketPlaceInput = params.get('marketplace');
		const datePeriodTypeInput = params.get('datePeriodType');
		parsedValue = parseBulkSnapshotApprovalParams(
			monthInput,
			yearInput,
			businessInput,
			marketPlaceInput,
			datePeriodTypeInput
		);
		// new ui url valid
		if (parsedValue?.bulkSnapshotApprovalFilters && !parsed) {
			dispatch(updateAppState('showNewExperience', true));
			dispatch(updateBulkSnapshotFilters(parsedValue.bulkSnapshotApprovalFilters));
			return parsedValue?.errors;
		}
	} else if (page === PAGE_LOCATIONS.DISBURSEMENT_SUMMARY) {
		const startDate = params.get('start_date');
		const endDate = params.get('end_date');
		const gl = params.get('gl');
		parsedValue = parseListDisbursementSummaryParams(startDate, endDate, gl);
		parsedValue?.disbursementSummaryInputs &&
			!parsed &&
			dispatch(
				updateDisbursementSummaryInputCompletely(parsedValue.disbursementSummaryInputs)
			);
	} else if (page !== '/') {
		parsedValue = {
			errors: [`${page} is not a valid page URL`]
		};
	}
	return parsedValue?.errors;
};
